<template>
  <v-app>
    <Sidebar />
    <v-content>
      <Snackbar />
      <!-- <v-overlay v-if="showLoader" opacity="0.9" absolute :value="true"> 
        <Spinner class="v-center-loader" :dark="true" v-bind:message="loaderMessage" />
      </v-overlay>-->
      <transition appear mode="out-in" name="fade">
        <router-view />
      </transition>
      <BottomBar />
    </v-content>
  </v-app>
</template>

<script>
import Sidebar from "../components/nav/Sidebar.vue";
import Spinner from "../components/misc/Spinner";
import BottomBar from "../components/nav/BottomBar.vue";
import Snackbar from "../components/misc/Snackbar";

import loader from "@/mixins/loader";

import store from "../store/store";
import { mapState } from "vuex";

export default {
  mixins: [loader],
  components: {
    Sidebar,
    BottomBar,
    Spinner,
    Snackbar
  },
  computed: {
    ...mapState('loader', ["showLoader", "loaderMessage", "dark"])
  }
  /*   beforeMount: function() {
    this.handleLoad(this.isLoggedIn);
  },
  watch: {
    isLoggedIn(newValue, oldValue) {
      this.handleLoad(newValue);
    }
  },
  methods: {
    handleLoad: function(value) {

    }
  } */
};
</script>
