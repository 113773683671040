
import store from './store/store';
import requests from "@/requests/requests"
import signuprequests from "./requests/signup/signup.js";

async function checkStore(pathname) {
    if(store.state.dataservice.status == 'initialise') {
        store.dispatch('dataservice/get');
    }
    /* if (store.state.questions.status == 'initialise') {
        store.dispatch("questions/get", pathname);
    }
    if (store.state.settings.status == 'initialise') {
        //await store.dispatch("settings/language", { language: null, notify: null })
        store.dispatch("settings/get", pathname);
    }
    if (store.state.applications_status == 'initialise') {
        requests.applications.get()
            .then(result => {
                return;
            })
            .catch(err => {
                console.log(err);
            });
    } */

}


export default {
    checkStore: checkStore,
};

