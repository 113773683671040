import requests from '../../requests/user/requests'
import store from '../../store/store'

import req from '../../requests/global'

const nativeLogin = async (context, data) => {
    var token = await req.nativeLogin({body: {username: data.username, password: data.password}});
    context.commit("token", token);
    context.commit("isLoggedIn", true);
    return "Succesful login"
}

function nativeLoginLegacy(context, data) {
    return new Promise((resolve, reject) => {
        console.log(data.username)
        console.log(data.password)
        requests.nativeLogin(data.username, data.password).then(result => {
            console.log(result);
            if (result.isLoggedIn) {
                store.commit("isLoggedIn", true);
                store.commit(
                    "encryptedAccessToken",
                    result.encryptedAccessToken
                );
                store.commit(
                    "encryptedSessionToken",
                    result.encryptedSessionToken
                );
                store.commit("newUser", result.newUser);
                //store.commit('signup_questions', result);
                //console.log(store.state.signup_questions);
                //store.commit('signup_questions_status', 'success');

                resolve("Successful login");
            }
            else {
                reject("Could not log in");
            }
        })
            .catch(err => {
                console.log("Error " + err);
                reject(err);
            })
    })
}
function createNativeUser(context, data) {
    return new Promise((resolve, reject) => {
        console.log(data.username)
        console.log(data.password)
        requests.createNativeUser(data.username, data.password).then(result => {
            console.log(result);
            if (result.isLoggedIn) {
                store.commit("isLoggedIn", true);
                store.commit(
                    "encryptedAccessToken",
                    result.encryptedAccessToken
                );
                store.commit(
                    "encryptedSessionToken",
                    result.encryptedSessionToken
                );
                store.commit("newUser", result.newUser);
                //store.commit('signup_questions', result);
                //console.log(store.state.signup_questions);
                //store.commit('signup_questions_status', 'success');

                resolve("Successful login");
            }
            else {
                reject("Could not log in");
            }
        })
            .catch(err => {
                console.log("Error " + err);
                reject(err);
            })
    })
}

export default {
    nativeLogin: nativeLogin,
    createNativeUser: createNativeUser
}