//import { axios } from 'axios';

import Vue from 'vue'
import store from "@/store/store.js";
//import { reject } from 'q';

export default {
    cancel: function (jobGuid) {
        var encryptedAccessToken = $cookies.get("encrypted_access_token");
        var encryptedSessionToken = ($cookies.get("encrypted_session_token") != null) ? $cookies.get("encrypted_session_token") : '';
        if (encryptedAccessToken == null) { return; }
        return new Promise((resolve, reject) => {
            Vue.axios.post("https://us-central1-higher.cloudfunctions.net/service/at/applications/" + jobGuid + "/cancel",
                //Vue.axios.post("http://localhost:3000/at/applications/"+jobGuid + "/cancel",
                {
                    encryptedAccessToken: encryptedAccessToken,
                    encryptedSessionToken: encryptedSessionToken,
                })
                .then((response) => {
                    // console.log("What is the token?");
                    // console.log(response.data);
                    resolve(response.data)
                },
                    (error) => {
                        console.log(error);
                        reject(error);
                    });
        });
    },
    apply: function (jobGuid) {
        var encryptedAccessToken = $cookies.get("encrypted_access_token");
        var encryptedSessionToken = ($cookies.get("encrypted_session_token") != null) ? $cookies.get("encrypted_session_token") : '';
        if (encryptedAccessToken == null) { return; }
        return new Promise((resolve, reject) => {
            Vue.axios.post("https://us-central1-higher.cloudfunctions.net/service/at/applications/" + jobGuid + "/apply",
                //Vue.axios.post("http://localhost:3000/at/applications/"+jobGuid + "/apply",
                {
                    encryptedAccessToken: encryptedAccessToken,
                    encryptedSessionToken: encryptedSessionToken,
                })
                .then((response) => {
                    // console.log("What is the token?");
                    // console.log(response.data);
                    resolve(response.data)
                },
                    (error) => {
                        console.log(error);
                        reject(error);
                    });
        });
    },
    getInterviewTimes: function (id) {
        var encryptedAccessToken = $cookies.get("encrypted_access_token");
        var encryptedSessionToken = ($cookies.get("encrypted_session_token") != null) ? $cookies.get("encrypted_session_token") : '';
        return new Promise((resolve, reject) => {
            if (encryptedAccessToken == null) {
                reject({ validToken: false });
            }
            //Vue.axios.get("http://localhost:3000/at/applications/" + id + "/invites",
            Vue.axios.get("https://us-central1-higher.cloudfunctions.net/service/at/applications/" + id + "/invites",
                {
                    //Vue.axios.get("http://localhost:3000/ag/jobs", {
                    params: {
                        encryptedAccessToken: encryptedAccessToken,
                        encryptedSessionToken: encryptedSessionToken
                    }
                })
                .then((response) => {
                    console.log("response.data");
                    console.log(response.data);
                    //store.dispatch('updateJobs', response.data)
                    resolve(response.data)

                },
                    (error) => {
                        console.log(error);
                        reject({ validToken: false });
                    });
        });
    },
    getApplications: function () {
        var encryptedAccessToken = $cookies.get("encrypted_access_token");
        var encryptedSessionToken = ($cookies.get("encrypted_session_token") != null) ? $cookies.get("encrypted_session_token") : '';
        return new Promise((resolve, reject) => {
            if (encryptedAccessToken == null) {
                reject({ validToken: false });
            }
            //Vue.axios.get("http://localhost:3000/at/applications",
            Vue.axios.get("https://us-central1-higher.cloudfunctions.net/service/at/applications",
                {
                    //Vue.axios.get("http://localhost:3000/ag/jobs", {
                    params: {
                        encryptedAccessToken: encryptedAccessToken,
                        encryptedSessionToken: encryptedSessionToken
                    }
                })
                .then((response) => {
                    console.log(response.data);
                    store.dispatch('updateApplications', response.data)

                },
                    (error) => {
                        console.log(error);
                        reject({ validToken: false });
                    });
        });
    }
}

//export function getInfo() {alert();}