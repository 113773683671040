

import { getQuestions, getBasicJobInfo, saveQuestions } from '../../requests/global'

const getSignupQuestions = async (context, data) => {
    context.commit('status', "pending");
    try {
        var result = await getQuestions({ signup: true, params: { job_guid: data } });
        if (data != null) {
            console.log("Get job info!!")
            context.commit('jobInfo_status', "pending");
            var jobInfo = await getBasicJobInfo({ params: { job_guid: data } });
            var cityText = '';
            console.log(jobInfo)
            jobInfo.city.forEach(city => {
                cityText += city.name + ", ";
            })
            jobInfo.cityText = cityText.slice(0, -2);
            context.commit('jobInfo', jobInfo);

        }
        console.log("result");
        console.log(result);
        result.forEach((el) => { el.ref = "signup-questions-no-" + el.id, el.visible = false, el.dimmed = false, el.answer = null, el.revealed = false, el.validAnswer = false; }) // ES6

        //console.log(result);
        //store.commit('signup_questions', result);
        //console.log(store.state.signup_questions);
        //store.commit('signup_questions_status', 'success');

        //resolve("Success actions");
        context.commit('status', "success");
        context.commit('questions', result);
    }
    catch (e) {
        context.commit('status', "failed");
        context.commit('loadingError', e.response.data)
    }
}

const save = async (context, questions) => {
    try {
        console.log("qs_changed_untouched")
        console.log(qs_changed);
        var qs_changed = JSON.parse(JSON.stringify(questions));
        var qs_current = context.state.questions;

        for (var i = qs_changed.length - 1; i >= 0; i--) {
            if (((qs_changed[i].answer == null || qs_changed[i].answer == undefined) &&
                (qs_changed[i].answer_text == null || qs_changed[i].answer_text == undefined) &&
                qs_changed[i].optional == false) || qs_changed.validAnswer == false
            ) {
                //console.log("splicing it")
                qs_changed.splice(i, 1);
                continue;
            }
            for (var k = 0; k < qs_current.length; k++) {
                if (qs_changed[i].id == qs_current[k].id) {

                    if ((qs_changed[i].answer == null &&
                        qs_changed[i].answer_text == null &&
                        qs_changed[i].optional == false)
                        ||
                        (JSON.stringify(qs_changed[i].answer) == JSON.stringify(qs_current[k].answer) &&
                            JSON.stringify(qs_changed[i].answer_text) == JSON.stringify(qs_current[k].answer_text))) {
                        qs_changed.splice(i, 1);
                        break;
                    }
                }
            }
        }
        console.log("qs_changed");
        console.log(qs_changed);
        //return;
        if (qs_changed.length > 0) {
            var response = await saveQuestions({ body: { questions: qs_changed } });
        }
        context.commit("questions", JSON.parse(JSON.stringify(questions)))
        return
    }
    catch (e) {
        console.log(e);
        context.dispatch("snackbar/error", "errorSavingQuestions", { root: true })
    }
}

const basicJobInfo = async (context, job_guid) => {

}

export default {
    getSignupQuestions: getSignupQuestions,
    basicJobInfo: basicJobInfo,
    save: save,

}