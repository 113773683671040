import questions from "./actions/questions";
import user from "./actions/user";
import requests from '../requests/requests'

const updateApplications = (context, data) => {
    for (var i = 0; i < data.length; i++) {
        data[i].city_name = JSON.parse(
            data[i].city
        )[0].name;
        if (JSON.parse(data[i].city).length > 1) {
            data[i].city_name += " mfl.";
        }
        if (data[i].user_application_status == "Applied") {
            data[i].type = "Active";
        }
        else {
            data[i].type = "Other";
        }
    }
    var temp_active_applications = context.getters.filteredList(data, 'type', 'Active');
    var temp_other_applications = context.getters.filteredList(data, 'type', 'Other');
    context.commit('applications', data);
    context.commit('active_applications', temp_active_applications);
    context.commit('other_applications', temp_other_applications);
    context.commit('applications_status', 'success');
};
const updateJobs = (context, data) => {
    for (var i = 0; i < data.length; i++) {
        data[i].city_name = JSON.parse(
            data[i].city
        )[0].name;
        if (JSON.parse(data[i].city).length > 1) {
            data[i].city_name += " mfl.";
        }
        data[i].city = JSON.parse(data[i].city)
        /*        if(data[i].user_application_status == "Applied") {
                 data[i].type = "Active";
               }
               else {
                 data[i].type = "Other";
               } */
    }

    context.commit('jobs', data);
    context.commit('jobs_status', 'success');
};
const updateAllJobs = (context, data) => {
    for (var i = 0; i < data.length; i++) {
        data[i].city_name = JSON.parse(
            data[i].city
        )[0].name;
        if (JSON.parse(data[i].city).length > 1) {
            data[i].city_name += " mfl.";
        }
        data[i].city = JSON.parse(data[i].city)
        /*        if(data[i].user_application_status == "Applied") {
                 data[i].type = "Active";
               }
               else {
                 data[i].type = "Other";
               } */
    }

    context.commit('all_jobs', data);
    context.commit('all_jobs_status', 'success');
};

export default {
    updateApplications: updateApplications,
    updateJobs: updateJobs,
    updateAllJobs: updateAllJobs,
    getSignupQuestions: questions.getSignupQuestions,
    nativeLogin: user.nativeLogin,
    createNativeUser: user.createNativeUser,
};