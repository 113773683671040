
module.exports = {
    namespaced: true,
    state: () => ({
        showLoader: false,
        loaderMessage: 'Startar Brite',
        pending: 0,
        dark: false,
        briteBotDefaultMessages: [1,2,3,4,5,6,7,8,9,10]
    }),
    mutations: {
        showLoader(state, status) { state.showLoader = status },
        loaderMessage(state, status) { state.loaderMessage = status },
        dark(state, status) { state.dark = status },
    },
    getters: {
    },
    actions: {
        show(context, data) {
            context.dispatch('default', data)
            if (data != null && data.hasOwnProperty("text")) {
                context.commit('loaderMessage', data.text);
            }
            context.commit('showLoader', true);
            console.log("showing loader")
            //context.state.pending++
            //console.log(context.state.pending)
        },
        hide(context, data) {
            //context.dispatch('default', data)
            //context.state.pending--
            //console.log(context.state.pending)
            context.commit('showLoader', false);

        },
        default(context, data) {
            context.commit(
                'loaderMessage',
                //"randomLoading.mess"+Math.floor(1+Math.random() * 10)
                "randomLoading.mess"+Math.floor(1+Math.random() * 10)
                )
            context.commit('dark', false);
        },
    }
}