<template>
  <v-dialog v-model="ss" max-width="500">
    <v-card>
      <v-card-title class="headline">Oparade ändringar</v-card-title>

      <v-card-text>Du har osparade ändringar, vill du gå vidare utan att spara?</v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn text left @click="dialog = false">Avbryt</v-btn>

        <v-btn color="primary" right @click="confirmLeave">Gå vidare</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import router from "../../router";

var outsideresolve;
var outsidereject;

export default {
  data() {
    return {};
  },
  props: {
    dialog: Boolean,
    original: Object,
    copy: Object,
    nextRoute: Object
  },
  methods: {
    confirmLeave() {
      outsideresolve();
    },
    rejectLeave() {
      outsidereject();
    }
  },
  watch: {
    nextRoute: {
      handler: function() {
        console.log("router");
        //    router.push({ name: 'Support' });
        //
        /*   new Promise(function(resolve, reject) {
          outsideresolve = resolve;
          outsidereject = reject;
        }).then(result => {
            console.log("Resolved from subc");

        }); */
      }
    }
  }
};
</script>