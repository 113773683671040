<template>
  <div>
    <v-navigation-drawer width="280" v-if="$vuetify.breakpoint.mdAndUp" app mobile-break-point="50">
      <v-list fluid flat>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <h1 class="SidebarHeader" v-bind:class="headerColor">Brite</h1>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item-group :mandatory="true" v-model="activePage" class="white--text">
          <v-list-item v-for="item in items" :key="item.id" :to="item.path">
            <v-list-item-icon>
              <v-badge v-if="item.notification" left overlap color="error" icon="priority_high">
                <v-icon v-text="item.icon"></v-icon>
              </v-badge>
              <v-icon v-else v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="$t('routes.'+item.key)"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <div class="bottom">
        <v-subheader class="white--text">
          <a href="http://digifut.se/" target="_blank">© Digifut Solutions {{fullyear}}</a>
        </v-subheader>

      <!--   <v-overflow-btn
          full-width
          class="my-2"
          :items="languages"
          v-model="lang"
          label="Language"
          item-value="code"
          item-text="text"
          background-color="secondary"
        ></v-overflow-btn> -->
      </div>
    </v-navigation-drawer>
  </div>
</template>
<script>
import routes from "../../routes";
import language from "@/mixins/language";

//import { mapAction, mapState } from
export default {
  mixins: [language],
  data: () => ({
    items: routes.items,
    activePage: 0,
    headerColor: "greenHeader"
  }),
  computed: {
    fullyear: function() {
      var fullyear = new Date();
      return fullyear.getFullYear();
    }
  },
  watch: {
    activePage: {
      handler: function() {
        if (this.activePage == 0 || this.activePage == 3) {
          this.headerColor = "greenHeader";
        } else if (this.activePage == 1) {
          this.headerColor = "yellowHeader";
        } else if (this.activePage == 2) {
          this.headerColor = "redHeader";
        } else if (this.activePage == 4) {
          this.headerColor = "rainbowHeader";
        } else {
          this.headerColor = "greenHeader";
        }
      },
      deep: true
    }
  }
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

#fader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  pointer-events: none;
  background: #fafafa; /* 
    animation-duration: 5000ms;
    animation-timing-function: ease-in-out; */
}

.v-list-item__title {
  font-family: "Proxima Nova Bold";
  font-size: 1.2rem;
}

html .v-application--is-ltr .v-list-item__icon:first-child {
  margin: 12px;
  margin-right: 16px;
}

h1 {
  margin-top: 24px;
  margin-bottom: 12px;
  font-size: 2rem;
  /* color: #038C8C; */
  -webkit-transition: color 1s ease-out;
  -moz-transition: color 1s ease-out;
  -o-transition: color 1s ease-out;
  transition: color 1s ease-out;
}

.greenHeader {
  color: #038c8c;
}

.redHeader {
  color: #d94032;
}
.yellowHeader {
  color: #f2af5c;
}

.rainbowHeader {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#038c8c+0,f2af5c+50,d94032+100 */
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#038c8c+15,f2af5c+50,d94032+85 */
  background: #038c8c; /* Old browsers */
  background: -moz-linear-gradient(
    -45deg,
    #038c8c 15%,
    #f2af5c 50%,
    #d94032 85%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    -45deg,
    #038c8c 15%,
    #f2af5c 50%,
    #d94032 85%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    135deg,
    #038c8c 15%,
    #f2af5c 50%,
    #d94032 85%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#038c8c', endColorstr='#d94032',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

nav.theme--light.v-navigation-drawer {
  background-color: #182540;
  color: white;
}

.theme--light.v-list-item,
.theme--light.v-icon {
  color: rgba(255, 255, 255, 1) !important;
}
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled),
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled)
  .v-icon {
  color: rgba(255, 255, 255, 0.4) !important;
}

div.bottom {
  position: absolute;
  bottom: 5px;
  width: 100%;
}

div.bottom .v-subheader a {
  font-family: "Proxima Nova Thin";
  text-decoration: none;
  color: white;
}
</style>