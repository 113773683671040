//import { axios } from 'axios';

import Vue from 'vue'
//import store from "../store.js";
//import { reject } from 'q';

export default {
    getPlaces(query) {
        //console.log("query is " + query)
        return new Promise((resolve, reject) => {
            //Vue.axios.get("http://localhost:3000/external/cities", {
            Vue.axios.get("https://us-central1-higher.cloudfunctions.net/service/external/cities", {
                params: {
                    input: query
                }
            })
                //Vue.axios.get("http://localhost:8010/higher/us-central1/autocompleteCity")
                .then((response) => {
                    // console.log(response.data);
                    //response.data.unshift({id:1,name:"Hela Sverige"});
                    resolve(response.data);
                }, (error) => {
                    console.log(error);
                });
        });
    },

    suggestJob(query) {
        //console.log("query is " + query)
        return new Promise((resolve, reject) => {
            //Vue.axios.post("http://localhost:3000/suggestions/jobs", 
            Vue.axios.post("https://us-central1-higher.cloudfunctions.net/service/suggestions/jobs",
                {
                    new_job: query
            })
                //Vue.axios.get("http://localhost:8010/higher/us-central1/autocompleteCity")
                .then((response) => {
                    // console.log(response.data);
                    //response.data.unshift({id:1,name:"Hela Sverige"});
                    resolve(response.data);
                }, (error) => {
                    console.log(error);
                });
        });
    },
}

//

//export function getInfo() {alert();}