<template>
  <div class="mx-auto page-normal">
    <PageHeader :title="page.title"></PageHeader>
    <PageActions
      :progress="null"
      :breadcrumbs="null"
      :alertBar="alertBar"
    ></PageActions>
    <PageContent :loading="loading">
      <v-row v-if="typeOfEnvironment != 'production'">
        <v-col cols="12">
          <h2>Notiser</h2>
        </v-col>
        <v-col cols="12">
          <v-combobox
            v-model="emailNotificationList"
            :items="[]"
            chips
            label="Email för notiser"
            multiple
            prepend-icon="email"
            outlined
          >
            <template v-slot:selection="{ attrs, item, select, selected }">
              <v-chip
                v-bind="attrs"
                :input-value="selected"
                close
                @click="select"
                @click:close="remove(emailNotificationList, item)"
              >
                <strong>{{ item }}</strong
                >&nbsp;
              </v-chip>
            </template>
          </v-combobox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h2>Återställ lösenord</h2>
          <p>Klicka för återställa och byta lösenord. Är du inloggad via Google eller Facebook så byter du lösenord via dem.</p>
        </v-col>
        <v-col>
          <v-btn dark class="text-none white--text" rounded color="primary"
            ><router-link to="password-reset">
              <span class="white--text">Återställ lösenord</span>
            </router-link></v-btn
          >
        </v-col>
      </v-row>
      <v-row v-if="typeOfEnvironment != 'production'">
        <v-col>
          <h2>Uppdatera lösenord</h2>
        </v-col>
        <v-col cols="12">
          <v-text-field
            label="Gammalt lösenord"
            placeholder="Skriv..."
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            label="Nytt lösenord"
            placeholder="Skriv..."
            outlined
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-text-field
            label="Upprepa lösenord"
            placeholder="Skriv..."
            outlined
          ></v-text-field>
        </v-col>
        <v-col>
          <v-btn class="text-none" rounded color="primary" @click="logout"
            >Uppdatera lösenord</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h2>Logga ut</h2>
          <p>
            Även när du är utloggad får du emails om vad som händer på Brite
          </p>
        </v-col>
        <v-col>
          <v-btn class="text-none" rounded color="primary" @click="logout"
            >Logga ut</v-btn
          >
        </v-col>
      </v-row>
    </PageContent>
  </div>
</template>
<script>
import Vue from "vue";
import store from "../store/store";
import { mapState } from "vuex";
import Unsaved from "../components/misc/Unsaved";
import Spinner from "../components/misc/Spinner";

import router from "../router";

import basicMixin from "../mixins/basic";
import alertBarMixin from "../mixins/alertBar";

export default {
  mixins: [basicMixin, alertBarMixin],
  components: {
    Spinner,
  },
  data() {
    return {
      page: {
        title: "Inställningar",
      },
      notifications: {},
      emailNotificationList: [],
      loading: false,
    };
  },
  watch: {},
  beforeMount: function () {},
  computed: {},
  methods: {
    remove(list, item) {
      list.splice(list.indexOf(item), 1);
      list = [...list];
    },
    async logout() {
      await store.dispatch("user/logout");
      //location.reload();
      this.$router.push({ name: "LoginPage" });
    },
  },
};
</script>