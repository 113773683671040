<template>
  <div class="mx-auto page-normal">
    <PageHeader :back="true" :title="'Jobb'" :divider="true"></PageHeader>
    <PageActions :alertBar="alertBar" :breadcrumbs="[]">
      <div
        v-if="
          $vuetify.breakpoint.smAndDown &&
          loading == false &&
          job != null &&
          job != undefined &&
          job.hasOwnProperty('application_status') == false
        "
      >
        <v-col class="px-0 pt-0 mt-2" cols="12" md="12">
          <v-card
            class="flex-column d-flex"
            height="100%"
            :elevation="2"
            color="success"
            dark
          >
            <v-card-title class="py-1">
              <h1 :class="{ 'mb-0': stickyActive }">Ansök</h1>
              <v-spacer></v-spacer>
              <v-btn
                @click="apply"
                v-if="stickyActive"
                class="text-none"
                rounded
                color="primary"
                >Ansök nu</v-btn
              >
            </v-card-title>

            <v-card-subtitle v-if="!stickyActive"
              >Ta chansen och fixa ditt drömjobb som
              {{ job.title }}</v-card-subtitle
            >
            <v-card-actions v-if="!stickyActive" class="justify-center">
              <v-btn
                @click="apply"
                class="text-none px-8"
                rounded
                color="primary"
                >Ansök nu</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </div>
    </PageActions>
    <PageContent :loading="loading">
      <div v-if="!loading">
        <!--  <v-card flat style="  position: -webkit-sticky; position: sticky;top: 0;z-index:10;">
        <v-card-action>
          <v-btn rounded color="primary">Ansök</v-btn>
        </v-card-action>
        </v-card>-->
        <Dialog
          v-if="bookInterviewDialogItem != null"
          :title="'Boka intervju'"
          v-model="bookInterviewDialog"
        >
          <p>
            Vill du boka en intervju {{ bookInterviewDialogItem.meetingTime }}?
          </p>

          <p class="subtitle-2 mb-1">Mötesinformation</p>
          <p>
            {{ bookInterviewDialogItem.meeting_info }}
          </p>
          <p class="subtitle-2 mb-1">Arbetsgivarens kommentar</p>
          <p v-if="bookInterviewDialogItem.employer_comment != null">
            {{ bookInterviewDialogItem.employer_comment }}
          </p>
          <p v-else>Ingen kommentar given</p>
          <p class="subtitle-2 mt-8 mb-1">
            Ange ditt namn och mobilnr eller email som arbetsgivaren kan kontakta dig på
          </p>
          <v-text-field
            placeholder="Skriv ditt namn och mobilnr eller email..."
            v-model="bookInterviewDialogItem.employee_ice_contact"
          ></v-text-field>
          <p class="subtitle-2 mt-8 mb-1">Din kommentar till arbetsgivaren</p>
          <v-textarea
            placeholder="Skriv..."
            :rows="2"
            v-model="bookInterviewDialogItem.employee_comment"
          />
          <template v-slot:actions>
            <v-spacer></v-spacer>
            <v-btn @click="bookInterviewDialog = false" text class="text-none"
              >Avbryt</v-btn
            >
            <Button
              v-if="
                bookInterviewDialogItem.employee_ice_contact != null &&
                bookInterviewDialogItem.employee_ice_contact.length > 0
              "
              @clicked="confirmBookInterview"
              :type="'continue'"
              >Ja, boka intervju</Button
            >
          </template>
        </Dialog>
        <Dialog
          v-if="changeDialog != null"
          :title="changeDialog[pendingStatus].title"
          v-model="changeStatusDialog"
        >
          {{ changeDialog[pendingStatus].text }}
          <template v-slot:actions>
            <v-spacer></v-spacer>
            <v-btn @click="changeStatusDialog = false" text class="text-none"
              >Avbryt</v-btn
            >
            <Button
              @clicked="confirmStatusChange"
              :type="changeDialog[pendingStatus].type"
              >{{ changeDialog[pendingStatus].btnText }}</Button
            >
          </template>
        </Dialog>
        <div ref="stickyapply"></div>
        <v-row>
          <v-col
            v-if="bookedInterview != null && bookedInterview.length > 0"
            cols="12"
            md="6"
          >
            <v-card
              v-for="(item, index) in bookedInterview"
              :key="item.interview_guid"
              :elevation="2"
              color="success"
              dark
            >
              <v-card-title>
                <h3 class="mt-0 font-weight-bold">Bokad intervju</h3>
                <v-spacer></v-spacer
                ><AddToCalendar :items="bookedInterview[0].addToCalendar" />
              </v-card-title>

              <v-card-subtitle>
                {{ item.start + "-" + item.end.substring(11, 16) }}
              </v-card-subtitle>
              <v-card-actions @click="item.expanded = !item.expanded">
                <v-btn text class="text-none"> Se mer information </v-btn>

                <v-spacer></v-spacer>

                <v-btn icon>
                  <v-icon>{{
                    item.expanded ? "mdi-chevron-up" : "mdi-chevron-down"
                  }}</v-icon>
                </v-btn>
              </v-card-actions>

              <v-expand-transition>
                <div v-show="item.expanded">
                  <v-card-text>
                    <v-textarea
                      :value="item.meeting_info"
                      label="Mötesinformation"
                      readonly
                      rows="3"
                    >
                    </v-textarea>
                    <v-textarea
                      label="Arbetsgivarens kommentar"
                      readonly
                      rows="3"
                      :value="item.employer_comment"
                    >
                    </v-textarea>
                  </v-card-text>
                </div>
              </v-expand-transition>
            </v-card>
          </v-col>
          <v-col
            v-else-if="
              availableInterviews != null && availableInterviews.length > 0
            "
            cols="12"
            md="6"
          >
            <v-card :elevation="2" color="success" dark>
              <v-card-title>
                <h3 class="mt-0 font-weight-bold">Dags för intervju!</h3>
              </v-card-title>
              <v-list two-line color="success" dark>
                <v-list-item-group>
                  <template v-for="(item, index) in job.interviews">
                    <v-list-item
                      :key="item.interview_guid"
                      @click="bookInterview(item)"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.meetingTime"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          class="white--text"
                          v-text="item.employer_comment"
                        ></v-list-item-subtitle>
                        <!-- <v-list-item-subtitle v-text="item.description"></v-list-item-subtitle> -->
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-btn color="primary" size="24">Boka</v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card color="amber lighten-5" height="100%">
              <v-card-title>
                <h3 class="mt-0 font-weight-bold">{{ job.title }}</h3>
                <v-spacer></v-spacer>
                <v-menu
                  v-if="job.hasOwnProperty('application_status')"
                  transition="slide-y-transition"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="white"
                      v-bind="attrs"
                      v-on="on"
                      class="text-none mr-2"
                    >
                      <v-icon>arrow_drop_down</v-icon>
                      {{ job.applicationStatusText }}
                      <v-list-item-avatar
                        size="24"
                        :color="job.applicationStatusColor"
                      ></v-list-item-avatar>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, i) in changeDropdown"
                      :key="i"
                      @click="changeStatus(item.status)"
                    >
                      <v-list-item-title>{{
                        item.statusText
                      }}</v-list-item-title>
                      <v-list-item-avatar
                        size="24"
                        :color="item.statusColor"
                      ></v-list-item-avatar>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="mt-0 font-weight-bold">{{
                          job.company
                        }}</v-list-item-title>
                        <v-list-item-subtitle>Företag</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <!--  <v-col cols="12" sm="6">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        class="mt-0 font-weight-bold"
                      >{{ job.application_period_end }}</v-list-item-title>
                      <v-list-item-subtitle>Sista ansökningsdag</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  </v-col>-->
                  <v-col cols="12" sm="6">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="mt-0 font-weight-bold">{{
                          job.jobStatusText
                        }}</v-list-item-title>
                        <v-list-item-subtitle>Status</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="mt-0 font-weight-bold">{{
                          job.cityText
                        }}</v-list-item-title>
                        <v-list-item-subtitle>Stad</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-list-item-title class="mt-0 font-weight-bold"
                      >Beskrivning</v-list-item-title
                    >

                    <p>{{ job.description }}</p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            v-if="
              !$vuetify.breakpoint.smAndDown &&
              job.hasOwnProperty('application_status') == false
            "
            cols="12"
            md="6"
          >
            <v-hover v-slot:default="{ hover }">
              <v-card
                class="flex-column d-flex"
                height="100%"
                :elevation="hover ? 16 : 2"
                color="success"
                dark
              >
                <v-card-title>
                  <h1>Ansökan öppen</h1>
                </v-card-title>

                <v-card-subtitle
                  >Ta chansen och fixa ditt drömjobb</v-card-subtitle
                >
                <v-card-text class="flex-grow-1"></v-card-text>
                <v-card-actions class="justify-center">
                  <v-btn
                    @click="apply"
                    class="text-none px-8"
                    rounded
                    color="primary"
                    >Ansök nu</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-hover>
          </v-col>
          <v-col cols="12" md="6">
            <v-card dark color="primary" height="100%">
              <v-carousel
                hide-delimiter-background
                min-height="200"
                height="300"
                :show-arrows="false"
                v-model="application_status_key"
              >
                <v-carousel-item
                  v-for="(slide, i) in application_status"
                  :key="i"
                >
                  <v-sheet :color="slide.color" height="100%">
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>
                          <h3 class="mt-0 font-weight-bold">
                            {{ slide.status }}
                          </h3>
                        </v-list-item-title>
                        <v-list-item-subtitle>Status</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-avatar size="60" color="white">
                        <img class="stageIconImg pa-2" :src="slide.image" />
                      </v-list-item-avatar>
                    </v-list-item>
                    <v-card-text>
                      <p>{{ slide.description }}</p>
                    </v-card-text>
                  </v-sheet>
                </v-carousel-item>
              </v-carousel>
            </v-card>
          </v-col>

          <v-col cols="12" sm="12" md="6">
            <v-card>
              <v-card-title>
                <v-row class="mr-4">
                  <v-col>
                    <v-img :max-width="130" :src="job.company_logo"></v-img>
                  </v-col>
                </v-row>
                <h5>{{ job.company }}</h5>
              </v-card-title>
              <v-card-text>
                <p>{{ job.company_description }}</p>
                <v-simple-table dense>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td>Hemsida</td>
                        <td>{{ job.company_website }}</td>
                      </tr>
                      <tr>
                        <td>Startat</td>
                        <td>{{ job.company_founding_year }}</td>
                      </tr>
                      <tr>
                        <td>Antal anställda</td>
                        <td>{{ job.company_number_of_employees }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </PageContent>
  </div>
</template>
<script>
import PageHeader from "../components/layout/PageHeader";
import PageActions from "../components/layout/PageActions";
import PageContent from "../components/layout/PageContent";

import store from "../store/store";
import requests from "../plugins/requests";
import QSingle from "../components/q/QSingle";
import QMulti from "../components/q/QMulti";
import QSlide from "../components/q/QSlide";
import QCities from "../components/q/QCities";
import QTextShort from "../components/q/QTextShort";
import QText from "../components/q/QText";
import QJobs from "../components/q/QJobs";
import Spinner from "../components/misc/Spinner";
import { mapState, mapActions, mapGetters } from "vuex";

import AlertBar from "../components/basic/AlertBar";
import AlertBarMixin from "@/mixins/alertBar";
import SnackbarMixin from "@/mixins/snackbar";
import Basic from "@/mixins/basic";

export default {
  mixins: [Basic, AlertBarMixin, SnackbarMixin],
  components: {
    QSingle,
    QMulti,
    QSlide,
    QCities,
    QTextShort,
    QText,
    QJobs,
    Spinner,
    PageHeader,
    PageContent,
    PageActions,
    AlertBar,
  },
  data: function () {
    return {
      showTestDialog: true,
      stickyActive: false,
      page: {
        key: "jobs",
        title: "Lediga tjänster",
      },
      colors: [
        "indigo",
        "warning",
        "pink darken-2",
        "red lighten-1",
        "deep-purple accent-4",
      ],
      application_status: [
        {
          color: "primary",
          status: "Öppen",
          description:
            "Just nu skickas ansökningar in och arbetsgivaren granskar urvalet. Brite Bot rankar nu alla sökande efter en vetenskaplig och diskrimineringsfri metod. Inga intervjuer är gjorda ännu",
          image: "/img/Ikonbibliotek/Artificiell intelligens.svg",
        },
        {
          color: "primary",
          status: "Intervjuer",
          description:
            "Arbetstagaren har skickat inbjudan till intervju till en eller flera kandidater.",
          image: "/img/Ikonbibliotek/Intervju.svg",
        },
        {
          color: "primary",
          status: "Rekryteringen är avslutad.",
          description: "Denna rekrytering är avslutad",
          image: "/img/Ikonbibliotek/Handskakning.svg",
        },
      ],
      pendingStatus: "APPLIED",
      matchQuestionsValidTooltip: false,
      changeStatusDialog: false,
      applicantAllStatusDropdown: [
        {
          status: "INVITED",
          statusText: "Kalla på intervju",
          statusColor: "primary",
        },
        { status: "BOOKED", statusText: "book", statusColor: "primary" },
        {
          status: "COMPLETED",
          statusText: "Intervju genomförd",
          statusColor: "primary",
        },
        { status: "HIRED", statusText: "Anställ!", statusColor: "success" },
        {
          status: "CANCELLED",
          statusText: "Tacka nej",
          statusColor: "error",
        },
      ],
      applyDialog: false,
      profile: null,
      stage: 1,
      previewStage: null,
      bookInterviewDialog: false,
      bookInterviewDialogItem: null,
      showSnackbar: false,
      snackbartimeout: 3000,
      snackbarbottom: true,
      snackbarmultiline: true,
      snackbarMessage: "",
      alertBoxMessage: undefined,
      questions: [],
      showAlertBox: false,
      dialog: false,
      changesPending: false,
      saving: false,
      firstLoadDone: false,
      loading: true,
      dialog: false,
      activeApplicant: { title: "[failed to get name]" },
      job: null,
      meeting_dates: [],
      table_mobile_limit: 200,
      selected: [],
      status_width: 200,
      tvs: this.$store.state.totalTvCount,
      search: "",
      headers: [],
    };
  },
  props: {},
  mounted: async function () {
    this.handleLoad();
  },
  beforeDestroy() {
    //this.observer.disconnect();
  },
  created() {
    window.scrollTo(0, 0);
  },
  computed: {
    application_status_key: function () {
      var key = 0;
      switch (this.job.status) {
        case "PUBLISHED":
          key = 0;
          break;

        case "INTERVIEWS":
          key = 1;
          break;

        case "CANCELLED":
          key = 2;
          break;

        case "FINISHED":
          key = 2;
          break;
        default:
          key = 0;
      }
      return key;
    },
    bookedInterview: function () {
      if (this.job.hasOwnProperty("interviews")) {
        return this.job.interviews.filter((elem) => {
          return elem.interview_status == "BOOKED";
        });
      }
      return null;
    },
    availableInterviews: function () {
      if (this.job.hasOwnProperty("interviews")) {
        return this.job.interviews.filter((elem) => {
          return elem.interview_status == "AVAILABLE";
        });
      }
      return null;
    },
    ...mapGetters("applications", ["applicationValidChangeStatus"]),
    ...mapState("applications", ["jobs", "status"]),
    changeDialog: function () {
      if (this.job != null && this.job != undefined) {
        return {
          APPLIED: {
            title: `Söka ${this.job.title}?`,
            text:
              "Kom ihåg att om du gjort testerna och svarat på profil-frågorna så kan argetsgivaren vara mer trygg i sitt beslut när dem väljer att kalla på intervju. ",
            type: "continue",
            btnText: "Ja, ansök",
          },
          CANCELLED: {
            title: `Tack nej till tjänst?`,
            text: "Om du klickar 'Tacka nej' så avbryts din ansökan.",
            type: "error",
            btnText: "Tacka nej",
          },
        };
      } else {
        return null;
      }
    },
    changeDropdown: function () {
      console.log("this.job");
      console.log(this.job);
      if (this.job.hasOwnProperty("application_status")) {
        var validChanges = this.applicationValidChangeStatus(
          this.job.application_status
        );
        const dropDownValues = this.applicantAllStatusDropdown.filter(
          (elem) => {
            var text = validChanges.findIndex((status) => {
              return status == elem.status ? true : false;
            });
            return text > -1 ? true : false;
          }
        );
        return dropDownValues;

        return true;
      }
      return null;
    },
  },
  watch: {
    status: function () {
      this.handleLoad();
    },
    meeting_dates: function () {
      if (this.meeting_dates.length > 3) {
        this.meeting_dates.shift();
      }
      for (var i = 0; i < this.meeting_dates.length; i++) {
        var d = this.meeting_dates[i];
        var year = d.getFullYear();
        var month = d.getMonth() < 10 ? "0" + d.getMonth() : d.getMonth();
        var day = d.getDate();
        this.meeting_dates[i].date = year + "-" + month + "-" + day;
      }
    },
  },
  methods: {
    confirmBookInterview: async function () {
      try {
        this.loading = true;
        await this.sendInterview({
          application_guid: this.job.application_guid,
          interview_guid: this.bookInterviewDialogItem.interview_guid,
          interview_info: this.bookInterviewDialogItem,
        });
        this.bookInterviewDialog = false;
        this.loading = false;
        this._snackbarSuccess("interviewBooked");
      } catch (e) {
        this.bookInterviewDialog = false;
        this.loading = false;
        this._snackbarError("errorSavingQuestions");
      }
    },
    bookInterview: function (item) {
      console.log(item.interview_guid);
      console.log(this.job.application_guid);
      this.bookInterviewDialogItem = item;
      this.bookInterviewDialog = true;
    },
    changeStatus: function (status) {
      this.pendingStatus = status;
      console.log("correft clock");
      this.changeStatusDialog = true;
    },
    confirmStatusChange: async function () {
      try {
        this.changeStatusDialog = false;
        this.loading = true;
        if (this.pendingStatus == "APPLIED") {
          await this.updateApplication({
            status: "APPLIED",
            job_guid: this.job.job_guid,
          });
          this._snackbarSuccess("applicationSent");
        } else if (this.pendingStatus == "CANCELLED") {
          await this.removeApplication({
            application_guid: this.job.application_guid,
          });
          this._snackbarSuccess("applicationCancelled");
          this.pendingStatus = "APPLIED";
        }
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this._snackbarError("unknownError");
      }
    },
    apply: function () {
      console.log("applingn");
      this.changeStatusDialog = true;
    },
    logMe: function (text) {
      console.log(text);
    },
    handleIntersection(entries, observer) {
      if (entries[0].intersectionRatio < 0.5) {
        this.stickyActive = true;
      } else {
        this.stickyActive = false;
      }
    },
    onIntersect: function () {},
    getImage(item) {
      return require(item.img);
    },
    ...mapActions("applications", [
      "getJobByJobGuid",
      "get",
      "updateApplication",
      "removeApplication",
      "sendInterview",
    ]),
    handleLoad: function () {
      if (this.status == "success") {
        console.log("fetched jobs");
        this.job = store.getters["applications/jobByJobGuid"](
          this.$route.params.job_guid
        );
        console.log(this.job);
        this.loading = false;
        this.addScrollListener();
      } else if (this.status == "initialize") {
        this.loading = true;
        this.get({ job_guid: this.$route.params.job_guid });
      }
    },
    addScrollListener: function () {
      if (!this.job.hasOwnProperty("application_status")) {
        var self = this;
        setTimeout(function () {
          console.log(self.$refs.stickyapply);
          const config = {
            rootMargin: "-100px 20px 75px 30px",
            threshold: [0, 1],
          };
          let observer = new IntersectionObserver(
            self.handleIntersection,
            config
          );

          observer.observe(self.$refs.stickyapply);

          self.observer = observer;
        }, 300);
      }
    },
    callInterview: function (data) {
      this.activeApplicant = this.job.applicants[data - 1];
      if (this.activeApplicant.status == "Kalla på intervju") {
        this.dialog = true;
      }
    },
    clicked: function (data) {
      console.log("clicked" + data);
    },
    getColor: function (status) {
      if (status == "Kallad") {
        return "#F2AF5C";
      } else if (status == "Avbruten") {
        return "#D94032";
      } else {
        return "#182540";
      }
    },
    getMatchColor: function (score) {
      var percentColors = [
        { pct: 0, color: { r: 248, g: 201, b: 168 } },
        { pct: 0.6, color: { r: 248, g: 201, b: 168 } },
        { pct: 0.8, color: { r: 245, g: 248, b: 167 } },
        { pct: 1.0, color: { r: 206, g: 242, b: 201 } },
      ];

      var getColorForPercentage = function (score) {
        var pct = score / 100;

        for (var i = 1; i < percentColors.length - 1; i++) {
          if (pct < percentColors[i].pct) {
            break;
          }
        }
        var lower = percentColors[i - 1];
        var upper = percentColors[i];
        var range = upper.pct - lower.pct;
        var rangePct = (pct - lower.pct) / range;
        var pctLower = 1 - rangePct;
        var pctUpper = rangePct;
        var color = {
          r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
          g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
          b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper),
        };
        return "rgb(" + [color.r, color.g, color.b].join(",") + ")";
        // or output as hex if preferred
      };

      return getColorForPercentage(score);
    },
  },
};
</script>
<style>
.v-data-table .v-chip {
  width: 180px;
  text-align: center;
  justify-content: center;
}
.v-data-table .v-chip .v-chip__content {
  width: auto;
  text-align: center;
}
</style>