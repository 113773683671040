<template>
  <div style="width:100%">
    <div class="vue-cal-container">
      <vue-cal
        class="vuecal--full-height-delete"
        ref="vuecal"
        :locale="'sv'"
        :selected-date="today"
        small
        :time-from="8 * 60"
        :time-to="18 * 60"
        :timeCellHeight="30"
        :disable-views="['years', 'year']"
        hide-weekends
        :cell-click-hold="false"
        :events="dEvents"
        :clickToNavigate="false"
        :dblclickToNavigate="false"
        :on-event-click="onEventClick"
      ></vue-cal>
    </div>
    <v-layout v-if="loading==true" justify-center>
      <Spinner v-bind:message="'Hämtar intervjutider'" />
    </v-layout>
    <v-layout v-else justify-center>
      <v-flex xs12 class="mt-4">
        <h3
          class="text-center"
        >Leta i kalendern eller välj en tid som passar i listan nedan, öppna mötestiden och boka.</h3>
        <h2 v-if="dEvents.length>0" class="text-center mt-4">Lediga intervjutider</h2>
        <v-layout justify-space-between wrap>
          <v-flex xs12 sm6 class="text-center" v-for="(event,index) in dEvents" :key="event.start">
            <v-chip xs12 class="text-center" color="primary" @click="onEventClick(event)">
              {{index+1}}. {{event.start}}
              <v-icon>calendar_today</v-icon>
            </v-chip>
          </v-flex>
        </v-layout>
        <!--  <v-layout justify-space-around>
          <v-btn
            v-if="dEvents.length>0"
            class="text-none"
            dark
            rounded
            @click="saveEvents"
            color="primary"
          >Spara</v-btn>

          <v-btn dark rounded @click="closeSchedule" class="text-none" color="error">Avbryt</v-btn>
        </v-layout>-->
      </v-flex>
    </v-layout>
    <!-- <v-layout>
      <v-flex xs12>
        <h3 v-if="dEvents.length>0" class="text-center">Intervjutider</h3>
        <v-layout justify-space-between wrap>
          <v-flex xs12 sm6 class="text-center" v-for="(event,index) in dEvents" :key="event.start">
            <v-chip xs12 class="text-center" color="primary" @click="removeEvent(event.start)">
              {{index+1}}. {{event.start}}
              <v-icon>calendar_today</v-icon>
            </v-chip>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>-->
    <!-- @cell-dblclick="$refs.vuecal.createEvent($event, { title: 'Möte', classes: ['blue-event'] })" -->
    <!-- 
    -->

    <!-- :on-event-click="onEventClick" -->
    <!-- Using Vuetify -->
    <v-dialog
      v-model="dialog"
      width="600"
      class="mx-auto"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <v-card>
        <v-card-title>
          <v-layout>
            <v-flex xs10>
              <h4>
                <v-icon large color="black">{{ icon }}</v-icon>
                {{ startDate }}
              </h4>
            </v-flex>
            <v-flex xs2 class="text-right">
              <v-icon large color="primary" @click="dialog=false">close</v-icon>
            </v-flex>
          </v-layout>
        </v-card-title>
        <v-card-text>
          <v-row wrap justify="space-between" class="disabled-time-picker">
            <v-col xs12 sm5>
              <span>Starttid</span>
              <VueCtkDateTimePicker
                v-model="startTime"
                color="#182540"
                label="Välj tid"
                formatted="HH:mm"
                format="HH:mm"
                :only-time="true"
                :no-label="true"
                :inline="true"
                inputSize="sm"
                :minute-interval="10"
                :disabled-hours="['00','01','02','03','04','05','06','07','19','20','21','22','23']"
              />
            </v-col>
            <v-col xs12 sm5>
              <span>Sluttid</span>
              <VueCtkDateTimePicker
                v-model="endTime"
                color="#182540"
                label="Välj tid"
                formatted="HH:mm"
                format="HH:mm"
                :only-time="true"
                :no-label="true"
                :inline="true"
                inputSize="sm"
                :minute-interval="10"
                :disabled-hours="['00','01','02','03','04','05','06','07','19','20','21','22','23']"
              />
            </v-col>
          </v-row>
          <span>Kommentar till kandidaten</span>
          <v-textarea v-model="contentFull" outlined disabled filled class="disabled-text-area"></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-flex xs12>
            <v-layout justify-space-around wrap>
              <v-checkbox
                xs12
                v-model="confirmInterviewBooking"
                color="primary"
                label="Jag vill boka denna intervjutid"
              />
            </v-layout>
            <v-layout justify-space-around>
              <v-btn
                class="text-none"
                dark
                rounded
                @click="removeEvent(startDateTime)"
                color="error"
              >Avbryt</v-btn>
              <v-btn
                class="text-none"
                :style="{visibility: confirmInterviewBooking ? 'visible' : 'hidden'}"
                :disabled="!confirmInterviewBooking"
                dark
                rounded
                @click="updateEvent(startDateTime)"
                color="primary"
              >Boka intervju</v-btn>
            </v-layout>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import "vue-cal/dist/i18n/sv.js";
import requests from "../plugins/requests";
import Spinner from "../components/misc/Spinner";

export default {
  components: { VueCal, Spinner},
  data: () => ({
    loading: true,
    confirmInterviewBooking: false,
    title: "Intervju",
    icon: "calendar_today",
    startTime: "",
    endTime: "",
    startDate: "",
    endDate: "",
    startDateTime: "",
    content: "",
    contentFull: "",
    class: "event",
    event: {},
    dEvents: [],
    selectedEvent: {},
    selectedEventTemp: {},
    editActions: false,
    createActions: false,
    dialog: false,
    createDialog: false,
    editDialog: false,
    moreevents: [
      {
        start: "2018-11-20 14:00",
        end: "2018-11-20 18:00",
        title: "Need to go shopping",
        icon: "shopping_cart", // Custom attribute.
        content: "Click to see my shopping list",
        contentFull:
          "My shopping list is rather long:<br><ul><li>Avocadoes</li><li>Tomatoes</li><li>Potatoes</li><li>Mangoes</li></ul>", // Custom attribute.
        class: "leisure"
      },
      {
        start: "2018-11-22 10:00",
        end: "2018-11-22 15:00",
        title: "Golf with John",
        icon: "golf_course", // Custom attribute.
        content: "Do I need to tell how many holes?",
        contentFull: "Okay.<br>It will be a 18 hole golf course.", // Custom attribute.
        class: "sport"
      }
    ]
  }),
  props: {
    events: Array
  },
  beforeMount: function() {
    var link_id = this.$route.params.id;
    requests.methods
      .getInterviewTimes(link_id)
      .then(result => {
        console.log(result);
        this.dEvents = result.events;
        this.loading = false;
      })
      .catch(err => {
        this.loading = false;
        console.log(err);
      });
  },
  mounted: function() {
    console.log("mounted vuecal");
    //this.dEvents = JSON.parse(JSON.stringify(this.events));
  },
  watch: {
    events: function() {
      console.log("thisevents");
      console.log(this.events);
      this.dEvents = JSON.parse(JSON.stringify(this.events));
    }
  },
  computed: {
    validTime: function() {
      if (
        this.startTime.substring(0, 2) > this.endTime.substring(0, 2) ||
        (this.startTime.substring(0, 2) == this.endTime.substring(0, 2) &&
          this.startTime.substring(3, 5) >= this.endTime.substring(3, 5))
      ) {
        console.log(this.startTime.substring(0, 2));
        return false;
      } else {
        return true;
      }
    },
    today: function() {
      return this.getFullDate(new Date());
    }
  },
  methods: {
    saveEvents() {
      this.$emit("updateEvents", { events: this.dEvents });
    },
    closeSchedule() {
      this.$emit("closeSchedule", {
        showSchedule: false
      });
    },
    roundToHour(date) {
      var p = 60 * 60 * 1000; // milliseconds in an hour
      return new Date(Math.floor(date.getTime() / p) * p);
    },
    getFullDate(date) {
      var mm = date.getMonth() + 1; // getMonth() is zero-based
      var dd = date.getDate();
      return [
        date.getFullYear(),
        (mm > 9 ? "" : "0") + mm,
        (dd > 9 ? "" : "0") + dd
      ].join("-");
    },
    onEventClick(event, e) {
      this.dialog = true;
      this.createActions = false;
      this.editActions = true;
      this.contentFull = event.contentFull;
      var self = this;
      setTimeout(function() {
        self.startDate = event.start.substring(0, 10);
        self.endDate = event.end.substring(0, 10);
        self.startTime = event.start.substring(11, 16);
        self.endTime = event.end.substring(11, 16);
        self.startDateTime = self.startDate + " " + self.startTime;
      }, 400);
      // Prevent navigating to narrower view (default vue-cal behavior).
      if (e != undefined) {
        e.stopPropagation();
      }
    },
    removeEvent(startDate) {
      console.log(startDate);
      for (var i = 0; i < this.dEvents.length; i++) {
        if (this.dEvents[i].start == startDate) {
          this.dEvents.splice(i, 1);
          break;
        }
      }
      this.dialog = false;
    },
    updateEvent(startDate) {
      this.event = {};
      this.event.start = this.startDate + " " + this.startTime;
      this.event.end = this.startDate + " " + this.endTime;
      //this.event.title = this.title;
      this.event.content = this.content;
      this.event.class = this.class;
      this.event.contentFull = this.contentFull;
      this.removeEvent(startDate);
      this.addEvent();
    },
    addEvent() {
      this.dialog = false;
      this.createActions = true;
      this.editActions = false;
      this.event = {};
      this.event.start = this.startDate + " " + this.startTime;
      this.event.end = this.startDate + " " + this.endTime;
      //this.event.title = this.title;
      this.event.content = this.content;
      this.event.class = this.class;
      this.event.contentFull = this.contentFull;
      this.dEvents.push(this.event);
    },
    onEventCreate(event, e) {
      this.dialog = true;
      this.createActions = true;
      this.editActions = false;
      this.contentFull = "";
      var self = this;
      setTimeout(function() {
        self.startDate = self.getFullDate(event.startDate);
        self.startTime =
          event.startDate.getHours() < 10
            ? "0" + event.startDate.getHours() + ":00"
            : event.startDate.getHours() + ":00";
        self.endTime =
          event.startDate.getHours() + 1 < 10
            ? "0" + (event.startDate.getHours() + 1) + ":00"
            : event.startDate.getHours() + 1 + ":00";
        self.startDateTime = self.startDate + " " + self.startTime;
      }, 400);

      // Prevent navigating to narrower view (default vue-cal behavior).
      //e.stopPropagation();
    }
  }
};
</script>
<style>
.disabled-time-picker .datetimepicker .pickers-container {
  display: none;
}
.disabled-text-area.theme--light.v-input--is-disabled textarea {
  color: black;
}
</style>