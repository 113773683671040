<template>
  <div class="mx-auto page-normal">
    <PageHeader :title="page.title"></PageHeader>
    <PageActions :breadcrumbs="null" :alertBar="alertBar"></PageActions>
    <PageContent :loading="false">
      <v-row>
        <v-col v-if="userTestStatus == 'FINISHED'" cols="12" md="8">
            <span v-html="$t('views.tests.doneTests')"></span>
            <Button :type="'confirm'"
              ><a :href="testReportUrl" target="_blank" class="white--text"
                >Ladda ned din testrapport</a
              ></Button
            >
        </v-col>
        <v-col v-else-if="userTestStatus == 'NOT_ALLOWED'" cols="12" md="8">
            <span v-html="$t('views.tests.notAllowed')"></span>
        </v-col>
        <v-col v-else cols="12" md="8">
          <div>
            <span v-html="$t('views.tests.notDoneTests')"></span>
            <!-- <span>{{$t(page.testResultsPlaceholder)}}</span> -->

            <LoadingButton v-if="loadingTests" />

            <Button v-if="testUrl != null" :type="'confirm'"
              ><a :href="testUrl" target="_blank" class="white--text"
                >Starta test</a
              ></Button
            >
          </div>
        </v-col>
      </v-row>
    </PageContent>
  </div>
</template>
<script>
import basic from "../mixins/basic";
import AlertBarMixin from "@/mixins/alertBar";

import { mapState, mapActions } from "vuex";

export default {
  mixins: [basic, AlertBarMixin],
  data: () => {
    return {
      loadingTests: true,
      testUrl: null,
      page: {
        key: "tests",
        title: "Tester",
        testResultsPlaceholder: "views.tests.testResultsPlaceholder",
        continueMessage: "Vill du spara dina ändringar?",
        continueBtnMessage: "Spara",
        missingAnswersMessage: "Svara på alla frågor för att gå vidare",
        missingAnswersBtnMessage: "Visa",
      },
    };
  },
  methods: {
    ...mapActions("tests", { getTests: "get" }),
  },
  computed: {
    ...mapState("tests", ["userTestStatus", "linkDefaultTest", "testReportUrl"]),
  },
  beforeMount: async function () {
    try {
      await this.getTests();
      this.testUrl = this.linkDefaultTest;
      this.loadingTests = false;
    } catch (e) {
      this.loadingTests = false;
      console.log(e);
      this._handleAlertBar({
        visible: true,
        message: "Kunde inte hämta tester",
        btnMessage: "Försök igen",
      });
    }
  },
};
</script>