<template>
  <div>
    <v-select
    :disabled="disabled" 
      v-if="answerAlternatives.length>0"
      multiple
      v-model="dAnswer"
      :items="answerAlternatives"
      item-value="val"
      item-text="text"
      class="secondary-color-chips"
      chips
      color="secondary"
      deletable-chips
    >
<!--       <template v-slot:selection="{ item, index }">
        <v-chip color="secondary">
          <span>{{ item.text }}</span>
        </v-chip>
      </template> -->
    </v-select>

    <v-flex v-else class="d-flex">
      <v-checkbox
      :disabled="disabled" 
        xs6
        v-for="alt in answerAlternatives"
        :key="alt.id"
        color="primary"
        v-model="dAnswer"
        :label="alt.text"
        :value="alt.val"
      ></v-checkbox>
    </v-flex>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      selected: [],
      dAnswer: this.answer ? this.answer : [],
      dValidAnswer: false,
      typing: false,
      typingDone: false
    };
  },
  props: {
    disabled: {
      type:Boolean,
      default: false,
    },
    id: Number,
    title: String,
    tab: String,
    groupAnswers: Boolean,
    answer: Array,
    answerAlternatives: Array,
    questionText: String,
    dimmed: Boolean,
    visible: Boolean,
    introText: String,
    instructionText: String
  },
  watch: {
    dAnswer: {
      handler: function() {
        this.dValidAnswer = false;
        if (
          this.dAnswer != null &&
          this.dAnswer != false &&
          this.dAnswer != ""
        ) {
          this.dValidAnswer = true;
        }
        this.$emit("changeAnswer", {
          id: this.id,
          //tab: this.tab,
          answer: this.dAnswer,
          validAnswer: this.dValidAnswer
        });
      },
      deep: true
    }
  },
  methods: {}
};
</script>