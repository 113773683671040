<template>
  <div
    @click="detectClick($event)"
    :id="tab + id"
    v-if="visible"
    class="clear"
    v-bind:class="{ 'dim': dimmed==true}"
  >
   <chatbox
      :questionText="questionText"
      :introText="introText"
      :instructionText="instructionText"
      :tab="tab"
      :typing="typing"
      v-on:typingDone="showAlternatives"
    /> 
    <transition name="fade">
    <div v-if="typingDone" class="text-right">
      <div>
        <br />
        <v-chip
          v-if="!confirmedOnce && !autoContinue"
          @click="confirmQuestion"
           color="secondary"
        >{{OKText}}</v-chip>
      </div>
    </div>
    </transition>
  </div>
</template>
<script>
import chatbox from "./misc/chatbox";

export default {
  components: {
    chatbox
  },
  data() {
    return {
      confirmedOnce: false,
      typing: false,
      typingDone: false,
      OKText: this.atbotOKText || 'OK'
    };
  },
  props: {
    id: Number,
    tab: String,
    title: String,
    questionText: String,
    dimmed: Boolean,
    visible: Boolean,
    introText: String,
    instructionText: String,
    autoContinue: Boolean,
    atbotOKText: {
      type:String,
      default:"OK"}
  },
  watch: {
    visible: function() {
      if (this.visible) {
        this.typing = true;
      }
    }
  },
  methods: {
    showAlternatives() {
      this.typingDone = true;
      console.log("auto is : " + this.autoContinue)
      if(this.autoContinue == true) {
        this.confirmQuestion();
        this.detectClick();
      }
    },
    detectClick(event) {
      this.$emit("clicked", { event: event, id: this.id, tab: this.tab });
    },
    confirmQuestion() {
      this.confirmedOnce = true;
      this.$emit("changeAnswer", {
        event: event,
        id: this.id,
        tab: this.tab,
        validAnswer: true
      });
    }
  }
};
</script>