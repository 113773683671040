module.exports = {
    status: 'initialize',
    jobs: null,
    applications: null,
    upcomingInterviews: null,
    invitedToInterviews: null,
    matchedJobs: null,
    jobStatusDefinition: {
        "PUBLISHED": { statusText: "Öppen", statusColor: "open" },
        "INTERVIEWS": { statusText: "Intervjuer pågår", statusColor: "ongoing" },
        "CANCELLED": { statusText: "Avbruten", statusColor: "closed" },
        "FINISHED": { statusText: "Slutförd", statusColor: "finished" }
     },
    applicantsStatusDefinition: {
        "CANCELLED": { statusText: "Avbruten", statusColor: "closed" },
        "APPLIED": { statusText: "Ansökt", statusColor: "open" },
        "INVITED": { statusText: "Inbjuden på intervju", statusColor: "ongoing" },
        "BOOKED": { statusText: "Bokat", statusColor: "ongoing" },
        "COMPLETED": { statusText: "Haft intervju", statusColor: "ongoing" },
        "REJECTED": { statusText: "Avböjt", statusColor: "closed" },
        "HIRED": { statusText: "Anställd!", statusColor: "finished" },
    },
    interviewStatusDefinition: {
        "AVAILABLE": { statusText: "Ansökt", statusColor: "open" },
        "BOOKED": { statusText: "Bokat", statusColor: "ongoing" },
        "COMPLETED": { statusText: "Haft intervju", statusColor: "finished" },
        "CANCELLED": { statusText: "Avböjt", statusColor: "closed" },
        "DELETED": { statusText: "Anställd!", statusColor: "closed" },
    },
    applicantValidStatusToChangeTo: {
        "CANCELLED": ["APPLY"],
        "APPLIED": ["CANCELLED"],
        "INVITED": ["CANCELLED"],
        "BOOKED": ["CANCELLED"],
        "COMPLETED": ["CANCELLED", "HIRED"],
        "REJECTED": [],
        "HIRED": ["CANCELLED", "APPLIED"]
      },
    headers: [
        { text: "Logga", value: "image", width: 100, sortable: false },
        { text: "Status", value: "jobStatusText", width: 85,sortable: false },
        {
          text: "Tjänst",
          align: "left",
          width: 200,
          sortable: true,
          value: "title",
        },
        { text: "Företag", width: 190, value: "company" },
        { text: "Ort", width: 140, value: "cityText" },
        { text: "Avslutas", width: 140, value: "application_period_end" },
      ],
}