

var { nativeLogin, nativeCreate, resetPassword, changePassword } = require('../../requests/global')

const isLoggedIn = (context, data) => {
    context.commit('isLoggedIn', data);
}

const verify = (context, data) => {
    return (context.state.token != null) ? true : false;
}

const load = (context, data) => {
    var token = $cookies.get("token");
    if (token != null) context.dispatch("token", token);
    return;
}

const token = (context, token) => {
    context.commit('token', token);
    if (token != null) {
        $cookies.set("token", token, context.state.tokenExpirationTime, null, null, false)
        context.dispatch("isLoggedIn", true);
    }
}

const login = async (context, data) => {
    var res = await nativeLogin({ body: { username: data.username, email: data.username, password: data.password } });
    if (res.token != null) { context.dispatch("token", res.token); }
    return;
}

const logout = async (context, data) => {
    $cookies.remove("token");
    window.location.reload();
    return;
}

const createNativeUser = async (context, data) => {
    var token = await nativeCreate({ body: { username: data.email, email: data.email, password: data.password } })
    context.dispatch("token", token.token);
}

const passwordReset = async (context, data) => {
    var reset = await resetPassword({ body: { action: "RESET", email: data } });
}

const updatePassword = async (context, data) => {
    var change = await changePassword({ body: { action: "CHANGE", token: data.token, newPassword: data.newPassword, currentPassword: data.currentPassword } });
}


export default {
    isLoggedIn: isLoggedIn,
    token: token,
    load: load,
    verify: verify,
    login: login,
    logout: logout,
    createNativeUser: createNativeUser,
    passwordReset: passwordReset,
    updatePassword:updatePassword
}