import AlertBar from "../components/basic/AlertBar";

export default {
    components: {
        AlertBar
    },
    data: () => {
        return {
            alertBar: {
                visible: false,
                message: null,
                btnMessage: null,
            }
        }
    },
    methods: {
        _handleAlertBar: function({visible, message, btnMessage}) {
            this.alertBar.visible = visible;
            this.alertBar.message = message;
            this.alertBar.btnMessage = btnMessage;
        },
        handleAlertBar: function({visible, message, btnMessage}) {
            this.alertBar.visible = visible;
            this.alertBar.message = message;
            this.alertBar.btnMessage = btnMessage;
        }
    }
}