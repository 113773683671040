import { render, staticRenderFns } from "./Profile.vue?vue&type=template&id=6e791bf2&"
import script from "./Profile.vue?vue&type=script&lang=js&"
export * from "./Profile.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsItem } from 'vuetify/lib/components/VBreadcrumbs';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VBreadcrumbs,VBreadcrumbsItem,VCol,VDivider,VIcon,VRow,VSwitch,VTabItem,VTabsItems})
