<template>
  <v-alert prominent type="error">
    <v-row align="center">
      <v-col class="grow">{{errorMessage}}</v-col>
      <v-col class="shrink">
        <v-btn @click="$router.go()">Ladda om sidan</v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>
<script>
export default {
  props: {
    errorMessage: {
      type: String,
      default: "Något gick fel, försök gärna igen"
    }
  }
};
</script>