<template>
  <!-- <v-app-bar
    app
    bottom
    active-class="font-weight-black"
    background-color="primary"
    @change="updateIcons"
    v-if="$vuetify.breakpoint.mdAndDown"
    v-model="navigation"
    dark
    hide-on-scroll
  >
    <v-btn v-for="item in items" :key="item.id" :value="item.name" :to="item.path">
      <span>{{$t('routes.'+item.key)}}</span>
      <v-badge v-if="item.notification" left overlap color="error" icon="priority_high">
        <v-icon small v-text="item.icon"></v-icon>
      </v-badge>
      <v-icon v-else small v-text="item.icon"></v-icon>
    </v-btn>
  </v-app-bar> -->
   <v-bottom-navigation
    app
    fixed
    active-class="font-weight-black"
    background-color="primary"
    @change="updateIcons"
    v-if="$vuetify.breakpoint.mdAndDown"
    v-model="navigation"
    dark
  >
    <v-btn v-for="item in items" :key="item.id" :value="item.name" :to="item.path">
      <span>{{$t('routes.'+item.key)}}</span>
      <v-badge v-if="item.notification" left overlap color="error" icon="priority_high">
        <v-icon small v-text="item.icon"></v-icon>
      </v-badge>
      <v-icon v-else small v-text="item.icon"></v-icon>
    </v-btn>
  </v-bottom-navigation> 
</template>
<script>
import routes from "../../routes";

export default {
  props: {
    source: String
  },
  data: () => ({
    navigation: "Företaget",
    items: routes.mobile_items
  }),
  methods: {
    updateIcons: function() {
      //console.log("nav change");
      //console.log(this.navigation);
    }
  }
};
</script>
<style scoped>
.v-btn:not(.v-btn--round).v-size--default {
  height: inherit;
}
.v-item-group.v-bottom-navigation .v-btn {
  font-size: 0.6rem;
  min-width: 60px;
  width: 20%;
}
</style>