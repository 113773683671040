<template>
  <div
    @click="detectClick($event)"
    :id="tab + id"
    v-if="visible"
    class="clear"
    v-bind:class="{ 'dim': dimmed==true}"
  >
    <chatbox
      :questionText="questionText"
      :introText="introText"
      :instructionText="instructionText"
      :tab="tab"
      :typing="typing"
      v-on:typingDone="showAlternatives"
    />
    <transition name="fade">
      <div v-if="typingDone" class="text-xs-right">
        <h3 v-if="error">{{errorMessage}}</h3>
        <div v-if="!applied">
          <v-layout wrap>
            <v-flex xs12 v-bind:class="{ 'groupAnswers': groupAnswers}">
              <ul>
                <li v-for="alt in answerAlternatives" :key="alt.val">
                  <input v-model="dAnswer" type="radio" :value="alt.val" :id="id+alt.text" />
                  <label :for="id+alt.text" @click.stop>
                    <v-chip
                      v-bind:class="{ 'v-chip--outline': dAnswer!=alt.val, 'white--text':dAnswer==alt.val}"
                    >{{alt.text}}</v-chip>
                  </label>
                </li>
              </ul>
            </v-flex>
          </v-layout>
          <transition name="fade">
            <div v-if="dAnswer != null">
              <div v-if="dAnswer == 1">
                <br />
                <v-chip class="white--text" @click="confirmQuestion(dAnswer)">Skicka in ansökan</v-chip>
                <Spinner v-if="loading" v-bind:message="'Skickar in ansökan'" />
              </div>
              <div v-else>
                <br />
                <v-chip class="white--text" @click="nextQuestion()">Gå vidare utan att söka</v-chip>
              </div>
            </div>
          </transition>
        </div>
        <div v-else>
          <div v-if="showAlertBox">
            <v-alert
              :value="true"
              color="error"
              icon="new_releases"
              class="text-center"
            >{{errorMessage}}</v-alert>
            <div>
              <br />
              <v-chip
                v-if="questionFinished==false"
                v-bind:class="{ 'v-chip--outline':questionFinished==false, 'white--text':questionFinished==true}"
                @click="nextQuestion()"
              >Fortsätt</v-chip>
            </div>
          </div>
          <div v-else>
            <chatbox
              :questionText="applyText"
              :instructionText="applicationComments"
              :tab="tab"
              typing="false"
            />
            <div>
              <br />
              <v-chip
                v-if="questionFinished==false"
                v-bind:class="{ 'v-chip--outline':questionFinished==false, 'white--text':questionFinished==true}"
                @click="nextQuestion()"
              >Fortsätt</v-chip>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import chatbox from "./misc/chatbox";
import requests from "../../../plugins/requests";
import store from "../../../store/store";
import Spinner from "./misc/Spinner.vue";
import AlertBox from "./misc/AlertBox";

export default {
  components: {
    chatbox,
    Spinner,
    AlertBox
  },
  data() {
    return {
      dAnswer: this.answer,
      loading: false,
      applied: false,
      applyText: "Din ansökan är nu mottagen",
      confirmedOnce: false,
      error: false,
      showAlertBox: false,
      errorMessage: "Något gick fel",
      dValidAnswer: false,
      typing: false,
      typingDone: false,
      answerAlternatives: [{ val: 1, text: "Ja" }, { val: 2, text: "Nej" }],
      OKText: this.atbotOKText || "Skicka in ansökan",
      applicationComments: null,
      questionFinished: false
    };
  },
  props: {
    id: Number,
    title: String,
    tab: String,
    groupAnswers: Boolean,
    answer: Number,
    questionText: String,
    instructionText: String,
    dimmed: Boolean,
    visible: Boolean,
    introText: String,
    atbotOKText: {
      type: String,
      default: "OK"
    }
  },
  watch: {
    dAnswer: {
      handler: function() {
        console.log(this.dAnswer);

        /*         if (
          this.dAnswer != null &&
          this.dAnswer != false &&
          this.dAnswer != ""
        ) {
          this.dValidAnswer = true;
        }
        this.$emit("changeAnswer", {
          id: this.id,
          tab: this.tab,
          answer: this.dAnswer,
          validAnswer: this.dValidAnswer
        }); */
      },
      deep: true
    }
  },
  methods: {
    nextQuestion() {
      this.questionFinished = true;
      this.$emit("changeAnswer", {
        id: this.id,
        tab: this.tab,
        answer: this.dAnswer,
        validAnswer: true
      });
      this.$emit("clicked", {
        id: this.id,
        tab: this.tab,
        validAnswer: true
      });
    },
    confirmQuestion() {
      this.confirmedOnce = true;
      if (this.dAnswer == 1) {
        this.loading = true;
        requests.methods.apply(store.state.jobGuid)
          .then(result => {
            this.loading = false;
            this.applied = true;
            this.applyText =
              "Din ansökan är nu mottagen, du har " +
              result.overall_score_percent +
              "% chans att få jobbet. Lycka till!";
          })
          .catch(error => {
            if (error == "Already applied for job") {
              this.applicationComments =
                "Du har redan sökt detta jobb tidigare. Kul att du är taggad!";
            } else {
              this.showAlertBox = true;
              this.errorMessage = error;
            }
            this.applied = true;
            this.loading = false;
          });
      } else {
      }
    },
    showAlternatives() {
      this.typingDone = true;
    },
    detectClick(event) {
      /*       this.$emit("clicked", {
        event: event,
        id: this.id,
        tab: this.tab,
        validAnswer: this.dValidAnswer
      }); */
    }
  }
};
</script>
