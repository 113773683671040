const status = (state, status) => {
    state.status = status;
};

const jobs = (state, jobs) => {
    state.jobs = jobs;
};

const upcomingInterviews = (state, upcomingInterviews) => {
    state.upcomingInterviews = upcomingInterviews;
};

const invitedToInterviews = (state, invitedToInterviews) => {
    state.invitedToInterviews = invitedToInterviews;
};

const applications = (state, applications) => {
    state.applications = applications;
};

const matchedJobs = (state, matchedJobs) => {
    state.matchedJobs = matchedJobs;
};


export default {
    status,
    jobs,
    matchedJobs,
    applications,
    upcomingInterviews,
    invitedToInterviews
}