const store = require('../store/store');
const axios = require('axios')

const headers = {
    headers: {
        briteUserToken: 'BRITE USER TOKEN'
    },
}

//var baseUrl = 'https://us-central1-higher.cloudfunctions.net/rec';
//const devBaseUrl = 'https://us-central1-higher.cloudfunctions.net/rec';
//const localBaseUrl = 'http://localhost:3000';

var baseUrl = process.env.VUE_APP_JOBS_BASE_URL;
const devBaseUrl = process.env.VUE_APP_JOBS_BASE_URL;

console.log(process.env.VUE_APP_JOBS_BASE_URL)


if (process.env.NODE_ENV == "development") {

    // Dev mode
    // baseUrl = 'http://localhost:3000';

}

// Devmode
// baseUrl = localBaseUrl;

const EPuploadFile = '/upload/at/file'
const EPinfo = '/at/info'
const EPtests = '/at/tests'
const EPsettings = '/at/settings'
const EPquestions = '/at/questions'
const EPbasicJobInfo = '/jobs'
const EPjobs = '/at/jobs'
const EPapplications = '/at/applications'
const EPinterviews = '/at/interviews'
const EPsignupQuestions = '/signup/questions'
const EPcitySuggestions = '/external/cities'
const EPjobFeedback = '/feedback/jobs'
const EPnativeValidate = '/auth/at/validate'
const EPnativeLogin = '/auth/at/login'
const EPnativeCreate = '/auth/at/user'
const EPpassword = '/auth/at/password'

// Links for oauth token redirect 
//const EPauthGoogle = '/auth/google/redirect'
//const EPauthFacebook = '/auth/facebook/redirect'

async function briteRequest({ type, ep, body, params, dev, id, url, propagate_401_error }) {
    try {
        if (url == undefined) {
            var url = (dev) ? devBaseUrl + ep : baseUrl + ep;
            url = (id) ? url + '/' + id : url;
        }
        if (type == 'GET') {
            const response = await axios.get(url, { ...headers, params: params })
            return response.data
        }
        if (type == 'POST') {
            const response = await axios.post(url, body, headers)
            return response.data
        }
        if (type == 'PUT') {
            const response = await axios.put(url, body, headers)
            return response.data
        }
        if (type == 'DELETE') {
            const response = await axios.delete(url, { ...headers })
            return response.data
        }
    }
    catch (e) {
        if (e.response) {
            console.log(e.response.data);
            console.log(e.response.status);
            console.log(e.response.headers);
            if (e.response.status == 401 && propagate_401_error) {
                throw e
            }
            else if (e.response.status == 401) {
                console.log("Logging out")
                store.default.dispatch("user/logout");
            }
            else {
                throw e
            }
        }
        else {
            console.log(e);
            throw e
        }
    }
}

const getInfo = async () => { return await briteRequest({ type: "GET", ep: EPinfo }) }
const getTests = async () => { return await briteRequest({ type: "GET", ep: EPtests }) }
const getSettings = async () => { return await briteRequest({ type: "GET", ep: EPsettings }) }
const saveSettings = async ({ body }) => { return await briteRequest({ type: "PUT", ep: EPsettings, body: body }) }
const getSignupQuestions = async ({ signup, params }) => { return await briteRequest({ type: "GET", ep: (signup) ? EPsignupQuestions : EPquestions, params: params }) }
const getBasicJobInfo = async ({ params }) => { return await briteRequest({ type: "GET", ep: EPbasicJobInfo, params: params }) }
const getQuestions = async ({ signup, params }) => { return await briteRequest({ type: "GET", ep: (signup) ? EPsignupQuestions : EPquestions, params: params }) }
const saveQuestions = async ({ body }) => { return await briteRequest({ type: "POST", ep: EPquestions, body: body }) }
const getJobs = async ({ params, ep = false }) => { return await briteRequest({ type: "GET", ep: (ep) ? ep : EPjobs, params: params }) }
const getApplications = async ({ params, ep }) => { return await briteRequest({ type: "GET", ep: (ep) ? ep : EPapplications, params: params }) }
const postApplication = async ({ body }) => { return await briteRequest({ type: "POST", ep: EPapplications, body: body }) }
const deleteApplication = async ({ id }) => { return await briteRequest({ type: "DELETE", ep: EPapplications + "/" + id }) }
const getInterviews = async ({ params }) => { return await briteRequest({ type: "GET", ep: EPinterviews, params: params }) }
const putInterview = async ({ body }) => { return await briteRequest({ type: "PUT", ep: EPinterviews, body: body }) }
const getCitySuggestions = async ({ params }) => { return await briteRequest({ type: "GET", ep: EPcitySuggestions, params: params }) }
const postJobFeedback = async ({ body }) => { return await briteRequest({ type: "POST", ep: EPjobFeedback, body: body }) }
const nativeLogin = async ({ body }) => { return await briteRequest({ type: "POST", ep: EPnativeLogin, body: body, propagate_401_error: true }) }
const nativeValidate = async ({ body }) => { return await briteRequest({ type: "POST", ep: EPnativeValidate, body: body }) }
const nativeCreate = async ({ body }) => { return await briteRequest({ type: "POST", ep: EPnativeCreate, body: body }) }
const resetPassword = async ({ body }) => { return await briteRequest({ type: "POST", ep: EPpassword, body: body }) }
const changePassword = async ({ body }) => { return await briteRequest({ type: "POST", ep: EPpassword, body: body }) }
const uploadFile = async ({ formData }) => { return await briteRequest({ type: "POST", ep: EPuploadFile, body: formData , propagate_401_error: true }) }

module.exports = {
    getInfo:getInfo,
    getTests:getTests,
    getBasicJobInfo: getBasicJobInfo,
    getSettings: getSettings,
    saveSettings: saveSettings,
    getQuestions: getQuestions,
    getSignupQuestions: getSignupQuestions,
    saveQuestions: saveQuestions,
    getJobs: getJobs,
    getApplications: getApplications,
    postApplication: postApplication,
    deleteApplication: deleteApplication,
    getInterviews: getInterviews,
    putInterview: putInterview,
    getCitySuggestions: getCitySuggestions,
    postJobFeedback: postJobFeedback,
    nativeLogin: nativeLogin,
    nativeValidate: nativeValidate,
    nativeCreate: nativeCreate,
    resetPassword: resetPassword,
    changePassword: changePassword,
    uploadFile: uploadFile
} 