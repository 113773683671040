<template>
  <div
    @click="detectClick($event)"
    :id="tab + id"
    v-if="visible"
    class="clear cities-question"
    v-bind:class="{ dim: dimmed == true }"
  >
    <chatbox
      :questionText="questionText"
      :introText="introText"
      :instructionText="instructionText"
      :tab="tab"
      :typing="typing"
      v-on:typingDone="showAlternatives"
    />

    <transition name="fade">
      <div v-if="typingDone" class="text-xs-right">
        <v-layout class="text-xs-right justify-end" wrap>
          <v-flex xs10>
            <div>
              <!-- <v-flex xs12>
<div>{{city_list_fix}}</div>
              </v-flex>-->
              <v-flex xs12 class="flex-right-input">
                <v-dialog
                  v-model="dialog"
                  fullscreen
                  hide-overlay
                  transition="dialog-bottom-transition"
                >
                  <v-card class="autocomplete popup cities-question">
                    <v-sheet class="pa-3 primary">
                      <v-text-field
                        xs12
                        ref="cityDialogInput"
                        v-model="cityDialogQuery"
                        prepend-inner-icon="keyboard_backspace"
                        @click:prepend-inner="dialog = false"
                        append-icon="search"
                        placeholder="Sök..."
                        dark
                        flat
                        solo-inverted
                        hide-details
                        clearable
                        clear-icon="close"
                      ></v-text-field>
                    </v-sheet>
                    <v-card-text>
                      <div class="text-right">
                        <transition name="fade" mode="out-in">
                          <v-subheader
                            v-if="
                              showOnlyOneAnswerMessage && confirmedOnce == true
                            "
                            :key="1"
                            class="pl-0 mb-2"
                            >{{ onlyOneAnswerMessage }}</v-subheader
                          >
                        </transition>
                      </div>

                      <h3>Valda städer:</h3>
                      <transition-group name="list-complete">
                        <span
                          class="list-complete-item"
                          v-for="cty in sortedCity"
                          v-bind:key="'valda' + cty.description"
                          @click="removeCity(cty)"
                        >
                          <v-chip color="secondary" dark>
                            {{ cty.name }}
                            <v-icon
                              v-if="city.length > 1 || confirmedOnce == false"
                              >close</v-icon
                            >
                          </v-chip>
                        </span>
                        <span
                          style="visibility: hidden"
                          :key="'cities-mobile-fallback-empty-chip'"
                        >
                          <v-chip color="secondary" dark>
                            Lite
                            <v-icon>close</v-icon>
                          </v-chip>
                        </span>
                      </transition-group>
                      <h3 class="mt-4">Sökresultat:</h3>
                      <transition-group name="slide-fade">
                        <span
                          class="d-inline-block"
                          v-for="cty in city_list_fix"
                          v-bind:key="'search' + cty.description"
                          @click="addCity(cty)"
                        >
                          <v-chip outlined color="secondary" dark>
                            {{ cty.name }}
                            <v-icon>add</v-icon>
                          </v-chip>
                        </span>
                      </transition-group>

                      <!-- <v-checkbox
color="primary"
v-for="cty in city_list_fix"
v-bind:key="'check'+cty.description"
:label="cty.name"
:value="JSON.stringify(cty)"
v-model="cityDialog"
:dense="true"
class="compact-checkbox"
                      ></v-checkbox>-->
                    </v-card-text>
                  </v-card>
                </v-dialog>
                <v-sheet class="pa-3 mb-6 primary">
                  <v-autocomplete
                    v-model="city_temp"
                    :items="city_list_fix"
                    :search-input.sync="cityQuery"
                    @mouseup="cityFocus"
                    item-text="name"
                    item-value="id"
                    placeholder="Sök..."
                    return-object
                    no-data-text="Inga träffar"
                    no-filter
                    tags
                    dark
                    flat
                    solo-inverted
                    hide-details
                    clearable
                    clear-icon="close"
                  >
                    <template v-slot:selection="data"></template>
                  </v-autocomplete>
                </v-sheet>
              </v-flex>
              <div class="text-right">
                <transition name="fade" mode="out-in">
                  <v-subheader
                    v-if="showOnlyOneAnswerMessage && confirmedOnce == true"
                    :key="1"
                    class="pl-0 mb-2"
                    >{{ onlyOneAnswerMessage }}</v-subheader
                  >
                </transition>
              </div>

              <div>
                <v-layout wrap>
                  <v-flex
                    xs12
                    class="hack-right-align-direction"
                    v-bind:class="{ groupAnswers: city.length > -1 }"
                  >
                    <transition-group name="list-complete">
                      <span
                        class="list-complete-item"
                        v-for="cty in sortedCity"
                        v-bind:key="'valda' + cty.description"
                        @click="removeCity(cty)"
                      >
                        <v-chip color="secondary" dark>
                          {{ cty.name }}
                          <v-icon
                            v-if="city.length > 1 || confirmedOnce == false"
                            >close</v-icon
                          >
                        </v-chip>
                      </span>
                    </transition-group>
                    <span
                      style="visibility: hidden"
                      :key="'cities-fallback-empty-chip'"
                    >
                      <v-chip color="secondary" dark>
                        Lite
                        <v-icon>close</v-icon>
                      </v-chip>
                    </span>
                  </v-flex>
                </v-layout>
              </div>
              <!-- <v-expansion-panel v-if="filteredCity.length>0" xs10 v-model="panel" expand>
<v-expansion-panel-content>
<template v-slot:header>
<div>Valda städer ({{city.length}})</div>
</template>
<v-card>
<v-card-text>
<v-list>
<v-list-tile
class="compact-list"
v-for="cty in filteredCity"
:key="cty.id"
dense
@click="remove(city,cty)"
>
<v-list-tile-content>
<v-list-tile-title>{{cty.name}}</v-list-tile-title>
</v-list-tile-content>
<v-list-tile-action>
<v-list-tile-icon @click="remove(city,cty)">
<v-icon small>close</v-icon>
</v-list-tile-icon>
</v-list-tile-action>
</v-list-tile>
</v-list>
</v-card-text>
</v-card>
</v-expansion-panel-content>
              </v-expansion-panel>-->
            </div>
          </v-flex>
        </v-layout>
        <transition name="fade">
          <div
            v-show="dAnswer.length > 0 && confirmedOnce == false"
            class="flex-right-input text-right mt-4"
          >
            <!-- v-if="showOKbutton && !confirmedOnce" -->
            <v-chip @click="confirmQuestion" color="primary">{{
              OKText
            }}</v-chip>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>
<script>
import chatbox from "./misc/chatbox";
import { nextTick } from "q";
import Vue from "vue";
import { getCitySuggestions } from "../../../requests/global";

export default {
  components: {
    chatbox,
  },
  data() {
    return {
      items: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      nextNum: 10,
      panel: 0,
      showOnlyOneAnswerMessage: false,
      onlyOneAnswerMessage: "Kan inte ta bort stad när bara en stad är vald",
      timeoutID: false,
      debounceDelay: 320,
      dAnswer: [],
      dValidAnswer: false,
      showOKbutton: false,
      confirmedOnce: false,
      typing: false,
      cityQuery: null,
      dialog: false,
      isUpdating: false,
      limit: 100,
      typingDone: false,
      cityDialog: [],
      city_list_fix: [{ id: 1, name: "Hela Sverige" }],
      cityDialogQuery: null,
      city_temp: null,
      city: [],
      cities: [],
      searchQuery: null,
      OKText: this.atbotOKText || "OK",
    };
  },
  props: {
    id: Number,
    tab: String,
    title: String,
    questionText: String,
    dimmed: Boolean,
    visible: Boolean,
    introText: String,
    instructionText: String,
    atbotOKText: {
      type: String,
      default: "OK",
    },
    answer: Array,
  },
  beforeMount() {
    // requests.methods
    //   .getPlaces("sto")
    //   .then(result => {
    //     console.log(result);
    //     this.cities = result;
    //   })
    //   .catch(err => {
    //     console.log("Could not get place");
    //   });
  },
  computed: {
    filteredCity() {
      //return this.limit ? this.city.slice(0, 3) : this.city;
      return this.city;
    },
    sortedCity() {
      return this.city.concat().sort((a, b) => (a.name > b.name ? 1 : -1));
    },
  },
  watch: {
    cityDialog: {
      handler: function (val, oldVal) {
        this.city = [];
        for (var i = 0; i < this.cityDialog.length; i++) {
          this.city[i] = JSON.parse(this.cityDialog[i]);
        }
        this.city.sort((a, b) => (a.name > b.name ? 1 : -1));
        try {
          this.$refs.cityDialogInput.focus();
        } catch (e) {}
        this.cityDialogQuery = null;
        this.$emit("update", this.city);
      },
    },
    city_temp: {
      handler: function (val, oldVal) {
        //console.log("updating city with city_temp");
        var index = this.findId(this.city, this.city_temp.id);
        //console.log(index);
        if (index < 0) {
          this.city[this.city.length] = this.city_temp;
          this.city.sort((a, b) => (a.name > b.name ? 1 : -1));
          this.$emit("update", this.city);
        }
        //this.city_temp = null;
        //console.log("updating city with city_temp");
      },
    },
    city: {
      handler: function (newValue, oldValue) {
        this.cityDialogQuery = null;
        // this.$emit("update", this.city);

        // console.log("changed city");
        // console.log(this.city);
        if (this.city != null) {
          this.dAnswer = this.city;
          this.dValidAnswer = true;
        }
        this.$emit("changeAnswer", {
          id: this.id,
          tab: this.tab,
          answer: this.city,
          validAnswer: this.dValidAnswer,
        });
        setTimeout(() => {
          //this.$refs.cityAutoComplete.isMenuActive = false;
        }, 50);
      },
    },
    cityDialogQuery: {
      handler: async function (val, oldVal) {
        //console.log(val);
        //console.log("type debounce");
        var self = this;
        this.city_list_fix = [];
        clearTimeout(this.timeoutID);
        this.timeoutID = setTimeout(function () {
          //console.log("fire debounce");
          //console.log(val);
          self.getSuggestions(val, true);
        }, this.debounceDelay);
      },
    },
    cityQuery: {
      handler: async function (val, oldVal) {
        //console.log(val);
        //console.log("type debounce");
        var self = this;
        this.city_list_fix = [];
        clearTimeout(this.timeoutID);
        this.timeoutID = setTimeout(function () {
          //console.log("fire debounce");
          //console.log(val);
          self.getSuggestions(val, false);
        }, this.debounceDelay);
      },
      deep: false,
    },
    search(val) {
      val && val !== this.select && this.querySelections(val);
    },
    isUpdating(val) {
      if (val) {
        setTimeout(() => (this.isUpdating = false), 3000);
      }
    },
    visible: function () {
      if (this.visible) {
        this.typing = true;
      }
    },
    dAnswer: {
      handler: function () {
        //console.log(this.dAnswer);
        if (
          this.dAnswer != null &&
          this.dAnswer != false &&
          this.dAnswer.length > 0
        ) {
          this.showOKbutton = true;
        } else {
          this.showOKbutton = false;
        }
        if (this.confirmedOnce) {
          this.$emit("changeAnswer", {
            id: this.id,
            tab: this.tab,
            answer: this.dAnswer,
            validAnswer: this.dValidAnswer,
          });
        }
      },
      deep: true,
    },
  },
  methods: {
    slowAdd: function (value, list, delay) {
      setTimeout(function () {
        //console.log(value.name);
        list.push(value);
      }, delay);
    },
    randomIndex: function () {
      return Math.floor(Math.random() * this.items.length);
    },
    LOadd: function () {
      this.items.push(this.randomIndex());
      this.items.sort((a, b) => (a > b ? 1 : -1));
    },
    LOremove: function () {
      this.items.splice(this.randomIndex(), 1);
    },
    addCity: function (cty) {
      for (var i = 0; i < this.city.length; i++) {
        if (cty.id == this.city[i].id) {
          return;
        }
      }
      this.city.push(cty);
      this.$refs.cityDialogInput.focus();
    },
    getSuggestions: async function (query, addSlowly) {
      this.cityFocus();
      if (query != "" && query != " " && query != " " && query != null) {
        var res = await getCitySuggestions({ params: { input: query } });

        //console.log(this.city_list_fix);

        this.city_list_fix = [];
        this.noResults = true;
        if (res.length > 0) {
          this.noResults = false;
        }
        if (addSlowly) {
          for (var i = 0; i < res.length; i++) {
            this.slowAdd(res[i], this.city_list_fix, i * 120);
          }
        } else {
          this.city_list_fix = res;
        }
      } else {
        this.city_list_fix = [{ id: 1, name: "Hela Sverige" }];
      }
    },
    cityFocus: function () {
      if (this.$vuetify.breakpoint.smAndDown) {
        setTimeout(() => {
          this.dialog = true;
          //console.log(this.$refs);
          try {
            this.$refs.cityDialogInput.focus();
          } catch (e) {}
        }, 1);
      }
    },
    findId: function (obj, id) {
      for (var i = 0; i < obj.length; i++) {
        //console.log("Object is" + obj[i].id);
        //console.log("id is: " + id);
        if (obj[i].id == id) {
          //console.log("Lap find: " + i);
          return i;
        }
      }
      return -1;
    },
    removeCity(item) {
      if (this.confirmedOnce == true && this.city.length < 2) {
        this.showOnlyOneAnswerMessage = true;
        return;
      }
      for (var i = 0; i < this.city.length; i++) {
        if (this.city[i].id == item.id) {
          this.city.splice(i, 1);
        }
      }
    },
    remove(list, item) {
      //console.log(list);
      //console.log(item.id);
      //const index = list.indexOf(item.id);
      const index = this.findId(list, item.id);
      if (index >= 0) list.splice(index, 1);
    },
    filteredList: function (list, answer) {
      return list.filter(function (itm) {
        //console.log(itm.id);
        //console.log(answer.includes(itm.id));
        //console.log(list);
        return answer.includes(itm.id) == true;
      });
    },
    add: function (list, item) {
      //console.log(item.description);
      Vue.set(this.city, list.length, item);
      this.$refs.cityDialogInput.focus();

      //list[list.length] = { value: item.description };
      //console.log(this.city);
    },
    querySelections(v) {
      this.loading = true;
      // Simulated ajax query
      setTimeout(() => {
        this.items = this.states.filter((e) => {
          return (e || "").toLowerCase().indexOf((v || "").toLowerCase()) > -1;
        });
        this.loading = false;
      }, 500);
    },
    showAlternatives() {
      this.typingDone = true;
    },
    detectClick(event) {
      //this.$emit("clicked", { event: event, id: this.id, tab: this.tab });
    },
    confirmQuestion() {
      this.confirmedOnce = true;
      this.validAnswer = true;
      this.$emit("changeAnswer", {
        id: this.id,
        tab: this.tab,
        answer: this.dAnswer,
        validAnswer: this.dValidAnswer,
      });
      this.$emit("clicked", { event: event, id: this.id, tab: this.tab });
    },
  },
};
</script>
<style scoped>
.v-list.overflow-scroll {
  height: 200px;
}
</style>
<style lang="sass" >
.cities-question
  .v-subheader
    font-family: $fontBoldPrimary
    font-weight: bold
  .hack-right-align-direction
    direction: rtl
    span.v-chip__content
      direction: ltr

  .list-complete-item
    transition: all 1s
    display: inline-block

  .list-complete-enter, .list-complete-leave-to
    opacity: 0
    transform: translateY(30px)

  .list-complete-leave-active
    position: absolute

  .slide-fade-enter-active
    transition: all 0.06s ease-in

  .slide-fade-leave-active
    transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0)

  .slide-fade-enter, .slide-fade-leave-to
    transform: translateY(30px)
    opacity: 0
  .slide-fade-move
    transition: transform 1s

  .slide-fade-x-enter-active
    transition: all .3s ease

  .slide-fade-x-leave-active
    transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0)

  .slide-fade-x-enter, .slide-fade-x-leave-to
    transform: translateX(150px)
    opacity: 0
</style>
	