//import { axios } from 'axios';

import Vue from 'vue'
import store from "../store/store.js";
//import { reject } from 'q';

export default {
    methods: {
        saveQuestions: function (questions) {
            var encryptedAccessToken = $cookies.get("encrypted_access_token");
            var encryptedSessionToken = ($cookies.get("encrypted_session_token") != null) ? $cookies.get("encrypted_session_token") : '';
            if (encryptedAccessToken == null) { return; }
            return new Promise((resolve, reject) => {
                Vue.axios.post("https://us-central1-higher.cloudfunctions.net/service/signup/questions",
                    //Vue.axios.post("http://localhost:3000/signup/questions",
                    {
                        encryptedAccessToken: encryptedAccessToken,
                        encryptedSessionToken: encryptedSessionToken,
                        questions: questions
                    })
                    .then((response) => {
                        console.log("Save possible?");
                        console.log(response.data);
                        resolve(response.data)
                    },
                        (error) => {
                            console.log(error);
                            reject(error);
                        });
            });
        },
        cancelApplication: function (jobGuid) {
            var encryptedAccessToken = $cookies.get("encrypted_access_token");
            var encryptedSessionToken = ($cookies.get("encrypted_session_token") != null) ? $cookies.get("encrypted_session_token") : '';
            if (encryptedAccessToken == null) { return; }
            return new Promise((resolve, reject) => {
                Vue.axios.post("https://us-central1-higher.cloudfunctions.net/service/at/applications/" + jobGuid + "/cancel",
                    //Vue.axios.post("http://localhost:3000/at/applications/"+jobGuid + "/cancel",
                    {
                        encryptedAccessToken: encryptedAccessToken,
                        encryptedSessionToken: encryptedSessionToken,
                    })
                    .then((response) => {
                        // console.log("What is the token?");
                        // console.log(response.data);
                        resolve(response.data)
                    },
                        (error) => {
                            console.log(error);
                            reject(error);
                        });
            });
        },
        applyApplication: function (jobGuid) {
            var encryptedAccessToken = $cookies.get("encrypted_access_token");
            var encryptedSessionToken = ($cookies.get("encrypted_session_token") != null) ? $cookies.get("encrypted_session_token") : '';
            if (encryptedAccessToken == null) { return; }
            return new Promise((resolve, reject) => {
                Vue.axios.post("https://us-central1-higher.cloudfunctions.net/service/at/applications/" + jobGuid + "/apply",
                    //Vue.axios.post("http://localhost:3000/at/applications/"+jobGuid + "/apply",
                    {
                        encryptedAccessToken: encryptedAccessToken,
                        encryptedSessionToken: encryptedSessionToken,
                    })
                    .then((response) => {
                        // console.log("What is the token?");
                        // console.log(response.data);
                        resolve(response.data)
                    },
                        (error) => {
                            console.log(error);
                            reject(error);
                        });
            });
        },
        getPlaces(query) {
            //console.log("query is " + query)
            return new Promise((resolve, reject) => {
                //Vue.axios.get("http://localhost:3000/external/cities", {
                Vue.axios.get("https://us-central1-higher.cloudfunctions.net/service/external/cities", {
                    params: {
                        input: query
                    }
                })
                    //Vue.axios.get("http://localhost:8010/higher/us-central1/autocompleteCity")
                    .then((response) => {
                        // console.log(response.data);
                        //response.data.unshift({id:1,name:"Hela Sverige"});
                        resolve(response.data);
                    }, (error) => {
                        console.log(error);
                    });
            });
        },
        checkIfLoggedIn: function () {
            return new Promise((resolve, reject) => {
                store.commit('isLoggedIn', false);
                //store.commit('globalLoaderMessage', 'Kontrollerar användare');
                //store.commit('showGlobalLoader', true);
                this.getUserInfo()
                    .then(result => {
                        //console.log(result);
                        //console.log(result.validToken);
                        if (result.validToken == true) {
                            //console.log("Valid login token");
                            store.commit('isLoggedIn', true);
                            //store.commit('showGlobalLoader', false);
                            //console.log(store.state.isLoggedIn);
                            resolve(true);

                        }
                        else {
                            //store.commit('showGlobalLoader', false);
                            store.commit('isLoggedIn', false);
                            resolve(false);
                        }

                    })
                    .catch(err => {
                        console.log("Could not get user data" + err);
                        store.commit('isLoggedIn', false);
                        reject(err);
                    });
            });
        },
        getJobs: function () {
            var encryptedAccessToken = $cookies.get("encrypted_access_token");
            var encryptedSessionToken = ($cookies.get("encrypted_session_token") != null) ? $cookies.get("encrypted_session_token") : '';
            return new Promise((resolve, reject) => {
                if (encryptedAccessToken == null) {
                    reject({ validToken: false });
                }
                //Vue.axios.get("http://localhost:3000/at/jobs",
                Vue.axios.get("https://us-central1-higher.cloudfunctions.net/service/at/jobs",
                    {
                        //Vue.axios.get("http://localhost:3000/ag/jobs", {
                        params: {
                            encryptedAccessToken: encryptedAccessToken,
                            encryptedSessionToken: encryptedSessionToken
                        }
                    })
                    .then((response) => {
                        console.log(response.data);
                        store.dispatch('updateJobs', response.data)


                    },
                        (error) => {
                            console.log(error);
                            reject({ validToken: false });
                        });
            });
        },
        getInterviewTimes: function (id) {
            var encryptedAccessToken = $cookies.get("encrypted_access_token");
            var encryptedSessionToken = ($cookies.get("encrypted_session_token") != null) ? $cookies.get("encrypted_session_token") : '';
            return new Promise((resolve, reject) => {
                if (encryptedAccessToken == null) {
                    reject({ validToken: false });
                }
                //Vue.axios.get("http://localhost:3000/at/applications/" + id + "/invites",
                Vue.axios.get("https://us-central1-higher.cloudfunctions.net/service/at/applications/" + id + "/invites",
                    {
                        //Vue.axios.get("http://localhost:3000/ag/jobs", {
                        params: {
                            encryptedAccessToken: encryptedAccessToken,
                            encryptedSessionToken: encryptedSessionToken
                        }
                    })
                    .then((response) => {
                        console.log("response.data");
                        console.log(response.data);
                        //store.dispatch('updateJobs', response.data)
                        resolve(response.data)

                    },
                        (error) => {
                            console.log(error);
                            reject({ validToken: false });
                        });
            });
        },
        getSpecificJob: function (id) {
            var encryptedAccessToken = $cookies.get("encrypted_access_token");
            var encryptedSessionToken = ($cookies.get("encrypted_session_token") != null) ? $cookies.get("encrypted_session_token") : '';
            return new Promise((resolve, reject) => {
                if (encryptedAccessToken == null) {
                    reject({ validToken: false });
                }
                //Vue.axios.get("http://localhost:3000/at/jobs/" + id,
                Vue.axios.get("https://us-central1-higher.cloudfunctions.net/service/at/jobs/" + id,
                    {
                        //Vue.axios.get("http://localhost:3000/ag/jobs", {
                        params: {
                            encryptedAccessToken: encryptedAccessToken,
                            encryptedSessionToken: encryptedSessionToken
                        }
                    })
                    .then((response) => {
                        console.log("response.data");
                        console.log(response.data);
                        //store.dispatch('updateJobs', response.data)
                        resolve(response.data)

                    },
                        (error) => {
                            console.log(error);
                            reject({ validToken: false });
                        });
            });
        },
        getApplications: function () {
            var encryptedAccessToken = $cookies.get("encrypted_access_token");
            var encryptedSessionToken = ($cookies.get("encrypted_session_token") != null) ? $cookies.get("encrypted_session_token") : '';
            return new Promise((resolve, reject) => {
                if (encryptedAccessToken == null) {
                    reject({ validToken: false });
                }
                //Vue.axios.get("http://localhost:3000/at/applications",
                Vue.axios.get("https://us-central1-higher.cloudfunctions.net/service/at/applications",
                    {
                        //Vue.axios.get("http://localhost:3000/ag/jobs", {
                        params: {
                            encryptedAccessToken: encryptedAccessToken,
                            encryptedSessionToken: encryptedSessionToken
                        }
                    })
                    .then((response) => {
                        console.log(response.data);
                        store.dispatch('updateApplications', response.data)

                    },
                        (error) => {
                            console.log(error);
                            reject({ validToken: false });
                        });
            });
        },
        getProfileQuestions: function () {
            var encryptedAccessToken = $cookies.get("encrypted_access_token");
            var encryptedSessionToken = ($cookies.get("encrypted_session_token") != null) ? $cookies.get("encrypted_session_token") : '';
            return new Promise((resolve, reject) => {
                if (encryptedAccessToken == null) {
                    reject({ validToken: false });
                }
                //Vue.axios.get("http://localhost:3000/at/questions",
                Vue.axios.get("https://us-central1-higher.cloudfunctions.net/service/at/questions",
                    {
                        //Vue.axios.get("http://localhost:3000/ag/jobs", {
                        params: {
                            encryptedAccessToken: encryptedAccessToken,
                            encryptedSessionToken: encryptedSessionToken
                        }
                    })
                    .then((response) => {
                        //console.log(response.data);
                        store.dispatch('updateUserInfo', response.data);
                    },
                        (error) => {
                            console.log(error);
                            reject({ validToken: false });
                        });
            });
        },
        getUserInfo: function () {
            //console.log("getting access_token_cookie")
            //console.log($cookies.get("encrypted_access_token"))
            var encryptedAccessToken = $cookies.get("encrypted_access_token");
            var encryptedSessionToken = ($cookies.get("encrypted_session_token") != null) ? $cookies.get("encrypted_session_token") : '';
            return new Promise((resolve, reject) => {
                if (encryptedAccessToken == null) {
                    reject({ validToken: false });
                }
                //Vue.axios.post("http://localhost:3000/auth/getUserInfo",
                Vue.axios.post("https://us-central1-higher.cloudfunctions.net/service/auth/getUserInfo",
                    {
                        encryptedAccessToken: encryptedAccessToken,
                        encryptedSessionToken: encryptedSessionToken
                    })
                    .then((response) => {
                        //console.log("What is the token?");
                        //console.log(response.data.validToken);
                        if (response.data.validToken == true) {
                            //console.log("Token resolved?");

                            resolve(response.data)
                        }
                        else {
                            reject({ validToken: false });
                        }

                    },
                        (error) => {
                            console.log(error);
                            reject({ validToken: false });
                        });
            });
        },
    }
}

//export function getInfo() {alert();}