<template>
  <v-card>
    <v-toolbar :flat="flat" :color="color" :dark="!flat">
      <v-toolbar-title>{{title}}</v-toolbar-title>

      <v-spacer></v-spacer>
      <!-- 
              <v-btn icon>
                <v-icon>mdi-magnify</v-icon>
              </v-btn>

              <v-btn icon>
                <v-icon>mdi-tune</v-icon>
      </v-btn>-->
    </v-toolbar>
    <v-list two-line>
      <v-list-item-group>
        <template v-for="(item, index) in items">
          <v-list-item :key="item[keyName]" @click="listItemClick(item)">
            <v-list-item-avatar tile>
              <v-img max-width="40px" contain :src="item[itemAvatar]"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-text="item[itemTitle]"></v-list-item-title>
              <v-list-item-subtitle
                class="text--primary"
                v-text="item[itemSubtitle]"
              ></v-list-item-subtitle>
              <v-list-item-subtitle v-text="item[itemSubtitle2]"></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-list-item-action-text v-text="item[itemActionText]"></v-list-item-action-text>
              <v-avatar :color="item[itemActionAvatarColor]" size="24"></v-avatar>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  props: {
    items: Array,
    title: String,
    keyName: String,
    itemTitle: String,
    itemAvatar: String,
    itemSubtitle: String,
    itemSubtitle2: String,
    itemActionText: String,
    itemActionAvatarColor: String,
    type: {
        type: String,
        default: 'normal'
    }
  },
  methods: {
      listItemClick: function(item) {
        this.$emit("listItemClick", item);
      }
  },
  computed: {
      flat: function() {
          if (this.type == 'normal') {
              return true
          }
          return false
      },
      color: function() {
          if (this.type == 'normal') {
              return null
          }
          return 'success'
      }
  }
};
</script>