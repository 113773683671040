    <template>
  <v-alert :value="true" color="error" icon="new_releases">
    {{errorMessage}}
    <v-btn @click="$router.go()">Ladda om sidan</v-btn>
  </v-alert>
</template>
<script>
export default {
  props: {
    errorMessage: {
      type: String,
      default: "Något gick fel, försök gärna igen"
    }
  }
};
</script>