const status = (state, status) => {
    state.status = status;
};

const jobs = (state, jobs) => {
    state.jobs = jobs;
};

export default {
    status,
    jobs
}