module.exports = {
    all_questions: null,
    profile: null,
    questions: null,
    profile_questions_status: 'initialise',
    signup_questions: null,
    signup_questions_status: 'initialise',
    account_info: null,
    account_info_status: 'initialise',
    tests: null,
    tests_status: 'initialise',
    applications: null,
    active_applications: null,
    other_applications: null,
    applications_status: 'initialise',
    newUser: false,
    isLoggedIn: null,
    encryptedAccessToken: '',
    encryptedSessionToken: '',
    all_jobs_status: 'initialise',
    all_jobs: null,
    jobs_status: 'initialise',
    jobs: null,
    status: 'pending',
    userInfo: null,
    jobb:
        [
            {
                "id": 1,
                "title": "Snickare",
                "ag_user_id": 1,
                "job_categories_id": 1,
                "salary_lower": 34000,
                "salaray_upper": 40000,
                "application_period_start": "2019-04-23",
                "application_period_end": "2019-09-21",
                "department": "Byggindustri",
                "cities": "Uppsala",
                "description": "Karin",
                "link_id": "6cfb738b-d72f-11e9-af60-42010aa6004d",
                "applicants": [
                    { id: 1, title: "Fancy Frog", match: "91", status: "Avbruten", date: "2020-01-08" },
                    { id: 2, title: "Cool Koala", match: "88", status: "Kallad", date: "2020-01-16" },
                    { id: 3, title: "Hot Horse", match: "74", status: "Kalla på intervju", date: "" },
                    { id: 4, title: "Zippy Zebra", match: "63", status: "Kalla på intervju", date: "" },

                ]
            },
            {
                "id": 2,
                "title": "Designer",
                "ag_user_id": 1,
                "job_categories_id": 2,
                "salary_lower": 24000,
                "salaray_upper": 26000,
                "application_period_start": "2019-05-23",
                "application_period_end": "2019-10-21",
                "department": "Grafik & Design",
                "cities": "Stockholm",
                "description": "Stefan",
                "link_id": "ea04e9ae-d72f-11e9-af60-42010aa6004d",
                "applicants": [
                    { id: 1, title: "Fancy Frog", match: "91", status: "Avbruten", date: "2020-01-08" },
                    { id: 2, title: "Cool Koala", match: "88", status: "Kallad", date: "2020-01-16" },
                    { id: 3, title: "Hot Horse", match: "85", status: "Kalla på intervju", date: "" },
                    { id: 4, title: "Zippy Zebra", match: "84", status: "Kalla på intervju", date: "" },
                    { id: 5, title: "Tantrum T-Rex", match: "35", status: "Kalla på intervju", date: "" },

                ]
            }
        ],

}