<template>
  <div class="mx-auto page-normal">
    <PageHeader :title="page.title"></PageHeader>
    <PageActions :alertBar="alertBar" :breadcrumbs="[]">
      <template v-slot:breadcrumbs>
        <v-breadcrumbs
          v-if="qs != null && qs.length > 0"
          class="pa-0 mb-2"
          :items="qs"
        >
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item
              divider="-"
              @click="goTo(item.tab)"
              class="clickable"
            >
              <v-icon v-if="!item.questionsMissingAnswers" small color="success"
                >check_circle</v-icon
              >
              <v-icon v-else small color="error">cancel</v-icon>
              <span
                :class="{ 'text-decoration-underline': item.tab == type_text }"
                >{{ $t("tabs." + item.tab) }}</span
              >
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
        <v-divider class="mt-0 pt-0"></v-divider>
      </template>
      <ContinueBar
        @continue="save"
        :visible="showSave"
        :message="page.continueMessage"
        :btnMessage="page.continueBtnMessage"
      ></ContinueBar>
    </PageActions>
    <PageContent :loading="loading" :loadingMessage="page.loadingMessage">
      <div>
        <v-switch
          v-if="questionsMissingAnswers"
          v-model="showOnlyMissing"
          :label="`Visa enbart frågor som saknar svar`"
        ></v-switch>
      </div>
      <div>
        <!-- <v-tabs
          v-model="type"
          background-color="primary"
          show-arrows
          dark
          :centered="true"
          :icons-and-text="false"
        >
        </v-tabs>-->
        <!-- <v-tab class="text-none" v-for="(tab,i) in qs" :key="i">{{$t('tabs.'+tab.tab)}}</v-tab> -->
        <v-tabs-items
          v-if="qs != null && qs.length > 0"
          class="transparent-tabs"
          touchless
          v-model="type"
        >
          <v-tab-item v-for="(tab, i) in qs" :key="tab.tab">
            <v-row v-if="showOnlyMissing && !tab.questionsMissingAnswers">
              <v-col>
                <p>
                  <v-icon x-large color="success">check_circle</v-icon> Du har
                  svarar på alla frågor på denna sida.
                </p>
              </v-col>
            </v-row>
            <div class="questions">
              <v-row>
                <QBase
                  v-for="question in tab.questions"
                  v-bind:key="question.id"
                  v-bind:showOnlyMissing="showOnlyMissing"
                  v-bind="question"
                >
                  <template v-slot:question>
                    <component
                      v-on:changeAnswer="answersChanged"
                      v-bind="question"
                      v-bind:is="question.kind"
                    ></component>
                  </template>
                </QBase>
              </v-row>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
      <!--   <transition name="slow-fade-y-slide">
        <v-btn v-if="showSave" fab color="primary" bottom right class="mb-12" fixed @click="save">
          <v-icon>save</v-icon>
        </v-btn>
      </transition>-->
      <!-- <PageTitle v-bind:title="pageTitle" /> -->
    </PageContent>
  </div>
</template>
<script>
import PageHeader from "../components/layout/PageHeader";
import PageActions from "../components/layout/PageActions";
import PageContent from "../components/layout/PageContent";

import ContinueBar from "../components/basic/ContinueBar";
import AlertBar from "../components/basic/AlertBar";

import store from "../store/store";
import requests from "../plugins/requests";
import PageTitle from "../components/page/Title";
import QBase from "../components/q/QBase";
import QSingle from "../components/q/QSingle";
import QLicense from "../components/q/QLicense";
import QMulti from "../components/q/QMulti";
import QSlide from "../components/q/QSlide";
import QCities from "../components/q/QCities";
import QTextShort from "../components/q/QTextShort";
import QText from "../components/q/QText";
import QJobs from "../components/q/QJobs";
import Spinner from "../components/misc/Spinner";
import { mapState, mapActions } from "vuex";

import snackbar from "@/mixins/snackbar";
import loader from "@/mixins/loader";
import AlertBarMixin from "@/mixins/alertBar";

import { saveQuestions } from "../requests/global";

export default {
  mixins: [snackbar, loader, AlertBarMixin],
  components: {
    QBase,
    QSingle,
    QMulti,
    QSlide,
    QCities,
    QTextShort,
    QText,
    QJobs,
    QLicense,
    Spinner,
    PageTitle,
    PageHeader,
    PageContent,
    PageActions,
    ContinueBar,
    AlertBar,
  },
  data() {
    return {
      page: {
        key: "jobQuestions",
        title: "Profil",
        loading: "loadingJobs",
        loadingMessage: "loading",
        continueMessage: "Vill du spara dina ändringar?",
        continueBtnMessage: "Spara",
        missingAnswersMessage: "Svara på alla frågor för att gå vidare",
        missingAnswersBtnMessage: "Visa",
      },
      showOnlyMissing: false,
      loading: true,
      type: 0,
      qs: null,
      showSave: false,
      pageTitle: "Profil",
      prevIcon: true,
      nextIcon: true,
      tabs: 3,
      fab: false,
    };
  },
  props: {
    startTab: {
      default: "profile",
      type: String,
    },
  },
  computed: {
    ...mapState("questions", ["status", "questionsMissingAnswers"]),
    type_text: function () {
      return this.qs[this.type].tab;
    },
  },
  beforeMount: function () {
    this.handleLoad(this.status);
  },
  watch: {
    status(newValue, oldValue) {
      this.handleLoad(newValue);
    },
  },
  methods: {
    goTo: function (tab) {
      for (var i = 0; i < this.qs.length; i++) {
        if (this.qs[i].tab == tab) {
          this.type = i;
        }
      }
    },
    handleLoad(value) {
      if (value == "success") {
        this.qs = store.getters["questions/questions"];
        this.goTo(this.startTab);
        this.loading = false;
      } else if (value == "initialize") {
        this.loading = true;
        store.dispatch("questions/get");
      }
    },
    answersChanged(data) {
      console.log("answers changed");
      console.log("valid answer");
      console.log(data);
      this.showSave = true;
      var q = this.qs;
      for (var k = 0; k < q.length; k++) {
        for (var i = 0; i < q[k].questions.length; i++) {
          if (q[k].questions[i].id == data.id) {
            q[k].questions[i].answer =
              data.answer != null && data.answer != undefined
                ? data.answer
                : null;
            q[k].questions[i].answer_text = data.answer_text
              ? data.answer_text
              : null;
            q[k].questions[i].validAnswer = data.validAnswer;
          }
          //console.log(q[k].questions[i]);
        }
      }
    },
    save() {
      this.loading = true;
      store
        .dispatch("questions/save", this.qs)
        .then(async (response) => {
          this.showSave = false;
          this.loading = false;
          this.handleLoad("initialize");
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    async logout() {
      await store.dispatch("user/logout");
      //location.reload();
      this.$router.push({ name: "LoginPage" });
    },
  },
};
</script>