<template>
  <transition name="fade">
    <div
      @click="detectClick($event)"
      :id="tab + id"
      v-if="visible"
      class="clear"
      v-bind:class="{ 'dim': dimmed==true}"
    >
      <ActionDialog
        v-model="dialog"
        title="Påbörja tester"
        v-bind:accept="'Gå vidare'"
        v-bind:cancel="'Avbryt'"
      >
        <p>Tänk på att du inte kan göra om detta test. Du får heller inte ta hjälp av någon annan.
          Det är viktigt att göra testerna i lugn och ro. 
          Ditt konto är redan skapat och dina svar är sparade.
          Har du inte tid att göra testerna nu kan du istället gå till din profil.
        </p>
        <v-checkbox v-model="agreeTerms" color="primary" label="Jag godkänner vilkoren"></v-checkbox>
        <template v-slot:actions>
          <v-row justify="space-around" class="mx-0">
            <v-btn @click="$router.push({ path: '/' })" class="dialog-action-btn text-none" rounded text >Till min profil</v-btn>
            <v-btn
              @click="$router.push({ path: '/t' })" 
              :disabled="!agreeTerms"
              class="dialog-action-btn text-none"
              rounded
              color="primary"
            >Gå vidare med tester</v-btn>
          </v-row>
        </template>
      </ActionDialog>

      <chatbox :questionText="questionText" :tab="tab" />
      <div class="text-right">
        <div>
          <br />
          <v-chip v-if="!confirmedOnce" @click="$router.push({ path: '/t' })"  color="primary">{{OKText}}</v-chip>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import chatbox from "./misc/chatbox";
import ActionDialog from "../../actions/ActionDialog";

export default {
  components: {
    chatbox,
    ActionDialog
  },
  data() {
    return {
      confirmedOnce: false,
      overlay: false,
      absolute: true,
      showPopup: false,
      agreeTerms: false,
      dialog: false,
      OKText: this.atbotOKText || "OK"
    };
  },
  props: {
    id: Number,
    tab: String,
    title: String,
    questionText: String,
    dimmed: Boolean,
    visible: Boolean,
    atbotOKText: {
      type: String,
      default: "Starta test!"
    }
  },
  watch: {},
  methods: {
    showAlternatives() {
      this.typingDone = true;
    },
    detectClick(event) {
      this.$emit("clicked", { event: event, id: this.id, tab: this.tab });
    },
    confirmQuestion() {
      console.log(this.showPopup);
      this.dialog = true;
      //this.confirmedOnce = true;
      this.$emit("changeAnswer", {
        event: event,
        id: this.id,
        tab: this.tab,
        validAnswer: true
      });
    }
  }
};
</script>
<style>
/* The Modal (background) */
.modal {
  position: fixed; /* Stay in place */
  z-index: 100; /* Sit on top */
  padding-top: 250px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 500px;
}

.modal .v-chip {
  text-align: center;
  display: block;
  width: 100%;
  max-width: 300px;
  margin: auto;
}
/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
</style>