<template>
  <div class="signup-page">
    <SignupNav
      :display="step"
      :value="active"
      :progress="progress"
      v-on:update="updateActive"
    />
    <v-content>
      <div class="signup-page-container">
        <Spinner v-if="loading" v-bind:message="'startingBriteBot'" />
        <div v-if="errorMessage != null">
          <h3 >{{ errorMessage }}</h3>
          <v-btn rounded color="primary" class="text-none" href="https://brite.jobs/om-oss#kontakta-oss">Support</v-btn>
        </div>
        <!-- <QCities v-bind="dummyCitiesQ" />  -->
        <!-- <QSlide v-bind="dummySlideQ" /> -->
        <!--  <QTest v-bind="qtest" /> -->
        <!-- <QEmail v-bind="dummyEmailQ" /> -->
        <v-tabs-items touchless v-model="active">
          <v-tab-item
            v-for="(questionGroup, index) in allQuestions"
            :key="questionGroup[0].tab"
            :value="questionGroup[0].tab"
          >
            <v-card flat>
              <v-card-text>
                <div v-for="question in questionGroup" v-bind:key="question.id">
                  <component
                    v-if="question.visible"
                    :ref="question.ref"
                    class="message"
                    v-on:clicked="switchFocus"
                    v-on:changeAnswer="answersChanged"
                    v-on:applyForJob="applyForJob"
                    v-bind="question"
                    v-bind:is="question.kind"
                  ></component>
                </div>
                <transition name="fade">
                  <div
                    class="d-flex justify-center"
                    v-if="
                      questionGroup[questionGroup.length - 1].validAnswer ==
                        true && index < questionGroup.length - 1
                    "
                  >
                    <v-btn
                      class="text-none"
                      rounded
                      color="primary"
                      @click="next(index + 2)"
                      >Gå vidare</v-btn
                    >
                  </div>
                </transition>
                <div id="fix-scroll-height"></div>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
        <div class="fix-scroll-height"></div>
      </div>
    </v-content>
  </div>
</template>

<script>
import QSingle from "../components/q/signup/QSingle";
import QLicense from "../components/q/signup/QLicense";
import QMulti from "../components/q/signup/QMulti.vue";
import QNone from "../components/q/signup/QNone.vue";
import QEmail from "../components/q/signup/QEmail.vue";
import QTest from "../components/q/signup/QTest.vue";
import QSlide from "../components/q/signup/QSlide.vue";
import QTextShort from "../components/q/signup/QTextShort.vue";
import QText from "../components/q/signup/QText.vue";
import QCities from "../components/q/signup/QCities.vue";
import QJobs from "../components/q/signup/QJobs.vue";
import QJobInfo from "../components/q/signup/QJobInfo.vue";
import QJobApply from "../components/q/signup/QJobApply.vue";
import Spinner from "../components/misc/Spinner.vue";
import SignupNav from "../components/nav/SignupNav.vue";

import { setTimeout } from "timers";
import requests from "../requests/signup/signup.js";
import routes from "../routes";
import store from "../store/store";
import { mapActions, mapState } from "vuex";
import snackbar from "@/mixins/snackbar";

var customMetaInfo = "Hitta rätt jobb eller talang diskrimineringfritt";

export default {
   metaInfo: {
    // Children can override the title.
    title: "Brite - Hitta rätt jobb eller talang diskrimineringfritt",
    // Result: My Page Title ← My Site
    // If a child changes the title to "My Other Page Title",
    // it will become: My Other Page Title ← My Site
    titleTemplate: "%s | Brite",
    // Define meta tags here.
    meta: [
      { "http-equiv": "Content-Type", content: "text/html; charset=utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      {
        name: "description",
        content:
          "Brite möjliggör snabb, träffsäker och diskrimingeringsfri rekrytering. Hitta rätt jobb eller talang baserat på kompetens."
      },
      // OpenGraph data (Most widely used)
      {
        property: "og:title",
        content: "Brite - " + customMetaInfo,
      },
      { property: "og:site_name", content: "Brite" },
      // The list of types is available here: http://ogp.me/#types
      { property: "og:type", content: "website" },
      // Should the the same as your canonical link, see below.
      { property: "og:url", content: "https://brite.jobs/" },
      {
        property: "og:image",
        content:
          "../assets/img/metadescriptions/brite-framtidens-satt-att-soka-jobb-smart-rekrytering.jpg"
      },
      // Often the same as your meta description, but not always.
      {
        property: "og:description",
        content:
          "Brite möjliggör snabb, träffsäker och diskrimingeringsfri rekrytering. Hitta rätt jobb eller talang baserat på kompetens."
      },

      // Twitter card
      { name: "twitter:card", content: "summary" },
      { name: "twitter:site", content: "https://brite.jobs/" },
      {
        name: "twitter:title",
        content: "Brite - Hitta rätt jobb och talanger diskrimineringfritt"
      },
      {
        name: "twitter:description",
        content:
          "Brite möjliggör snabb, träffsäker och diskrimingeringsfri rekrytering. Hitta rätt jobb eller talang baserat på kompetens."
      },
      // Your twitter handle, if you have one.
      { name: "twitter:creator", content: "@brite_jobs" },
      {
        name: "twitter:image:src",
        content:
          "../assets/img/metadescriptions/brite-framtidens-satt-att-soka-jobb-smart-rekrytering.jpg"
      },

      // Google / Schema.org markup:
      {
        itemprop: "name",
        content: "Brite - Hitta rätt jobb och talanger diskrimineringfritt"
      },
      {
        itemprop: "description",
        content:
          "Brite möjliggör snabb, träffsäker och diskrimingeringsfri rekrytering. Hitta rätt jobb eller talang baserat på kompetens."
      },
      {
        itemprop: "image",
        content:
          "../assets/img/metadescriptions/brite-framtidens-satt-att-soka-jobb-smart-rekrytering.jpg"
      }
    ],
    link: [{ rel: "canonical", href: "https://brite.jobs/" }]
  },
  mixins: [snackbar],
  components: {
    QSingle,
    QMulti,
    QNone,
    QEmail,
    QTest,
    QSlide,
    QTextShort,
    QText,
    QCities,
    QJobInfo,
    QJobApply,
    QLicense,
    Spinner,
    QJobs,
    SignupNav,
  },
  data: () => ({
    loading: true,
    errorMessage: null,
    progress: 0,
    active: "profile",
    currentQuestion: 1,
    questions_profile: [{ validAnswer: false }],
    questions_custom: [{ validAnswer: false }],
    questions_test: [{ validAnswer: false }],
    step: 1,
    job_info: {
      job_id: null,
      job_title: "",
      job_description: "",
      id: 0,
      tab: "profile",
      kind: QNone,
      title: "",
      visible: true,
      dimmed: false,
      answer: null,
      validAnswer: true,
      revealed: true,
    },
    applied: false,
  }),
  watch: {
    active: {
      handler: function () {
        this.handleScroll();
      },
    },
    status(newValue, oldValue) {

        this.handleLoad(newValue, this.job_info.job_id);
      
    },
  },
  computed: {
    allQuestions: function () {
      return [
        this.questions_profile,
        this.questions_custom,
        this.questions_test,
      ];
    },
    ...mapState("signup", ["status", "loadingError"]),
  },
  beforeMount() {
    this.handleLoad();
  },
  mounted: function () {
    document.addEventListener("scroll", () => {
      this.handleScroll();
    });
    customMetaInfo = this.$route.query.job;
  },
  methods: {
    ...mapActions("applications", ["updateApplication"]),
    async applyForJob() {
      if (store.state.user.isLoggedIn && this.$route.query.job != null) {
        try {
          await this.updateApplication({
            status: "APPLIED",
            job_guid: this.$route.query.job,
          });
        } catch (e) {
          console.log(e);
          this._snackbarError("errorApply");
        }
      }
    },
    async handleLoad(value, jobGuid) {
      if (this.status == "initialize") {
        store.dispatch("signup/getSignupQuestions", this.$route.query.job);
      } else if (this.status == "success") {
        this.questions_profile = this.filteredList(
          JSON.parse(JSON.stringify(store.state.signup.questions)),
          "tab",
          "profile"
        );
        this.questions_custom = this.filteredList(
          JSON.parse(JSON.stringify(store.state.signup.questions)),
          "tab",
          "questions"
        );
        this.questions_test = this.filteredList(
          JSON.parse(JSON.stringify(store.state.signup.questions)),
          "tab",
          "test"
        );
        this.questions_profile[0].visible = true;
        this.questions_profile[0].revealed = true;
        this.loading = false;
      } else if (this.status == "failed") {
        this.loading = false;

        console.log(this.loadingError);
      
        this.errorMessage = this.$t('messages.'+this.loadingError.code);
      }
    },
    updateActive: function (tab) {
      this.next(tab);
    },
    saveQuestions() {
      if (store.state.user.isLoggedIn) {
      store
        .dispatch(
          "signup/save",
          this.questions_profile.concat(this.questions_custom)
        )
        .then((response) => {
        })
        .catch((e) => {
          console.log(e);
          this._snackbarError("Kunde in spara, försök igen.");
        });
      }
    },
    filteredList: function (list, attr, answer) {
      return list.filter(function (itm) {
        return answer.includes(itm[attr]) == true;
      });
    },
    answersChanged(data) {
      var q = this.questions_profile;
      if (data.tab == "questions") {
        q = this.questions_custom;
      }
      for (var i = 0; i < q.length; i++) {
        if (q[i].id == data.id) {
          q[i].answer = data.answer ? data.answer : null;
          q[i].answer_text = data.answer_text ? data.answer_text : null;
          q[i].validAnswer = data.validAnswer;
        }
      }
      this.saveQuestions();
    },
    questions_focus(list) {
      window.scrollTo(0, 0);
      setTimeout(() => {
        this.$nextTick(() => {
          list[0].revealed = true;
          list[0].visible = true;
          list[0].dimmed = false;
        });
      }, 50);
    },
    findInArray(array, attr, value) {
      for (var i = 0; i < array.length; i += 1) {
        if (array[i][attr] === value) {
          return i;
        }
      }
      return -1;
    },
    setDimmed(list, tab) {
      var elements = this.$refs[tab];
      var allRefElements = this.$refs;
      var allElements = [];
      var visibleElements = [];
      for (var key in allRefElements) {
        allElements.push(allRefElements[key]);
      }

      for (var i = 0; i < allElements.length; i++) {
        try {
          if (allElements[i][0].tab == this.active) {
            visibleElements.push(allElements[i][0]);
          }
        } catch {}
      }

      var elements = visibleElements;
      var visibleEl = [];
      if (elements.length == 1 || elements.length == 0) {
        list[0].dimmed = false;
        return;
      }
      for (var i = 0; i < elements.length; i++) {
        if (elements[i].visible == true) {
          visibleEl[visibleEl.length] = elements[i];
        }
      }
      for (var j = 0; j < list.length; j++) {
        list[j].dimmed = true;
      }
      var oneElementNotDimmed = false;
      for (var i = 0; i < visibleEl.length; i++) {
        if (
          visibleEl[i].$el.getBoundingClientRect().y - 100 > 0 ||
          i > visibleEl.length - 2
        ) {
          if (
            visibleEl[i].$el.getBoundingClientRect().y + 200 >
            (window.innerHeight || document.documentElement.clientHeight)
          ) {
            list[i - 1].dimmed = false;
            this.currentQuestion = i;
            this.progress = (100 * this.currentQuestion) / list.length;
            break;
          }
          list[i].dimmed = false;
          this.currentQuestion = i + 1;
          this.progress = (100 * this.currentQuestion) / list.length;
          break;
        }
      }
    },
    handleScroll() {
      var list = this.questions_profile;
      var tab = "questions_profile";
      if (this.active == "profile") {
        tab = "questions_profile";
        list = this.questions_profile;
      } else if (this.active == "questions") {
        tab = "questions_custom";
        list = this.questions_custom;
      } else if (this.active == "test") {
        tab = "questions_test";
        list = this.questions_test;
      }
      this.setDimmed(list, tab);
    },
    switchFocus: function (data) {
      if (this.questions_profile.length < 1) {
        return;
      }
      var id = data.id;
      var ref = data.ref;
      var index;
      var q = this.questions_profile;
      if (data.tab == "questions") {
        q = this.questions_custom;
      }

      for (var k = 0; k < q.length; k++) {
        if (q[k].id == id) {
          index = k;
        }
      }
      setTimeout(() => {
        if (q[index].validAnswer && q[index + 1].revealed == false) {
          q[index + 1].visible = true;
          q[index + 1].revealed = true;
          this.currentQuestion = q[index + 1].id;
          this.scrollingTo(q[index + 1].ref, q[index + 1].tab);
        } else if (this.currentQuestion != id) {
          this.currentQuestion = id;
          this.scrollingTo(ref, data.tab);
        } else {
        }
      }, 100);
    },
    scrollingTo: function (ref, tab) {
      var vuetify = this.$vuetify;
      setTimeout(() => {
        this.$nextTick(() => {
          vuetify.goTo(this.$refs[ref][0]);
        });
      }, 10);
    },
    next(index) {
      if (index == 1) {
        this.active = "profile";
        this.questions_profile[0].dimmed = false;
        this.handleScroll();
      } else if (index == 2) {
        this.step < 2
          ? (this.step = 2)
          : setTimeout(() => {
              this.active = "questions";
              this.questions_focus(this.questions_custom);
              this.questions_custom[0].dimmed = false;
              this.handleScroll();
            }, 100);
      } else if (index == 3) {
        this.step < 3
          ? (this.step = 3)
          : setTimeout(() => {
              this.active = "test";
              this.questions_focus(this.questions_test);
              this.questions_test[0].dimmed = false;
              this.handleScroll();
            }, 100);
      } else {
        this.active = "questions";
        this.questions_custom[0].dimmed = false;
        this.handleScroll();
      }
      this.saveQuestions();
      this.handleScroll();

    },
  },
};
</script>
<style lang="sass">
@import '@/styles/pages/signup.sass'
</style>