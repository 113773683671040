<template>
  <div
    @click="detectClick($event)"
    :id="tab + id"
    v-if="visible"
    class="clear"
    v-bind:class="{ 'dim': dimmed==true}"
  >
    <chatbox
      :questionText="questionText"
      :introText="introText"
      :instructionText="instructionText"
      :tab="tab"
      :typing="typing"
      v-on:typingDone="showAlternatives"
    />
    <transition name="fade">
      <div v-if="typingDone" class="text-right">
        <div class="d-flex justify-end">
          <v-flex xs10 sm6 class="mt-3">
            <v-textarea v-model="dAnswer" solo></v-textarea>
          </v-flex>
        </div>
        <br />
        <v-chip
          v-if="showOKbutton && !confirmedOnce"
          @click="confirmQuestion"
          color="primary"
        >{{OKText}}</v-chip>
      </div>
    </transition>
  </div>
</template>
<script>
import chatbox from "./misc/chatbox";

export default {
  components: {
    chatbox
  },
  data() {
    return {
      dAnswer: null,
      dValidAnswer: false,
      showOKbutton: this.optional ? true : false,
      confirmedOnce: false,
      typing: false,
      typingDone: false,
      OKText: this.atbotOKText || "OK"
    };
  },
  props: {
    id: Number,
    tab: String,
    title: String,
    questionText: String,
    dimmed: Boolean,
    visible: Boolean,
    introText: String,
    instructionText: String,
    atbotOKText: {
      type: String,
      default: "OK"
    },
    optional: Boolean
  },
  watch: {
    visible: function() {
      if (this.visible) {
        this.typing = true;
      }
    },
    dAnswer: {
      handler: function() {
        console.log(this.dAnswer);
        if (
          this.dAnswer != null &&
          this.dAnswer != false &&
          this.dAnswer.length > 0
        ) {
          this.showOKbutton = true;
          this.$emit("changeAnswer", {
            id: this.id,
            tab: this.tab,
            answer_text: this.dAnswer,
            validAnswer: true
          });
        } else {
          this.showOKbutton = false;
        }
        if (this.optional == true) {
          this.showOKbutton = true;
        }
      },
      deep: true
    }
  },
  methods: {
    showAlternatives() {
      this.typingDone = true;
    },
    detectClick(event) {
      this.$emit("clicked", { event: event, id: this.id, tab: this.tab });
    },
    confirmQuestion() {
      this.confirmedOnce = true;
      this.$emit("changeAnswer", {
        event: event,
        id: this.id,
        tab: this.tab,
        answer_text: this.dAnswer,
        validAnswer: true
      });
    }
  }
};
</script>