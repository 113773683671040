//import { axios } from 'axios';

import Vue from 'vue'
import store from "@/store/store.js";
//import { reject } from 'q';

export default {
    set: function (language) {
        var encryptedAccessToken = $cookies.get("encrypted_access_token");
        var encryptedSessionToken = ($cookies.get("encrypted_session_token") != null) ? $cookies.get("encrypted_session_token") : '';
        if (encryptedAccessToken == null) { return; }
        return new Promise((resolve, reject) => {
            //Vue.axios.post("https://us-central1-higher.cloudfunctions.net/service/signup/questions",
            Vue.axios.post("https://us-central1-higher.cloudfunctions.net/brite-dev/at/settings",
            //Vue.axios.post("http://localhost:3000/at/settings",
                {
                    encryptedAccessToken: encryptedAccessToken,
                    encryptedSessionToken: encryptedSessionToken,
                    settings: {
                        language: language
                    }
                })
                .then((response) => {
                    resolve(response.data)
                },
                    (error) => {
                        reject(error);
                    });
        });
    },
    get: function () {
        var encryptedAccessToken = $cookies.get("encrypted_access_token");
        var encryptedSessionToken = ($cookies.get("encrypted_session_token") != null) ? $cookies.get("encrypted_session_token") : '';
        return new Promise((resolve, reject) => {
            if (encryptedAccessToken == null) {
                reject({ validToken: false });
            }
            //Vue.axios.get("http://localhost:3000/at/settings",
                //Vue.axios.get("https://us-central1-higher.cloudfunctions.net/service/at/questions",
                Vue.axios.get("https://us-central1-higher.cloudfunctions.net/brite-dev/at/settings",
                {
                    //Vue.axios.get("http://localhost:3000/ag/jobs", {
                    params: {
                        encryptedAccessToken: encryptedAccessToken,
                        encryptedSessionToken: encryptedSessionToken
                    }
                })
                .then((response) => {
                    //console.log(response.data);
                    //store.dispatch('updateQuestions', response.data);
                    resolve(response.data)
                },
                    (error) => {
                        if (error.response) {
                            console.log(error.response.data);
                            reject(error.response.data);
                        }
                        else {
                            reject(error);
                        }
                    });
        });
    },
}
//export function getInfo() {alert();}