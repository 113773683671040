import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import VueRouter from 'vue-router'
import VCalendar from 'v-calendar';


import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify,{
  iconfont: 'md'}, VueRouter)

const opts = {
  iconfont: 'md',
  theme: {
    themes: {
      light: {
        primary: '#182540',
        secondary: '#038C8C',
        success: '#038C8C',
        accent: '#F2AF5C',
        error: '#D94032',
        open: '#07CE63',
        closed: '#E64435',
        ongoing: '#F4BB7A',
        finished: '#8B919F'
      }
    }
  }
}

Vue.use(VCalendar, {
  componentPrefix: 'vc', // Now use vc-calendar and vc-date-picker
   });

export default new Vuetify(opts)
