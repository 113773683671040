<template>
  <div
    :id="tab + id"
    v-if="visible"
    class="clear"
    v-bind:class="{ 'dim': dimmed==true}"
  >
    <chatbox
      :questionText="questionText"
      :introText="introText"
      :instructionText="instructionText"
      :tab="tab"
      :typing="typing"
      v-on:typingDone="showAlternatives"
    />
    <transition name="fade">
      <div v-if="typingDone">
        <v-row justify="end">
          <v-col cols="12" sm="10" md="8" v-bind:class="{ 'groupAnswers': groupAnswers}">
            <v-card justify="end">
              <v-sheet class="pa-3 primary">
                <v-text-field
                  v-model="search"
                  @input="searchUpdate($event)"
                  @mouseup="jobFocus"
                  label="Sök jobb"
                  dark
                  flat
                  solo-inverted
                  hide-details
                  clearable
                  clear-icon="close"
                ></v-text-field>
              </v-sheet>
              <v-treeview
                v-model="dAnswer"
                selectable
                selected-color="primary"
                :items="jobsTree"
                :search="search_tree"
                :filter="filter"
                :open.sync="open"
                @update:open="toggleExpand"
                @update:active="toggleExpand"
                return-object
              ></v-treeview>
              <v-subheader
                class="clickable"
                @click="addCustomJobDialog=true"
              >{{$t("questions.cannotFindJob")}}</v-subheader>

              <ActionDialog
                v-model="addCustomJobDialog"
                :title="'Vilken tjänst söker du?'"
                v-on:resolve="sendNewJobSuggestion()"
                v-on:reject="addCustomJobDialog=false"
              >
                <v-text-field v-model="newJobSuggestion" placeholder="Ange tjänst" outlined></v-text-field>
              </ActionDialog>
              <v-dialog
                v-model="dialog"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
              >
                <v-card class="autocomplete popup">
                  <v-flex>
                    <v-sheet class="pa-3 primary">
                      <v-text-field
                        v-model="search"
                        @input="searchUpdate($event)"
                        label="Sök jobb"
                        ref="jobDialogInput"
                        prepend-inner-icon="keyboard_backspace"
                        @click:prepend-inner="dialog = false"
                        dark
                        flat
                        solo-inverted
                        hide-details
                        clearable
                        clear-icon="close"
                      ></v-text-field>
                    </v-sheet>
                    <v-card-text>
                      <v-treeview
                        v-model="dAnswer"
                        selectable
                        selected-color="primary"
                        :items="jobsTree"
                        :search="search_tree"
                        :filter="filter"
                        :open.sync="open"
                        @update:open="toggleExpand"
                        @update:active="toggleExpand"
                        return-object
                      ></v-treeview>
                    </v-card-text>
                  </v-flex>
                </v-card>
              </v-dialog>
            </v-card>
            <div class="text-right mt-6">
              <v-row wrap>
                <v-col
                  v-if="dAnswer.length>0"
                  cols="12"
                  v-bind:class="{ 'groupAnswers': dAnswer.length>0}"
                >
                  <v-subheader>{{$t('questions.youhaveChoosenTheseJobs')}}</v-subheader>
                  <ul class="text-right hack-right-align-direction">
                    <transition-group name="slide-fade">
                      <li class="list-complete-item" v-for="jb in sortedJobs" :key="jb.id">
                        <input
                          v-show="false"
                          v-model="dAnswer"
                          type="checkbox"
                          :value="jb.id"
                          :id="id + jb.id"
                          :name="id"
                          @click="removeJob(jb)"
                        />
                        <label :for="id + jb.id" @click.stop>
                          <v-chip color="secondary">
                            {{jb.name}}
                            <v-icon>close</v-icon>
                          </v-chip>
                        </label>
                      </li>
                    </transition-group>
                  </ul>
                  <ul >
                    <li v-if="dAnswer.length>sortedJobs.length">
                      <v-chip
                        @click="updateSortedJobs(true)"
                        color="primary"
                      >{{$t('questions.showAll')}} ({{dAnswer.length}})</v-chip>
                    </li>
                    <li v-else-if="dAnswer.length>sortedJobsLimit">
                      <v-chip
                        @click="updateSortedJobs(false)"
                        color="primary"
                      >{{$t('questions.showFewer')}}</v-chip>
                    </li>
                  </ul>
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col
                  v-if="jobSuggestions.length>0"
                  cols="12"
                  v-bind:class="{ 'groupAnswers': dAnswer.length>0}"
                >
                  <v-subheader>Egna föreslagna alternativ</v-subheader>
                  <ul class="text-right hack-right-align-direction">
                    <transition-group name="list-complete">
                      <li class="list-complete-item" v-for="jb in jobSuggestions" :key="jb.id">
                        <input
                          v-show="false"
                          v-model="dAnswer"
                          type="checkbox"
                          :value="jb.id"
                          :id="id + jb.id"
                          :name="id"
                          @click="removeJobSuggestion(jb)"
                        />
                        <label :for="id + jb.id" @click.stop>
                          <v-chip color="secondary">
                            {{jb.name}}
                            <v-icon>close</v-icon>
                          </v-chip>
                        </label>
                      </li>
                    </transition-group>
                  </ul>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <div class="flex-end-container">
          <transition name="fade">
            <v-subheader
              v-show="allJobs.length<1 && confirmedOnce == true"
            >Vänligen ange minst ett svar</v-subheader>
          </transition>
          <!--   <transition name="fade">
            <v-subheader v-show="dAnswer.length>0 || confirmedOnce == false">Flera svar möjliga</v-subheader>
          </transition>-->
        </div>
        <transition name="fade">
          <div class="text-right" v-show="(allJobs.length>0) && confirmedOnce == false">
            <br />
            <v-chip @click="confirmQuestion" color="primary">{{OKText}}</v-chip>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>
<script>
import chatbox from "./misc/chatbox";
import ActionDialog from "../../actions/ActionDialog";
import requests from "@/requests/requests";

export default {
  components: {
    chatbox,
    ActionDialog
  },
  data() {
    return {
      sortedJobsLimit: 5,
      sortedJobsShowAll: false,
      newJobSuggestion: null,
      addCustomJobDialog: false,
      dAnswer: this.answer != null ? this.answer : [],
      dValidAnswer: false,
      confirmedOnce: false,
      typing: false,
      typingDone: false,
      OKText: this.atbotOKText || "OK",
      timeoutID: false,
      debounceDelay: 320,
      jobs: [],
      sortedJobs: [],
      search: null,
      search_tree: null,
      caseSensitive: false,
      open: [],
      dialog: false,
      jobSuggestions: []
    };
  },
  props: {
    id: Number,
    tab: String,
    title: String,
    answer: {
      type: Array,
      default: () => {
        [];
      }
    },
    /*      jobsTree: ssyk.ssyk_professions,
      allOpen: ssyk.ssyk_professions_id,
      jobsFlat: ssyk.ssyk_professions_flat, */
    jobsTree: Array,
    jobsFlat: Array,
    answerAlternatives: Array,
    groupAnswers: Boolean,
    questionText: String,
    dimmed: Boolean,
    visible: Boolean,
    showCheckbox: {
      type: Boolean,
      default: false
    },
    introText: String,
    instructionText: String,
    atbotOKText: {
      type: String,
      default: "OK"
    }
  },
  watch: {
    allJobs: function() {
      if (this.allJobs.length > 0) {
        this.$emit("changeAnswer", {
          id: this.id,
          tab: this.tab,
          answer: this.allJobs,
          validAnswer: this.dValidAnswer
        });
      }
    },
    dAnswer: {
      handler: function() {
        console.log("found change" + this.dAnswer.length);
        this.updateSortedJobs();
        if (this.dAnswer.length > 0 && this.confirmedOnce == true) {
          this.dValidAnswer = true;
        } else {
          this.dValidAnswer = false;
          console.log(this.dValidAnswer);
        }
        /*         this.$emit("changeAnswer", {
          id: this.id,
          tab: this.tab,
          answer: this.dAnswer.concat(this.jobSuggestions),
          validAnswer: this.dValidAnswer
        }); */
      },
      deep: true
    },
    search_tree: function() {
      //console.log(JSON.stringify(this.filter()));
      if (this.search_tree.length > 2) {
        this.open = [];
        for (var i = 0; i < this.jobsFlat.length; i++) {
          //console.log(this.jobsFlat[i].brite_title.toLowerCase());
          if (
            this.jobsFlat[i].name
              .toLowerCase()
              .includes(this.search_tree.toLowerCase())
          ) {
            this.open.push({
              id: String(this.jobsFlat[i].id).substring(0, 2)
            });
            this.open.push({
              id: String(this.jobsFlat[i].id).substring(0, 3)
            });
            this.open.push({
              id: String(this.jobsFlat[i].id).substring(0, 4)
            });
            this.open.push({ id: this.jobsFlat[i].id });
          }
        }
        console.log("open is");
        console.log(this.open);
        //this.open = this.allOpen;
        //console.log("asdasd");
      } else {
        this.open = [];
      }
    }
  },
  methods: {
    updateSortedJobs: function(toggle) {
      if(toggle) {this.sortedJobsShowAll = true}
      if(!toggle) {this.sortedJobsShowAll = false}
      var limit = (this.sortedJobsShowAll) ? this.dAnswer.length : this.sortedJobsLimit;
      this.sortedJobs = [];
      this.sortedJobs = this.dAnswer
        .concat()
        .sort((a, b) => (a.name > b.name ? 1 : -1));
      if (this.sortedJobs.length > limit) {
        this.sortedJobs = this.sortedJobs.slice(0, limit);
      }
    },
    sendNewJobSuggestion: function() {
      requests.misc
        .suggestJob(this.newJobSuggestion)
        .then(result => {
          console.log("Suggestion added");
        })
        .catch(err => {
          console.log("Suggestion could not be added");
        });
      console.log("pushing");
      this.addCustomJobDialog = false;
      this.jobSuggestions.push({
        id: "[SUGGESTION]:" + this.newJobSuggestion,
        name: this.newJobSuggestion,
        suggestion: true
      });
    },
    removeJob: function(job) {
      for (var i = 0; i < this.dAnswer.length; i++) {
        if (this.dAnswer[i].id == job.id) {
          this.dAnswer.splice(i, 1);
        }
      }
    },
    removeJobSuggestion: function(job) {
      for (var i = 0; i < this.jobSuggestions.length; i++) {
        if (this.jobSuggestions[i].id == job.id) {
          this.jobSuggestions.splice(i, 1);
        }
      }
    },
    jobFocus: function() {
      if (this.$vuetify.breakpoint.smAndDown) {
        setTimeout(() => {
          this.dialog = true;
          console.log(this.$refs);
          this.$refs.jobDialogInput.focus();
        }, 1);
      }
    },
    iterObj: function(obj) {
      for (var key in obj) {
        //console.log(key + ": " + obj[key]);
        if (obj[key] !== null && typeof obj[key] === "object") {
          // Recurse into children
          this.iterObj(obj[key]);
        } else if (obj[key] === "id") {
        }
      }
    },
    toggleExpand: function(a) {
      //console.log(a);
    },
    searchUpdate(val) {
      console.log(val);
      var self = this;
      clearTimeout(this.timeoutID);
      this.timeoutID = setTimeout(function() {
        self.search_tree = val;
      }, this.debounceDelay);
    },
    showAlternatives() {
      this.typingDone = true;
    },
    detectClick(event) {
      this.$emit("clicked", { event: event, id: this.id, tab: this.tab });
    },
    confirmQuestion() {
      this.confirmedOnce = true;
      if (this.allJobs.length > 0) {
        this.dValidAnswer = true;
      } else {
        this.dValidAnswer = false;
      }
      this.$emit("changeAnswer", {
        id: this.id,
        tab: this.tab,
        answer: this.allJobs,
        validAnswer: true
      });
      this.detectClick();

    }
  },
  computed: {
    allJobs: function() {
      return this.dAnswer.concat(this.jobSuggestions);
    },
    filter() {
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined;
    },
    selected_jobs() {
      //console.log("looping");
      var temp_jobs = [];
      this.iterObj(this.jobsTree);
      //console.log(testLoop);
      for (var i = 0; i < this.jobs.length; i++) {
        temp_jobs.push(this.jobs[i]);
      }

      return temp_jobs;
    }
  }
};
</script>
<style scoped>
.fade-leave-active {
  transition: opacity 0s;
}
</style>
<style lang="sass">
.hack-right-align-direction
  direction: rtl
  span.v-chip__content
    direction: ltr

  .list-complete-item
    transition: all 1s
    display: inline-block

  .list-complete-enter, .list-complete-leave-to
    opacity: 0
    transform: translateY(30px)

  .list-complete-leave-active
    position: absolute
  .slide-fade-enter-active
    transition: all 0.06s ease-in

  .slide-fade-leave-active
    transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0)

  .slide-fade-enter, .slide-fade-leave-to
    transform: translateY(30px)
    opacity: 0
</style>