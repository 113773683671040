//import { axios } from 'axios';

import Vue from 'vue'
import store from "../../store/store.js";
//import { reject } from 'q';

export default {
    validateCookie: function (sessionCookie, accessCookie) {
        return new Promise((resolve, reject) => {
            //Vue.axios.post("http://localhost:3000/auth/getUserInfo",

            Vue.axios.post("https://us-central1-higher.cloudfunctions.net/service/auth/getUserInfo",
                {
                    encryptedSessionToken: sessionCookie,
                    encryptedAccessToken: accessCookie
                })
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    nativeLogin: function (email, password) {
        return new Promise((resolve, reject) => {
            Vue.axios.post("https://us-central1-higher.cloudfunctions.net/service/at/user/login",
                //Vue.axios.post("http://localhost:3000/at/user/login",
                {
                    login_provider_id: 1,
                    email: email,
                    password: password
                })
                .then((response) => {

                    resolve(response.data)

                },
                    (error) => {
                        console.log(error.response.data);
                        reject(error.response.data);
                    });
        });
    },
    createNativeUser: function (email, password) {
        return new Promise((resolve, reject) => {
            Vue.axios.post("https://us-central1-higher.cloudfunctions.net/service/at/user/create",
                //Vue.axios.post("http://localhost:3000/at/user/create",
                {
                    login_provider_id: 1,
                    email: email,
                    password: password
                })
                .then((response) => {

                    resolve(response.data)

                },
                    (error) => {
                        console.log(error);
                        reject({ validToken: false });
                    });
        });
    },
    methods: {
        checkIfLoggedIn: function () {
            return new Promise((resolve, reject) => {
                this.getUserInfo()
                    .then(result => {
                        //console.log(result);
                        //console.log(result.validToken);
                        if (result.validToken == true) {
                            //console.log("Valid login token");
                            store.commit('isLoggedIn', true);
                            //console.log(store.state.isLoggedIn);
                            resolve(true);

                        }
                        else {
                            store.commit('isLoggedIn', false);
                            resolve(false);
                        }

                    })
                    .catch(err => {
                        console.log("Could not get user data" + err);
                        store.commit('isLoggedIn', false);
                        reject(err);
                    });
            });
        },
        validate: function () {
            //console.log("getting access_token_cookie")
            //console.log($cookies.get("encrypted_access_token"))
            var encryptedAccessToken = $cookies.get("encrypted_access_token");
            var encryptedSessionToken = ($cookies.get("encrypted_session_token") != null) ? $cookies.get("encrypted_session_token") : '';
            return new Promise((resolve, reject) => {
                if (encryptedAccessToken == null) {
                    reject({ validToken: false });
                }
                //Vue.axios.post("http://localhost:3000/auth/getUserInfo",
                Vue.axios.post("https://us-central1-higher.cloudfunctions.net/service/auth/getUserInfo",
                    {
                        encryptedAccessToken: encryptedAccessToken,
                        encryptedSessionToken: encryptedSessionToken
                    })
                    .then((response) => {
                        //console.log("What is the token?");
                        //console.log(response.data.validToken);
                        if (response.data.validToken == true) {
                            //console.log("Token resolved?");

                            resolve(response.data)
                        }
                        else {
                            reject({ validToken: false });
                        }

                    },
                        (error) => {
                            console.log(error);
                            reject({ validToken: false });
                        });
            });
        },
    }
}
