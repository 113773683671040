const status = (state, status) => {
    state.status = status;
};

const userTestStatus = (state, userTestStatus) => {
    state.userTestStatus = userTestStatus;
};
const linkDefaultTest = (state, linkDefaultTest) => {
    state.linkDefaultTest = linkDefaultTest;
};

const testReportUrl = (state, testReportUrl) => {
    state.testReportUrl = testReportUrl;
};




export default {
    status,
    userTestStatus,
    linkDefaultTest,
    testReportUrl
    
}