const status = (state, status) => {
    state.status = status;
};

const notifications = (state, notifications) => {
    state.notifications = notifications;
};
const userQuestionStatus = (state, userQuestionStatus) => {
    state.userQuestionStatus = userQuestionStatus;
};

export default {
    status,
    notifications,
    userQuestionStatus,
}