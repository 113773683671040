<template>
  <div
    @click="detectClick($event)"
    :id="tab + id"
    v-if="visible"
    class="clear"
    v-bind:class="{ 'dim': dimmed==true}"
  >
    <chatbox
      :questionText="questionText"
      :introText="introText"
      :instructionText="instructionText"
      :tab="tab"
      :typing="typing"
      v-on:typingDone="showAlternatives"
    />
    <transition name="fade">
      <div v-if="typingDone && jobInfo != null">
        <span class="mess text-left" :class="tab">
          <span v-html="jobInfoHTML"></span>
        </span>
        <div class="text-right">
          <br />
          <v-chip
            v-if="!confirmedOnce && !autoContinue"
            @click="confirmQuestion"
            color="secondary"
          >{{OKText}}</v-chip>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import chatbox from "./misc/chatbox";
import store from "../../../store/store";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    chatbox,
  },
  data() {
    return {
      confirmedOnce: false,
      typing: false,
      typingDone: false,
      OKText: this.atbotOKText || "OK",
    };
  },
  props: {
    id: Number,
    tab: String,
    title: String,
    questionText: String,
    dimmed: Boolean,
    visible: Boolean,
    introText: String,
    instructionText: String,
    autoContinue: Boolean,
    atbotOKText: {
      type: String,
      default: "OK",
    },
  },
  beforeMount: function () {
    this.handleLoad();
  },
  computed: {
    ...mapState("signup", ["jobinfo_status", "jobInfo"]),
    jobInfoHTML: function () {
      return `  Titel: <b>${this.jobInfo.title}</b> <br/>
                Företag: <b>${this.jobInfo.company}</b>  <br/>
                Avdelning: <b>${this.jobInfo.department}</b>  <br/>
                Stad: <b>${this.jobInfo.cityText} </b> <br/>
                <br/>
                Beskrivning: <br/>${this.jobInfo.description} <br/>`;
    },
  },
  watch: {
    visible: function () {
      if (this.visible) {
        this.typing = true;
      }
    },
    jobinfo_status: function () {
      this.handleLoad();
    },
  },
  methods: {
    handleLoad: async function () {
      if (this.jobinfo_status == "success") {
        console.log("this.jobInfo");
        console.log(this.jobInfo);
      } else if (this.jobinfo_status == "initialize") {
        this.basicJobInfo(this.$route.query.job);
      }
    },
    ...mapActions("signup", ["basicJobInfo"]),
    showAlternatives() {
      this.typingDone = true;
      console.log("auto is : " + this.autoContinue);
      if (this.autoContinue == true) {
        this.confirmQuestion();
        this.detectClick();
      }
    },
    detectClick(event) {
      this.$emit("clicked", { event: event, id: this.id, tab: this.tab });
    },
    confirmQuestion() {
      this.confirmedOnce = true;
      this.$emit("changeAnswer", {
        event: event,
        id: this.id,
        tab: this.tab,
        validAnswer: true,
      });
    },
  },
};
</script>