<template>
  <v-app>
    <Snackbar />
    <router-view />
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import Snackbar from "../components/misc/Snackbar";

export default {
  components: {
    Snackbar,
  },
  computed: {
    ...mapState("loader", ["showLoader", "loaderMessage", "dark"]),
  },
  /*   beforeMount: function() {
    this.handleLoad(this.isLoggedIn);
  },
  watch: {
    isLoggedIn(newValue, oldValue) {
      this.handleLoad(newValue);
    }
  },
  methods: {
    handleLoad: function(value) {

    }
  } */
};
</script>
