
import { getSettings, saveSettings } from '../requests/global'
import requests from '../requests/requests'
import ds from '../dataservice'

import i18n from '../i18n'

export default {
    namespaced: true,
    state: () => ({
        status: 'initialize',
        language: 'sv',
        languages: [{ text: "English", code: "en" }, { text: "Svenska", code: "sv" }]
    }),
    mutations: {
        language(state, status) { state.language = status },
        status(state, status) { state.status = status },
    },
    getters: {
        questions: state => JSON.parse(JSON.stringify(state.questions)),
    },
    actions: {
        async get(context, data) {
            try {
                //context.dispatch("loader/show", "getUserSettings", { root: true })
                context.commit("status", "pending")
                var language = $cookies.get("language");
                var settings = {};
                if (language == null) {
                    settings = await getSettings();
                }
                else {
                    settings.language = language;
                }
                context.dispatch("language", { language: settings.language, manual: false });
                context.commit("status", "success")
                //context.dispatch("loader/hide", null, { root: true })
                return;
            }
            catch (e) {
                var errorCode = await context.dispatch("error/handle", e, { root: true })
                console.log("settings error");
                console.log(errorCode);
                //context.dispatch("loader/hide", null, { root: true })
                context.dispatch("snackbar/error", errorCode, { root: true })
            }
        },
        languageCookie(context, data) {
            if (context.state.language == null && $cookies.get("language") != null) {
                context.dispatch("setLanguage", $cookies.get("language"))
                console.log("Language from cookie is: " + context.state.language)
            }
        },
        setLanguage(context, language) {
            i18n.locale = language;
            $cookies.set("language", language, 365 * 24 * 60 * 60, null, null, false)
            context.commit('language', language);
        },
        async language(context, data) {
            try {
                var language = data.language;
                var notify = data.notify;
                if (language == context.state.language) {
                    return;
                }
                else {
                    context.dispatch("setLanguage", language)
                    if (data.manual) {
                        console.log("manual change")
                        console.log(language)
                        var promises = []
                        promises.push(saveSettings({ body: { settings: { language: language } } }));
                        promises.push(context.dispatch("dataservice/getDataAfterLanguageChange", null, { root: true }));
                        await Promise.all(promises)
                        context.dispatch("snackbar/success", "changedLanguage", { root: true })
                    }
                }
            }
            catch (e) {
                console.log(e)
                context.dispatch("snackbar/error", "errorSwitchingLanguage", { root: true })
            }

        }
    }
}