<template>
  <div
    @click="detectClick($event)"
    :id="tab + id"
    v-if="visible"
    class="clear"
    v-bind:class="{ 'dim': dimmed==true}"
  >
    <chatbox
      :questionText="questionText"
      :introText="introText"
      :instructionText="instructionText"
      :tab="tab"
      :typing="typing"
      v-on:typingDone="showAlternatives"
    />
    <transition name="fade">
      <div v-if="typingDone" class="text-right">
        <div class="text-right">
          <transition name="fade" mode="out-in">
            <v-subheader :key="1"
              v-if="dAnswer.length<1 && confirmedOnce == true"
            >Vänligen ange minst ett svar</v-subheader>
            <v-subheader :key="2" v-else>Flera svar möjliga</v-subheader>
          </transition>
        </div>
        <v-layout wrap>
          <v-flex xs12 v-bind:class="{ 'groupAnswers': groupAnswers}">
            <ul>
              <li v-for="alt in answerAlternatives" :key="alt.val">
                <input
                  v-show="showCheckbox"
                  v-model="dAnswer"
                  type="checkbox"
                  :value="alt.val"
                  :id="id + alt.text"
                  :name="id"
                />
                <label :for="id + alt.text" @click.stop>
                  <v-chip :outlined="!dAnswer.includes(alt.val)" color="secondary">{{alt.text}}</v-chip>
                </label>
              </li>
            </ul>
          </v-flex>
        </v-layout>
        <transition name="fade">
          <div v-show="dAnswer.length>0 && confirmedOnce == false">
            <br />
            <v-chip @click="confirmQuestion" color="primary">{{OKText}}</v-chip>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>
<script>
import chatbox from "./misc/chatbox";

export default {
  components: {
    chatbox
  },
  data() {
    return {
      dAnswer: this.answer != null ? this.answer : [],
      dValidAnswer: false,
      confirmedOnce: false,
      typing: false,
      typingDone: false,
      OKText: this.atbotOKText || "OK"
    };
  },
  props: {
    id: Number,
    tab: String,
    title: String,
    answer: {
      type: Array,
      default: () => {
        [];
      }
    },
    answerAlternatives: Array,
    groupAnswers: Boolean,
    questionText: String,
    dimmed: Boolean,
    visible: Boolean,
    showCheckbox: {
      type: Boolean,
      default: false
    },
    introText: String,
    instructionText: String,
    atbotOKText: {
      type: String,
      default: "OK"
    }
  },
  watch: {
    dAnswer: {
      handler: function() {
        console.log("found change" + this.dAnswer.length);
        if (this.dAnswer.length > 0 && this.confirmedOnce == true) {
          this.dValidAnswer = true;
        } else {
          this.dValidAnswer = false;
          console.log(this.dValidAnswer);
        }
        this.$emit("changeAnswer", {
          id: this.id,
          answer: this.dAnswer,
          validAnswer: this.dValidAnswer
        });
      },
      deep: true
    }
  },
  methods: {
    showAlternatives() {
      this.typingDone = true;
    },
    detectClick(event) {
      this.$emit("clicked", { event: event, id: this.id, tab: this.tab });
    },
    confirmQuestion() {
      this.confirmedOnce = true;
      if (this.dAnswer.length > 0) {
        this.dValidAnswer = true;
      } else {
        this.dValidAnswer = false;
      }
      this.$emit("changeAnswer", {
        id: this.id,
        tab: this.tab,
        answer: this.dAnswer,
        validAnswer: this.dValidAnswer
      });
    }
  }
};
</script>
<style scoped>
</style>