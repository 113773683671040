
module.exports = {
    namespaced: true,
    state: () => ({
        status: 'initialise',
    }),
    mutations: {
        status(state, status) { state.status = status },
    },
    getters: {
    },
    actions: {
        async get(context, data) {
            try {
                if (context.state.status == "pending" || context.state.status == "success") {
                    return;
                }
                const requests = []
                context.commit('status', "pending");
                //context.dispatch("loader/show", null, { root: true })


                /* if (context.rootState.questions.status == 'initialize' && context.rootState.settings.language != null) {
                    requests.push(context.dispatch("questions/get", null, { root: true }))
                } */
                if (context.rootState.settings.status == 'initialize') {
                    requests.push(context.dispatch("settings/get", null, { root: true }))
                }
                if (context.rootState.information.status == 'initialize') {
                    requests.push(context.dispatch("information/get", null, { root: true }))
                }
                await Promise.all(requests)
                /* if (context.rootState.questions.status == 'initialize' && context.rootState.settings.language != null) {
                    requests.push(context.dispatch("questions/get", null, { root: true }))
                } */
                console.log("got all promises")
                context.commit('status', "success");
                context.dispatch("loader/hide", null, { root: true })
                return;
            }
            catch (e) {
                console.log(e)
                context.dispatch("loader/hide", null, { root: true })
                context.dispatch("snackbar/error", "errorLoadingData", { root: true })

            }
        },
        async getDataAfterLanguageChange(context, data) {
            context.dispatch("loader/show", { text: "switchingLanguage" }, { root: true })
            await context.dispatch("questions/get", null, { root: true })
            context.dispatch("loader/hide", null, { root: true })
            return;
        }
    }
}