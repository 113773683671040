<template>
  <div
    @click="detectClick($event)"
    :id="tab + id"
    v-if="visible"
    class="clear"
    v-bind:class="{ 'dim': dimmed==true}"
  >
    <chatbox
      :questionText="questionText"
      :introText="introText"
      :instructionText="instructionText"
      :tab="tab"
      :typing="typing"
      v-on:typingDone="showAlternatives"
    />

    <transition name="fade">
      <div v-if="typingDone" class="text-right">
        <div>
          <v-flex>
            <v-slider
              v-if="showThumbLabel"
              v-model="dAnswer"
              :max="range[1]"
              :min="range[0]"
              :step="sliderStepSize"
              @start="touching=true"
              @end="endOfSlide"
              @mouseup="endOfSlide"
              @mousedown="dragging=true"
              :ticks="false"
              thumb-label="always"
              thumb-size="50"
              :color="myColor"
              always-dirty
              :tick-labels="fixedLabels"
            ></v-slider>
            <v-slider
              v-if="!showThumbLabel"
              v-model="dAnswer"
              :max="range[1]"
              :min="range[0]"
              :step="sliderStepSize"
              @start="touching=true"
              @end="endOfSlide"
              @mouseup="endOfSlide"
              @mousedown="dragging=true"
              :ticks="false"
              thumb-size="50"
              :track-color="myColor"
              :color="myColor"
              always-dirty
              :tick-labels="fixedLabels"
            ></v-slider>
          </v-flex>
          <br />
          <v-chip
            v-if="!confirmedOnce && dValidAnswer && !touching"
            @click="confirmQuestion"
            color="secondary"
          >{{OKText}}</v-chip>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import chatbox from "./misc/chatbox";

export default {
  components: {
    chatbox,
  },
  data() {
    return {
      confirmedOnce: false,
      slideEnded: false,
      touching: false,
      dragging: false,
      myColor: "#038C8C",
      dAnswer: this.answer != null ? this.answer : this.default,
      dLabels: [],
      typing: false,
      typingDone: false,
      dValidAnswer: false,
      OKText: this.atbotOKText || "OK",
    };
  },
  props: {
    id: Number,
    tab: String,
    title: String,
    questionText: String,
    dimmed: Boolean,
    visible: Boolean,
    ticks: Number,
    max: Number,
    min: Number,
    default: Number,
    autoContinue: Boolean,
    sliderStepSize: Number,
    answer: Number,
    showThumbLabel: Boolean,
    range: Array,
    labels: Array,
    introText: String,
    instructionText: String,
    atbotOKText: {
      type: String,
      default: "OK",
    },
  },
  computed: {
    fixedLabels: function () {
      this.dLabels[0] = this.labels[0];
      var labellength = Math.floor(
        (this.range[1] - this.range[0]) / this.sliderStepSize
      );
      //console.log(labellength);
      //console.log(this.range);
      for (var i = 1; i < labellength; i++) {
        this.dLabels[i] = "";
      }
      this.dLabels[labellength] = this.labels[1];
      //console.log(this.dLabels);
      return this.dLabels;
    },
  },
  methods: {
    endOfSlide: function () {
      this.touching = false;
      this.dragging = false;
      this.slideEnded = true;
      this.dValidAnswer = true;
      //console.log("slide ended");
      //console.log(this.dAnswer);
      this.$emit("changeAnswer", {
        id: this.id,
        tab: this.tab,
        answer: this.dAnswer,
        validAnswer: this.dValidAnswer,
      });
      if (this.autoContinue) {
        this.confirmedOnce = true;
        this.$emit("clicked", { event: event, id: this.id, tab: this.tab });
      }
    },
    showAlternatives() {
      this.typingDone = true;
    },
    detectClick(event) {
      /*  if (this.slideEnded == true) {
      } */
    },
    confirmQuestion() {
      this.confirmedOnce = true;
      this.$emit("changeAnswer", {
        event: event,
        id: this.id,
        tab: this.tab,
        answer: this.dAnswer,
        validAnswer: this.dValidAnswer,
      });
      this.$emit("clicked", { event: event, id: this.id, tab: this.tab });
    },
  },
  watch: {
    dAnswer: {
      handler: function () {
        if (
          this.dAnswer != null &&
          this.dAnswer != false &&
          this.dAnswer != ""
        ) {
          this.dValidAnswer = true;
          if (
            this.autoContinue == true &&
            (this.slideEnded == true ||
              (this.touching == false && this.dragging == false))
          ) {
            this.confirmedOnce = true;
            this.$emit("changeAnswer", {
              id: this.id,
              tab: this.tab,
              answer: this.dAnswer,
              validAnswer: this.dValidAnswer,
            });
            this.$emit("clicked", { event: event, id: this.id, tab: this.tab });
          }
        }
        if (this.confirmedOnce == true) {
          //console.log("Sending answer for slide");
          this.$emit("changeAnswer", {
            id: this.id,
            tab: this.tab,
            answer: this.dAnswer,
            validAnswer: this.dValidAnswer,
          });
        }
      },
      deep: true,
    },
  },
};
</script>
<style >
.clear .v-slider__ticks {
  border-width: 0px !important;
}
</style>