<template>
  <v-card :elevation="2" :color="colors[type].mainColor" :dark="colors[type].dark">
            <v-card-title>
              <v-toolbar-title>{{title}}</v-toolbar-title>

              <v-spacer></v-spacer>
            </v-card-title>

            <v-card-subtitle>{{subtitle}}</v-card-subtitle>
            <v-card-text ></v-card-text>
            <v-card-actions class="justify-center">
              <v-btn @click="$emit('clicked', null)" class="text-none px-8" :dark="colors[type].dark" rounded :color="colors[type].secColor">{{actionText}}</v-btn>
            </v-card-actions>
          </v-card>
</template>

<script>
export default {
  data() {
    return {
        colors: {
            "DEFAULT": {mainColor: "success", secColor: "primary", dark: true},
            "IMPORTANT": {mainColor: "error", secColor: "primary", dark: true},
        }
    };
  },
  props: {
    title: String,
    subtitle: String,
    actionText: String,
    type: 
    {
        type: String,
        default: "DEFAULT"
    },
  },
  computed: {
  }
};
</script>