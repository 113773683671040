import Vue from 'vue'
import Router from 'vue-router'
import store from './store/store';
import requests from "./plugins/requests.js";
import ds from "./dataservice";

import LoginPage from './views/LoginPage.vue'
import MissingPath from './views/MissingPath.vue'
import Profile from './views/Profile.vue'
import Questions from './views/Questions.vue'
import Tests from './views/Tests.vue'
import Applications from './views/Applications.vue'
import Application from './views/Application.vue'
import Jobs from './views/Jobs.vue'
import AllJobs from './views/AllJobs.vue'
import Support from './views/Support.vue'
import Overview from './views/Overview.vue'
import Settings from './views/Settings.vue'
import BookInterview from './views/BookInterview.vue'
import Signup from './views/Signup.vue'
import Dashboard from './views/Dashboard.vue'
import PasswordReset from './views/PasswordReset.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        selector: to.hash
      };
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Dashboard,
      /* redirect: {
        path: '/p'
      }, */
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/p',
      name: 'Profile',
      component: Profile,
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/q',
      name: 'Questions',
      component: Questions,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/t',
      name: 'Tests',
      component: Tests,
      meta: {
        requiresAuth: true
      }
    },
    /*     {
          path: '/',
          name: 'Dashboard',
          component: Dashboard,
          meta: {
            requiresAuth: true
          }
        }, */
    {
      path: '/a',
      name: 'Applications',
      component: Applications,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/a/:id',
      name: 'Application',
      component: Application,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/j/:job_guid',
      name: 'Job',
      component: Application,
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/j',
      name: 'Jobs',
      component: Jobs,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/alljobs',
      name: 'AllJobs',
      component: AllJobs,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/s',
      name: 'Settings',
      component: Settings,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/support',
      name: 'Support',
      component: Support,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/o',
      name: 'Overview',
      component: Overview,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/interview/:id',
      name: 'BookInterview',
      component: BookInterview,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/l',
      name: 'LoginPage',
      meta: {
        skipIfLoggedIn: true,
        layout: 'no-sidebar'
      },
      component: LoginPage
    },
    {
      path: '/signup',
      name: 'Signup',
      props: true,
      meta: {
        skipIfLoggedIn: true,
        layout: 'no-sidebar'
      },
      component: Signup
    },
    {
      path: '/password-reset',
      name: 'PasswordReset',
      meta: {
        skipChecks: true,
        layout: 'no-sidebar'
      },
      component: PasswordReset
    },
    {
      path: '*',
      name: 'Missing Path',
      meta: {
        skipChecks: true,
        layout: 'no-sidebar'
      },
      component: MissingPath
    }
  ]
})

const loginPage = { path: '/l' };

export default router



var loginPath = '/l';
var startPagePath = '/';

router.beforeEach(async (to, from, next) => {
  if (!store.state.user.isLoggedIn) {
    store.dispatch("user/load", null);
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.state.user.isLoggedIn) {
      console.log("user is logged in");
      store.dispatch("dataservice/get")
      next();
      return;
    }
    next({
      path: loginPath,
      query: { nextUrl: to.fullPath },
      params: { nextUrl: to.fullPath }
    });
    return;
  }
  else if (to.matched.some(record => record.meta.skipIfLoggedIn && store.state.user.isLoggedIn == true)) {
    console.log("Skip if logged in")

    if (to.name == "Signup" && to.query.new == true) {
        next();
        return;
      }
    else if (to.name == "Signup" && to.query.job != null) {
      next({ path: 'j/' + to.query.job });
    }
    else {
      next({
        path: startPagePath,
      });
    }
    return;
  }
  else {
    next();
    return;
  }
})
