<template>
  <div class="page-title">
    <div class="d-flex">
      <router-link
        v-if="goBack"
        class="no-ul d-flex"
        :to="$router.currentRoute.path.substr(0, $router.currentRoute.path.lastIndexOf('/'))"
      >
        <v-icon class="align-self-center" color="black">arrow_back</v-icon>
      </router-link>
      <h1 class="d-inline">{{title}}</h1>
      <v-btn
        style="margin-left:auto"
        v-if="label != null"
        class="text-none category-button button-not-clickable align-self-center"
        color="#97CFCE"
        depressed
        :ripple="'false'"
      >{{label}}</v-btn>
    </div>
    <v-divider></v-divider>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    label: {
      type: String,
      default: null
    },
    goBack: {
      type: Boolean,
      default: false
    },
    prependIcon: String
  }
};
</script>
<style lang="sass" scoped>
@import '@/styles/components/page-title.sass'
</style>