<template>
  <div>
    <v-select
      :disabled="disabled"
      v-if="answerAlternatives.length > 0"
      v-model="dAnswer"
      :items="answerAlternatives"
      item-value="val"
      item-text="text"
      color="white"
    ></v-select>
    <v-radio-group v-else v-model="dAnswer" row>
      <v-radio
        :disabled="disabled"
        color="primary"
        v-for="alternative in answerAlternatives"
        v-bind:key="alternative.val"
        :label="alternative.text"
        :value="alternative.val"
      ></v-radio>
    </v-radio-group>
    <v-text-field
      v-if="dAnswer == 1"
      :disabled="disabled"
      placeholder="Referensnummer (4d på körkortet)"
      v-model="dAnswerText"
    >
    </v-text-field>
    <!--  <FileUpload
      v-on:uploaded="uploaded"
      v-if=" dAnswer == 1 "
      :uploadType="'CERTIFICATIONS'"
      :uploadLocation="'DRIVERS-LICENSE'"
    /> -->
  </div>
</template>

<script>
import FileUpload from "../misc/FileUpload";

export default {
  components: {
    FileUpload,
  },
  data() {
    return {
      dAnswer: this.answer,
      dAnswerText: this.answer_text,
      dValidAnswer: false,
      uploadeds : null,
      typing: false,
      typingDone: false,
      validationStatus: this.validationStatus,
    };
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    id: Number,
    title: String,
    tab: String,
    groupAnswers: Boolean,
    answer: Number,
    answer_text: String,
    answerAlternatives: Array,
    questionText: String,
    dimmed: Boolean,
    visible: Boolean,
    introText: String,
    instructionText: String,
  },
  watch: {
    dAnswer: {
      handler: function () {
        this.checkAnswer();
      },
      deep: true,
    },
    dAnswerText: {
      handler: function () {
        this.checkAnswer();
      },
      deep: true,
    },
  },
  methods: {
    uploaded: function () {
      this.validationStatus = "PENDING";
    },
    checkAnswer: function () {
      this.dValidAnswer = false;
      if (
        ((this.dAnswer == 1 && this.dAnswerText != null && this.dAnswerText.length > 0) ||
          this.dAnswer == 2) &&
        this.dAnswer != false &&
        this.dAnswer != ""
      ) {
        this.dValidAnswer = true;
      }
      this.$emit("changeAnswer", {
        id: this.id,
        //tab: this.tab,
        answer_text: this.dAnswerText,
        answer: this.dAnswer,
        validAnswer: this.dValidAnswer,
      });
    },
  },
};
</script>