<template>
  <div
    @click="detectClick($event)"
    :id="tab + id"
    v-if="visible"
    class="clear signin-question"
    v-bind:class="{ dim: dimmed == true }"
  >
    <chatbox
      :questionText="questionText"
      :introText="introText"
      :instructionText="instructionText"
      :tab="tab"
      :typing="typing"
      v-on:typingDone="showAlternatives"
    />
    <div v-if="signedIn" class="flex-center-container mt-4 clear">
      <v-subheader>{{ signedInText }}</v-subheader>
    </div>
    <div v-else>
      <v-layout
        v-if="loading"
        class="mt-4"
        wrap
        align-content="center"
        justify="center"
      >
        <v-flex xs3></v-flex>
        <v-flex class="text-xs-center" xs6>
          <span class="text-xs-center">Hämtar login information</span>
          <v-progress-linear
            color="#038c8c"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-flex>
        <v-flex xs3></v-flex>
      </v-layout>
      <transition name="fade">
        <div v-if="typingDone && !signedIn && !loading">
          <v-container fluid >
          <div>
             <v-checkbox class="text-right" v-model="consent">
            <template v-slot:label>
              <div>
                Jag godkänner att mina personuppgifter sparas och hanteras
                enligt <a @click.stop href="https://brite.jobs/integritetspolicy" target="_blank">integritetspolicyn</a>
              </div>
            </template>
          </v-checkbox>
          </div>

      <v-card
        class="mx-0"
        flat
      >


<v-card-text>

          <div>
            
            <h3>Logga in med ett existerande konto</h3>
            <div class="buttonContainer">
              <LoginButtons v-on:loggedIn="loggedIn" />
            </div>
            <!--             <v-layout class="login-container" wrap>
              <v-flex xs12 sm5 class="text-xs-center">
                <button @click="authenticate('google')">
            <!--  <button @click="testGoogle">-->
            <!--  <img
                    class="login-button login-button-google"
                    src="../../assets/img/signin/btn_google_signin_dark_normal_web@2x.png"
                  />
                </button>
              </v-flex>
              <v-flex xs12 sm5 class="text-xs-center">
                <button @click="authenticate('facebook')">
                  <!-- <img
                    class="login-button login-button-facebook"
                    src="../../assets/img/signin/login_with_facebook.png"
                  />
                </button>
              </v-flex>
            </v-layout> 

            <!--  <v-btn @click="gSignIn" color="#CE4332" class="signinButton googleButton">Google</v-btn>
            <facebook-login
              ref="fbbtn"
              id="facebookButton"
              appId="438297253358759"
              @login="facebookGetLoginData"
              @logout="facebookLogout"
              :loginOptions="facebookLoginOptions"
              @get-initial-status="facebookGetLoginData"
            ></facebook-login>
            <v-btn
              @click="clickFbButton"
              color="#4267B2"
              class="signinButton facebookButton"
            >Facebook</v-btn>-->

            <h3>Eller skapa ett nytt</h3>
            <v-form v-model="validForm">
              <v-text-field
                color="primary"
                v-model="nativeSignInData.dEmail"
                :rules="rules.emailRules"
                label="E-post"
                required
              ></v-text-field>
              <v-text-field
                color="primary"
                v-model="nativeSignInData.dPassword"
                :append-icon="show1 ? 'visibility' : 'visibility_off'"
                :rules="[rules.required, rules.min]"
                :type="show1 ? 'text' : 'password'"
                name="input-10-1"
                label="Lösenord"
                hint="Minst 8 tecken"
                counter
                @click:append="show1 = !show1"
                required
                loading
              >
                <template v-slot:progress>
                  <v-progress-linear
                    :value="progress"
                    :color="color"
                    absolute
                    height="7"
                  ></v-progress-linear>
                </template>
              </v-text-field>
              <v-text-field
                color="primary"
                v-model="nativeSignInData.dPasswordConfirmation"
                :append-icon="show1 ? 'visibility' : 'visibility_off'"
                :rules="[rules.passwordMatch, rules.min]"
                :type="show1 ? 'text' : 'password'"
                name="input-10-1"
                label="Upprepa lösenord"
                @click:append="show1 = !show1"
                required
              ></v-text-field>
              <transition name="slide-fade">
                <v-row justify="end">
                  <v-btn
                    :disabled="!validForm"
                    class="text-none"
                    rounded
                    color="primary"
                    @click="createNativeUser"
                    >Skapa konto</v-btn
                  >
                </v-row>
              </transition>
            </v-form>
          </div>
</v-card-text>

        <v-fade-transition>
          <v-overlay
            v-if="!consent"
            absolute
            color="white"
          >
          </v-overlay>
        </v-fade-transition>
      </v-card>
   
        
          </v-container>
        </div>
      </transition>
      <transition name="fade">
        <div>
          <div v-if="typingDone" class="text-right">
            <transition name="fade">
              <div v-show="signedIn">
                <br />
                <v-chip
                  @click="confirmQuestion"
                  v-bind:class="{
                    'v-chip--outline': !dValidAnswer || !confirmedOnce,
                    'white--text': confirmedOnce,
                  }"
                  >{{ OKText }}</v-chip
                >
              </div>
            </transition>
          </div>
        </div>
      </transition>
      <transition name="fade"></transition>
      <transition name="fade">
        <v-alert type="error" v-if="signinError">{{ signinErrorText }}</v-alert>
      </transition>
    </div>
  </div>
</template>
<script>
import chatbox from "./misc/chatbox";
import LoginButtons from "../../misc/LoginButtons";
import requests from "../../../requests/signup/signup";
import store from "../../../store/store";
// import facebookLogin from "facebook-login-vuejs";

export default {
  components: {
    chatbox,
    LoginButtons,
  },
  data() {
    return {
      confirmedOnce: false,
      OKText: this.atbotOKText || "OK",
      isAuthenticated: "ss",
      myColor: "#038C8C",
      consent: false,
      loading: false,
      dValidAnswer: false,
      nativeSignInData: {
        dEmail: "",
        dPassword: "",
        dPasswordConfirmation: "",
      },
      validForm: false,
      show1: false,
      password: "Password",
      typing: false,
      typingDone: false,
      facebookLoginOptions: { scope: "email" },
      signedIn: false,
      signinError: false,
      validNativeSignInData: false,
      signedInText: "Du är nu inloggad!",
      signinErrorText: "Det uppstod ett fel vid inloggning!",
      rules: {
        required: (value) => !!value || "",
        min: (v) => v.length >= 8 || "Minst 8 tecken",
        passwordMatch: (v) =>
          v == this.nativeSignInData.dPassword || "Lösenorden matchar inte",
        emailRules: [
          (v) => !!v || "",
          (v) => /.+@.+\../.test(v) || "Ej godkänd e-post",
        ],
      },
    };
  },
  props: {
    id: Number,
    tab: String,
    title: String,
    questionText: String,
    dimmed: Boolean,
    visible: Boolean,
    introText: String,
    instructionText: String,
    atbotOKText: {
      type: String,
      default: "OK",
    },
  },
  mounted: function () {
    console.log("store.state.isLoggedIn");
    console.log(store.state.isLoggedIn);
    if (store.state.isLoggedIn == true) {
      this.loggedIn(true);
    }
    // self = this;
    // requests.methods.checkIfLoggedIn().then(status => {
    //   self.signedIn = status;
    //   console.log("Signed in: " + status);
    // });
  },
  watch: {
    signedIn: {
      handler: function () {
        if (this.signedIn) {
          this.signinError = false;
        }
      },
    },
    signinError: {
      handler: function () {
        if (this.signinError) {
          this.signedIn = false;
        }
      },
    },
  },
  computed: {
    progress() {
      return Math.min(100, this.nativeSignInData.dPassword.length * 10);
    },
    color() {
      return ["#D94032", "#F2AF5C", "#038C8C"][Math.floor(this.progress / 40)];
    },
  },
  methods: {
    showAlternatives() {
      this.typingDone = true;
    },
    detectClick(event) {
      if (this.signedIn == true) {
        this.dValidAnswer = true;
      }
      this.$emit("clicked", {
        event: event,
        id: this.id,
        tab: this.tab,
        validAnswer: this.dValidAnswer,
      });
    },
    async createNativeUser() {
      try {
        var self = this;
        this.loading = true;
        await store.dispatch("user/createNativeUser", {
          email: this.nativeSignInData.dEmail,
          username: this.nativeSignInData.dEmail,
          password: this.nativeSignInData.dPassword,
        });
        console.log("MY Successful Brite response");
        //console.log(response);
        self.signedInText =
          "Du är nu inloggad på Brite - ditt användarnamn är " +
          this.nativeSignInData.dEmail;
        self.signedIn = true;
        this.loading = false;
        self.confirmQuestion();
      } catch (e) {
        console.log(e);
        self.signinErrorText = "Kunde inte skapa användare. Försök igen.";
        self.signinError = true;
        this.loading = false;
        try {
          if (e.response.data.code == "userAlreadyExist") {
            console.log(e.response.data);
            self.signinErrorText = "Användaren existerar redan.";
            return;
          }
        } catch (e) {}
      }
    },
    loggedIn: function (user) {
      console.log("Logged in is: " + user.loggedIn);
      if (user.loggedIn) {
        console.log("True: " + user.loggedIn);

        this.signedIn = true;
        this.confirmQuestion();
      }
    },
    confirmQuestion() {
      this.signedIn = true;
      this.confirmedOnce = true;
      this.$emit("changeAnswer", {
        event: event,
        id: this.id,
        tab: this.tab,
        validAnswer: true,
      });
      this.$emit("applyForJob", {});
      this.$emit("clicked", { event: event, id: this.id, tab: this.tab });
    },
  },
};
</script>
<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.flex-center-container {
  display: flex;
  justify-content: center;
  text-align: center;
}

.chip-xs-right {
  float: right;
}
.fade-leave-active {
  transition: none;
}
.fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  display: none;
}
</style>
