import Vue from 'vue'
import Vuex from 'vuex'
import state from './state'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'
import snackbar from './snackbar'
import loader from './loader'
import user from './user/index'
import jobs from './jobs'
import signup from './signup'
import applications from './applications'
import questions from './questions'
import settings from './settings'
import information from './information'
import tests from './tests'
import error from './error'
import dataservice from './dataservice'


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    snackbar: snackbar,
    loader: loader,
    user: user,
    jobs: jobs,
    questions: questions,
    settings: settings,
    information: information,
    error: error,
    dataservice: dataservice,
    applications: applications,
    signup: signup,
    tests: tests
  },
  state: state,
  actions: actions,
  getters: getters,
  mutations: mutations
})

//store.dispatch("settings/language");
