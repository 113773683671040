
module.exports = {
    namespaced: true,
    state: () => ({
        visible: false,
        text: "snackMessage",
        x: null,
        y: "bottom",
        color: "primary",
        timeout: 0,
        dismissable: true,
        mode: null
    }),
    mutations: {
        visible(state, status) { state.visible = status },
        text(state, status) { state.text = status },
        x(state, status) { state.x = status },
        y(state, status) { state.y = status },
        color(state, status) { state.color = status },
        timeout(state, status) { state.timeout = status },
        dismissable(state, status) { state.dismissable = status },
        mode(state, status) { state.mode = status },
    },
    getters: {
    },
    actions: {
        default(context, data) {
            context.commit('x', null);
            context.commit('y', "bottom");
            context.commit('color', "primary");
            context.commit('timeout', 3000);
            context.commit('dismissable', true);
            context.commit('mode', null);
        },
        success(context, data) {
            context.dispatch('default', data)
            context.commit('color', "success");
            context.commit('text', data);
            context.commit('visible', true);

        },
        error(context, data) {
            context.dispatch('default', data)
            context.commit('timeout', 0);
            context.commit('color', "error");
            context.commit('text', data);
            context.commit('visible', true);

        },
        visible(context, data) {
            context.commit('visible', data)
        }
    }
}