<template>
  <v-card class="mx-auto" >
    <v-card-title>
      <h2>{{title}}</h2>
      <v-spacer></v-spacer>
     <slot name="cardOptions"> 
     </slot>
    </v-card-title>
    <slot></slot>
  </v-card>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    title: String,
  },
};
</script>