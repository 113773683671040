 <template>
  <div class="mx-auto page-normal">
    <PageHeader :title="page.title" :divider="true"></PageHeader>
    <PageActions :alertBar="alertBar" :breadcrumbs="[]"></PageActions>
    <PageContent :loading="loading">
      <v-snackbar
        v-model="showSnackbar"
        :timeout="snackbartimeout"
        :bottom="snackbarbottom"
        color="secondary"
        :multi-line="snackbarmultiline"
      >
        {{ snackbarMessage }}
        <v-btn color="primary" text @click="showSnackbar = false">Stäng</v-btn>
      </v-snackbar>
      <AlertBox v-if="showAlertBox" />
      <Spinner v-if="loading == true" v-bind:message="'loadingJobs'" />
      <v-row
        v-if="invitedToInterviews != null && invitedToInterviews.length > 0"
      >
        <v-col v-for="item in invitedToInterviews" :key="item.job_guid">
          <v-card
            class="flex-column d-flex"
            height="100%"
            :elevation="2"
            color="success"
            dark
          >
            <v-card-title>
              <v-toolbar-title>Inbjudan till intervju!</v-toolbar-title>

              <v-spacer></v-spacer>
            </v-card-title>

            <v-card-subtitle
              >Du har fått en inbjudan till tjänsten som
              {{ item.title }}</v-card-subtitle
            >
            <v-card-actions class="justify-center">
              <v-btn
                @click="openJob(item)"
                class="text-none px-8"
                rounded
                color="primary"
                >Svara</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="upcomingInterviews != null && upcomingInterviews.length > 0">
        <v-col cols="12" md="12">
          <SimpleList
            :items="upcomingInterviews"
            :keyName="'job_guid'"
            :itemTitle="'title'"
            :itemAvatar="'company_logo'"
            :itemSubtitle="'company'"
            :itemSubtitle2="'description'"
            :itemActionText="'meetingStart'"
            :itemActionAvatarColor="'jobStatusColor'"
            :title="'Kommande intervjuer'"
            v-on:listItemClick="openJob"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col v-if="testStatus == 'success' && userTestStatus == 'OPEN'" cols="12" md="6">
          <ActionCard
            :type="'DEFAULT'"
            v-on:clicked="$router.push('/t')"
            :title="'Dags att göra test'"
            :subtitle="'En arbetsgivare vill att du gör ett test'"
            :actionText="'Till tester'"
          />
        </v-col>
      
        <v-col v-if="informationStatus == 'success' && userQuestionStatus.answered_all_match_questions != 1" cols="12" md="6">
          <ActionCard
            :type="'IMPORTANT'"
            v-on:clicked="$router.push('/p')"
            :title="'Matchningsproblem'"
            :subtitle="'Din profil är inte helt ifylld, du kan fortfarande söka jobb via Brite men du kommer inte kunna få specifika matchningar och förslag på jobb som passar dig. När arbetsgivare tittar på din profil kommer dem inte heller veta om du matchar med jobbets kriterier.'"
            :actionText="'Till min profil'"
          />
        </v-col>
        <v-col  v-if="informationStatus == 'success' && userQuestionStatus.answered_all_motivation_questions != 1" cols="12" md="6">
          <ActionCard
            :type="'IMPORTANT'"
            v-on:clicked="
              $router.push({
                name: 'Profile',
                params: { startTab: 'questions' },
              })
            "
            :title="'Okända värderingar'"
            :subtitle="'Du har inte svarat på alla motivationsfrågor. Det innebär att arbetsgivaren inte kan veta hur era värderingar stämmer överens.'"
            :actionText="'Svara på motivationsfrågor'"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
          v-if="applications != null && applications.length > 0"
        >
          <v-card
            class="flex-column d-flex"
            height="100%"
            :elevation="2"
            color="primary"
            dark
          >
            <v-card-title>
              <v-toolbar-title
                >{{ applications.length }} ansökningar</v-toolbar-title
              >

              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-actions class="justify-center">
              <v-btn
                :to="'/a'"
                :dark="false"
                class="text-none black--text px-8"
                rounded
                color="white"
                >Gå till ansökningar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col
          cols="12"
          md="6"
          v-if="matchedJobs != null && matchedJobs.length > 0"
        >
          <v-card
            class="flex-column d-flex"
            height="100%"
            :elevation="2"
            color="accent"
            dark
          >
            <v-card-title>
              <v-toolbar-title
                >{{ matchedJobs.length }} matchade jobb</v-toolbar-title
              >

              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-actions class="justify-center">
              <v-btn
                :to="'/j'"
                :dark="false"
                class="text-none black--text px-8"
                rounded
                color="white"
                >Visa jobb</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </PageContent>
  </div>
</template>

<script>
import ouical from "../utils/addToCalendar.js";

import PageHeader from "../components/layout/PageHeader";
import PageActions from "../components/layout/PageActions";
import PageContent from "../components/layout/PageContent";

import store from "../store/store";
import ds from "../dataservice";
import { mapState, mapActions } from "vuex";
import Spinner from "../components/misc/Spinner";
import SimpleList from "../components/basic/SimpleList";
import AlertBox from "../components/misc/AlertBox";
import ActionDialog from "../components/actions/ActionDialog";
import DataTableTitle from "../components/tables/DataTableTitle";
import jobrequests from "../requests/jobs/requests";
import PageTitle from "../components/page/Title";
import AlertBar from "../components/basic/AlertBar";
import AlertBarMixin from "@/mixins/alertBar";
import basic from "@/mixins/basic";

export default {
  mixins: [AlertBarMixin, basic],
  components: {
    Spinner,
    AlertBox,
    ActionDialog,
    DataTableTitle,
    PageTitle,
    PageTitle,
    PageHeader,
    PageContent,
    PageActions,
    AlertBar,
    SimpleList,
  },
  data() {
    return {
      page: {
        key: "jobs",
        title: this.$t("views.dashboard.title"),
        tableTitle: "Alla ansökningar",
      },
      snackbarMessage: null,
      showSnackbar: false,
      snackbartop: true,
      snackbartimeout: 5000,
      snackbarbottom: true,
      snackbarmultiline: true,
      showAlertBox: false,
      loading: true,
      jobs: [],
      jobs_selected: [],
      jobs_search: "",
      googleLink: null,
    };
  },
  props: {},
  computed: {
    ...mapState("applications", [
      "status",
      "headers",
      "upcomingInterviews",
      "invitedToInterviews",
      "applications",
      "matchedJobs",
    ]),
    ...mapState("information", {informationStatus: "status", userQuestionStatus:"userQuestionStatus"}),
    ...mapState("tests", {
      userTestStatus: "userTestStatus",
      testStatus: "status",
    }),
  },
  created: function () {},
  beforeMount: function () {
    this.handleLoad(this.status, this.testStatus);
  },
  watch: {
    status(newValue, oldValue) {
      this.handleLoad(newValue, this.testStatus);
    },
    testStatus(newValue, oldValue) {
      this.handleLoad(this.status, this.testStatus);
    },
  },
  methods: {
    ...mapActions("applications", { getApplications: "get" }),
    ...mapActions("tests", { getTests: "get" }),
    handleLoad(applicationStatus, testStatus) {
      if (applicationStatus === "success") {
        //this.loading = false;
      } else if (applicationStatus === "initialize") {
        this.getJobs();
      } else if (applicationStatus === "failed") {
        this.loading = false;
        this.showAlertBox = true;
        return;
      }
      if (testStatus === "success") {
        //this.loading = false;
      } else if (testStatus === "initialize") {
        this.getTests();
      } else if (testStatus === "failed") {
        this.loading = false;
        this.showAlertBox = true;
        return;
      }
      if (this.status == "success") {
        this.loading = false;
      }
    },
    getJobs: async function () {
      try {
        this.loading = true;
        await this.getApplications();
        //this.loading = false;
        this.showAlertBox = false;
      } catch (err) {
        console.log("failed to get jobs");
        console.log(err);
        this.showAlertBox = false;
      }
    },
    openJob(job) {
      console.log(job);
      this.$router.push({
        name: "Job",
        params: {
          job_guid: job.job_guid,
          application_guid: job.application_guid,
        },
      });
    },
  },
};
</script>
<style scope>
.dialog-action-btn.v-btn:not(.v-btn--round).v-size--default {
  min-width: 150px;
}
.theme--light.v-card > .v-card__text {
  color: black;
}
</style>