<template>
  <div
    @click="detectClick($event)"
    :id="tab + id"
    v-if="visible"
    class="clear"
    v-bind:class="{ 'dim': dimmed==true}"
  >
    <chatbox
      :questionText="questionText"
      :introText="introText"
      :instructionText="instructionText"
      :tab="tab"
      :typing="typing"
      v-on:typingDone="showAlternatives"
    />
    <transition name="fade">
      <div v-if="typingDone" class="text-right">
        <v-layout wrap>
          <v-flex xs12 v-bind:class="{ 'groupAnswers': groupAnswers}">
            <ul>
              <li v-for="alt in answerAlternatives" :key="alt.val">
                <input v-model="dAnswer" type="radio" :value="alt.val" :id="id+alt.text" />
                <label :for="id+alt.text" @click.stop>
                  <v-chip :outlined="dAnswer!=alt.val" color="secondary">{{alt.text}}</v-chip>
                </label>
              </li>
            </ul>
          </v-flex>
        </v-layout>
      </div>
    </transition>
  </div>
</template>
<script>
import chatbox from "./misc/chatbox";

export default {
  components: {
    chatbox
  },
  data() {
    return {
      dAnswer: this.answer,
      dValidAnswer: false,
      typing: false,
      typingDone: false
    };
  },
  props: {
    id: Number,
    title: String,
    tab: String,
    groupAnswers: Boolean,
    answer: Number,
    answerAlternatives: Array,
    questionText: String,
    dimmed: Boolean,
    visible: Boolean,
    introText: String,
    instructionText: String
  },
  watch: {
    dAnswer: {
      handler: function() {
        if (
          this.dAnswer != null &&
          this.dAnswer != false &&
          this.dAnswer != ""
        ) {
          this.dValidAnswer = true;
        }
        this.$emit("changeAnswer", {
          id: this.id,
          tab: this.tab,
          answer: this.dAnswer,
          validAnswer: this.dValidAnswer
        });
      },
      deep: true
    }
  },
  methods: {
    showAlternatives() {
      this.typingDone = true;
    },
    detectClick(event) {
      this.$emit("clicked", {
        event: event,
        id: this.id,
        tab: this.tab,
        validAnswer: this.dValidAnswer
      });
    }
  }
};
</script>