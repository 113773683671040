
export default {
    namespaced: true,
    state: () => ({
        errorCodes: {
            "unknownError": { id: 1000, status: 400, message: "Something went wrong" },
            "errorWhileGettingUserSettings": { id: 1001, status: 400, message: "Could not get data" },
            "noUserSettings": { id: 1002, status: 400, message: "No settings exists for user" },
            "sqlError": { id: 1003, status: 500, message: "We did something bad" },
            "invalidPayload": { id: 1004, status: 400, message: "Bad payload" },
            "failedUpdate": { id: 1005, status: 400, message: "Could not update info" },
            "errorError": { id: 9999, status: 500, message: "Error squared" },
        },
    }),
    mutations: {
    },
    getters: {
    },
    actions: {
        handle(context, e) {
            try {
                if(typeof e == "string" && context.state.errorCodes.hasOwnProperty(e)) {
                    return e
                }
                else if (e.hasOwnProperty("code") && context.state.errorCodes.hasOwnProperty(e.code)) {
                    return e.code
                }
                else {
                    return "unknownError"
                }

            }
            catch (e) {
                console.log(e);
            }
        },
    }
}