<template>
  <v-card
    flat
    @dragenter.stop="dragCounter++"
    @drop="handleDrop($event)"
    @dragleave.stop="dragCounter--"
    :class="{ 'file-hover': fileHover }"
  >
    <v-card-text class="pa-4">
      <div class="file-upload-card-content">
        <form @submit.prevent="saveFile" enctype="multipart/form-data">
          <v-row
            :class="{ 'visibility-hidden': fileHover }"
            class="fields justify-center"
          >
            <div class="upload-btn-wrapper">
              <v-btn class="text-none" rounded color="primary"
                >Välj fil på datorn</v-btn
              >
              <input
                type="file"
                name="myfile"
                ref="file"
                @change="handleSelect"
              />
            </div>
            <div class="fields mt-4">
              <span>Eller släpp din fil här</span>
            </div>
            <div class="message">
              <v-alert
                v-if="errorMessage != null"
                dense
                outlined
                type="error"
                >{{ errorMessage }}</v-alert
              >
              <v-alert v-if="message != null" dense outlined type="success">{{
                message
              }}</v-alert>
            </div>
          </v-row>
          <v-row :class="{ 'visibility-hidden': !fileHover }">
            <h3>Nu kan du släppa</h3>
          </v-row>
          <v-row v-if="imageUpload" align="center" justify="center">
            <div
              style="display: inline-block; padding: 25px"
              class="grey lighten-2"
            >
              <v-img
                style="display: block; height: 100%; width: 200px"
                contain
                :src="fileSrc"
                aspect-ratio="1"
                widht="200"
                max-width="200"
              ></v-img>
            </div>
          </v-row>
        </form>
      </div>
    </v-card-text>
    <v-card-actions justify="center" v-if="fileSrc != null">
      <Spinner
        v-if="uploadingFile == true"
        v-bind:message="'Laddar upp bild'"
      />
      <slot v-else name="actions">
        <v-row class="mx-0" justify="space-around">
          <v-btn
            @click="fileSrc = originalUrl"
            class="dialog-action-btn text-none"
            rounded
            text
            color="error"
            >Avbryt</v-btn
          >
          <v-btn
            @click="saveFile"
            class="dialog-action-btn text-none"
            rounded
            dark
            color="primary"
            >Spara</v-btn
          >
        </v-row>
      </slot>
    </v-card-actions>
  </v-card>
</template>


<script>
import axios from "axios";
import mixin from "@/mixins/general";
import Spinner from "@/components/misc/Spinner.vue";

import requests from "../../requests/global";

export default {
  name: "FileUpload",
  mixins: [mixin],
  components: {
    //vueDropzone: vue2Dropzone,
    Spinner,
  },
  data() {
    return {
      uploadingFile: false,
      wrongFileTypeMessage: "Fel fil-typ. Jpg och Png godkända.",
      couldNotFetchMessage: "Inte en bild eller kunde inte hämta",
      fileSizeLimit: 5000000,
      fileSizeTooBigMessage: "För stor fil, max 5mb",
      dragCounter: 0,
      file: null,
      urlSrc: "",
      fileSrc: ``,
      errorMessage: null,
      message: null,
      uploadedMessage: "Fil sparad!",
    };
  },
  props: {
    uploadType: String,
    uploadLocation: String,
    imageUpload: {
      type: Boolean,
      default: true,
    },
    allowedTypes: {
      default: () => ["image/jpeg", "image/jpg", "image/png"],
      type: Array,
    },
    originalUrl: {
      default:
        "https://storage.googleapis.com/brite-files/0a74f4cc-b859-4eef-94c0-cac0f3e61b47.png",
      type: String,
    },
  },
  watch: {
    originalUrl: function () {
      this.fileSrc = this.originalUrl;
      //console.log("this.fileSrc");
      //console.log(this.fileSrc);
      //console.log(this.originalUrl);
    },
    urlSrc: async function () {
      this.corsIssue = false;
      this.errorMessage = null;
      this.message = null;
      console.log(this.validBase64(this.urlSrc));
      if (this.validBase64(this.urlSrc)) {
        console.log("base64 string");
      } else if (!this.validUrl(this.urlSrc)) {
        console.log("Not a valid url");
        return;
      }
      try {
        let b = await fetch(this.urlSrc).then((r) => r);
        let blob = await b.blob();
        console.log(b);
        console.log(blob);
        var file = new File([blob], Date.now(), {
          type: blob.type,
          lastModified: Date.now(),
        });
        this.checkFile(file);
      } catch (e) {
        this.fileSrc = this.urlSrc;
        console.log("CORS issue");
        //this.errorMessage = this.couldNotFetchMessage;
        this.corsIssue = true;
      }
    },
  },
  created: function () {
    this.fileSrc = this.originalUrl;
    window.addEventListener(
      "dragover",
      function (e) {
        e = e || event;
        e.preventDefault();
      },
      false
    );
    window.addEventListener(
      "drop",
      function (e) {
        e = e || event;
        e.preventDefault();
      },
      false
    );
  },
  computed: {
    fileHover: function () {
      return this.dragCounter > 0;
    },
  },
  methods: {
    updateOriginalUrl: function (link) {
      this.$emit("update", link);
      //this.fileSrc = this.originalUrl;
    },
    previewFile(file) {
      var self = this;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function () {
        self.file = file;
        self.fileSrc = reader.result;
        console.log(file);
      };
    },
    validateFile: function (file) {
      /*     if (!this.allowedTypes.includes(file.type)) {
        this.errorMessage = this.wrongFileTypeMessage;
        return false;
      } */
      if (file.size > this.fileSizeLimit) {
        this.errorMessage = this.fileSizeTooBigMessage;
        return false;
      }
      return true;
    },
    handleDrop: function (e) {
      e.preventDefault;
      this.corsIssue = false;
      this.dragCounter = 0;
      this.checkFile(e.dataTransfer.files[0]);
    },
    handleSelect: function () {
      this.corsIssue = false;
      this.checkFile(this.$refs.file.files[0]);
    },
    checkFile(file) {
      this.errorMessage = null;
      this.message = null;
      if (this.validateFile(file)) {
        this.previewFile(file);
      }
    },
    async saveFile() {
      var self = this;
      this.uploadingFile = true;
      console.log(self.file);
      console.log("CORS status");
      console.log(this.corsIssue);
      if (this.corsIssue) {
        console.log("Url fix");

        this.$store
          .dispatch("uploadUrl", this.fileSrc)
          .then((response) => {
            self.message = self.uploadedMessage;
            self.updateOriginalUrl(response);
            console.log(response);
            this.uploadingFile = false;
          })
          .catch((err) => {
            console.log(err);
            self.errorMessage = err;
            this.uploadingFile = false;
          });
      } else {
        //return;
        try {
          console.log("1 status");

          const formData = new FormData();
          console.log("2 status");
          formData.append("file", self.file);
          formData.append("type", self.uploadType);
          formData.append("location", self.uploadLocation);
          console.log("3 status");
          var results = await requests.uploadFile({
            formData: formData,
          });
          this.$emit("uploaded", null);
          //self.message = self.uploadedMessage;
          //self.updateOriginalUrl(response);
          //console.log(response);
          this.fileSrc = results.url;
          this.uploadingFile = false;
        } catch (err) {
          console.log(err);
          self.errorMessage = err;
          this.uploadingFile = false;
        }
      }
    },
  },
};
</script>
<style>
.dialog-action-btn {
  min-width: 200px !important;
}
.file-upload-card-content {
  max-width: 400px;
  width: 100%;
  margin: auto;
}
.file-upload-card-content .row {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.file-hover {
  background-color: rgb(241, 241, 241) !important;
  opacity: 0.8;
}

.visibility-hidden {
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
}

input[type=file], /* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
}

.btn {
  border: 2px solid gray;
  color: gray;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.dropzone-custom-content {
  text-align: center;
}

.dropzone-custom-title {
  margin-top: 0;
  color: #00b782;
}

.subtitle {
  color: #314b5f;
}

/* .vue-dropzone {
  display: inline-block;
} */
.vue-dropzone .dz-error-message {
  opacity: 1 !important;
  top: 0 !important;
}
.vue-dropzone > .dz-preview .dz-details {
  bottom: 0;
  top: 0;
  color: #fff;
  background-color: #182540;
  -webkit-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
  text-align: left;
}
</style>