const questions = (state, questions) => {
    state.questions = questions;
};
const status = (state, status) => {
    state.status = status;
};
const jobInfo = (state, jobInfo) => {
    state.jobInfo = jobInfo;
};
const jobInfo_status = (state, jobInfo_status) => {
    state.jobInfo_status = jobInfo_status;
};
const loadingError = (state, loadingError) => {
    state.loadingError = loadingError;
};

export default {
    questions,
    status,
    loadingError,
    jobInfo_status,
    jobInfo
}