import router from '../router'

const logout = (state) => {
    $cookies.remove("encrypted_access_token");
    $cookies.remove("encrypted_session_token");
    state.isLoggedIn = false;
    router.push({ path: "l" });
};
const isLoggedIn = (state, status) => {
    console.log("status is" + status)
    console.log("state is" + state)
    state.isLoggedIn = status;
};
const showGlobalLoader = (state, status) => {
    state.showGlobalLoader = status;
};
const globalLoaderMessage = (state, status) => {
    state.globalLoaderMessage = status;
};
const newUser = (state, status) => {
    state.newUser = status;
};
const encryptedAccessToken = (state, token) => {
    $cookies.set("encrypted_access_token", token, 48 * 60 * 60, null, null, false)
    state.encryptedAccessToken = token;
};
const encryptedSessionToken = (state, token ) => {
    $cookies.set("encrypted_session_token", token, 48 * 60 * 60, null, null, false)
    state.encryptedSessionToken = token;
};
const userInfo = (state, obj ) => {
    //console.log("saving user info");
    state.userInfo = obj;
    //console.log("saved user info");
};
const question_categories = (state, obj ) => {
    state.question_categories = obj;
};
const all_questions = (state, obj ) => {
    state.all_questions = obj;
};
const profile = (state, obj ) => {
    state.profile = obj;
};
const questions = (state, obj ) => {
    state.questions = obj;
};
const profile_questions_status = (state, status ) => {
    state.profile_questions_status = status;
};
const signup_questions = (state, obj ) => {
    state.signup_questions = obj;
};
const signup_questions_status = (state, status ) => {
    state.signup_questions_status = status;
};
const account_info = (state, obj ) => {
    state.account_info = obj;
};
const account_info_status = (state, status ) => {
    state.account_info = status;
};
const tests = (state, obj ) => {
    state.tests = obj;
};
const tests_status = (state, status ) => {
    state.tests_status = status;
};
const jobs = (state, obj ) => {
    state.jobs = obj;
};
const jobs_status = (state, status ) => {
    state.jobs_status = status;
};
const all_jobs = (state, obj ) => {
    state.all_jobs = obj;
};
const all_jobs_status = (state, status ) => {
    state.all_jobs_status = status;
};
const applications = (state, obj ) => {
    state.applications = obj;
};
const active_applications = (state, obj ) => {
    state.active_applications = obj;
};
const other_applications = (state, obj ) => {
    state.other_applications = obj;
};
const applications_status = (state, status ) => {
    state.applications_status = status;
};

export default {
    question_categories,
    all_questions,
    logout,
    isLoggedIn,
    showGlobalLoader,
    globalLoaderMessage,
    newUser,
    encryptedAccessToken,
    encryptedSessionToken,
    userInfo,
    profile,
    questions,
    profile_questions_status,
    signup_questions,
    signup_questions_status,
    account_info,
    account_info_status,
    tests,
    tests_status,
    jobs,
    jobs_status,
    all_jobs,
    all_jobs_status,
    applications,
    active_applications,
    other_applications,
    applications_status
};