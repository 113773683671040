//import { axios } from 'axios';

import Vue from 'vue'
import store from "../../store/store.js";
//import { reject } from 'q';

export default {

    getJobs: function () {
        var encryptedAccessToken = $cookies.get("encrypted_access_token");
        var encryptedSessionToken = ($cookies.get("encrypted_session_token") != null) ? $cookies.get("encrypted_session_token") : '';
        return new Promise((resolve, reject) => {
            if (encryptedAccessToken == null) {
                reject({ validToken: false });
            }
            //Vue.axios.get("http://localhost:3000/at/matchedjobs",
            Vue.axios.get("https://us-central1-higher.cloudfunctions.net/service/at/matchedjobs",
                {
                    //Vue.axios.get("http://localhost:3000/ag/jobs", {
                    params: {
                        encryptedAccessToken: encryptedAccessToken,
                        encryptedSessionToken: encryptedSessionToken
                    }
                })
                .then((response) => {
                    console.log(response.data);                        
                    store.dispatch('updateJobs', response.data)

                    
                },
                    (error) => {
                        console.log(error);
                        reject({ validToken: false });
                    });
        });
    },
    getAllJobs: function () {
        var encryptedAccessToken = $cookies.get("encrypted_access_token");
        var encryptedSessionToken = ($cookies.get("encrypted_session_token") != null) ? $cookies.get("encrypted_session_token") : '';
        return new Promise((resolve, reject) => {
            if (encryptedAccessToken == null) {
                reject({ validToken: false });
            }
            //Vue.axios.get("http://localhost:3000/at/alljobs",
            Vue.axios.get("https://us-central1-higher.cloudfunctions.net/service/at/jobs",
                {
                    //Vue.axios.get("http://localhost:3000/ag/jobs", {
                    params: {
                        encryptedAccessToken: encryptedAccessToken,
                        encryptedSessionToken: encryptedSessionToken
                    }
                })
                .then((response) => {
                    console.log(response.data);                        
                    store.dispatch('updateAllJobs', response.data)

                    
                },
                    (error) => {
                        console.log(error);
                        reject({ validToken: false });
                    });
        });
    },
        getSpecificJob: function (id) {
            var encryptedAccessToken = $cookies.get("encrypted_access_token");
            var encryptedSessionToken = ($cookies.get("encrypted_session_token") != null) ? $cookies.get("encrypted_session_token") : '';
            return new Promise((resolve, reject) => {
                if (encryptedAccessToken == null) {
                    reject({ validToken: false });
                }
                //Vue.axios.get("http://localhost:3000/at/jobs/" + id,
                Vue.axios.get("https://us-central1-higher.cloudfunctions.net/service/at/jobs/" + id,
                    {
                        //Vue.axios.get("http://localhost:3000/ag/jobs", {
                        params: {
                            encryptedAccessToken: encryptedAccessToken,
                            encryptedSessionToken: encryptedSessionToken
                        }
                    })
                    .then((response) => {
                        console.log("response.data");                        
                        console.log(response.data);                        
                        //store.dispatch('updateJobs', response.data)
                        resolve(response.data)
                        
                    },
                        (error) => {
                            console.log(error);
                            reject({ validToken: false });
                        });
            });
        },
}

//export function getInfo() {alert();}