<template>
  <div>
    <v-select
    :disabled="disabled" 
      v-if="answerAlternatives.length>0"
      v-model="dAnswer"
      :items="answerAlternatives"
      item-value="val"
      item-text="text"
      color="white"
    ></v-select>
    <v-radio-group v-else v-model="dAnswer" row>
      <v-radio
      :disabled="disabled" 
        color="primary"
        v-for="alternative in answerAlternatives"
        v-bind:key="alternative.val"
        :label="alternative.text"
        :value="alternative.val"
      ></v-radio>
    </v-radio-group>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      dAnswer: this.answer,
      dValidAnswer: false,
      typing: false,
      typingDone: false
    };
  },
  props: {
    disabled: {
      type:Boolean,
      default: false,
    },
    id: Number,
    title: String,
    tab: String,
    groupAnswers: Boolean,
    answer: Number,
    answerAlternatives: Array,
    questionText: String,
    dimmed: Boolean,
    visible: Boolean,
    introText: String,
    instructionText: String
  },
  watch: {
    dAnswer: {
      handler: function() {
        this.dValidAnswer = false;
        if (
          this.dAnswer != null &&
          this.dAnswer != false &&
          this.dAnswer != ""
        ) {
          this.dValidAnswer = true;
        }
        this.$emit("changeAnswer", {
          id: this.id,
          //tab: this.tab,
          answer: this.dAnswer,
          validAnswer: this.dValidAnswer
        });
      },
      deep: true
    }
  },
  methods: {}
};
</script>