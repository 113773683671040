<template>
  <v-col v-if="!showOnlyMissing || (showOnlyMissing && missingAnswer)" class="questions" cols="12" sm="6" lg="6">
    
    <v-card height="100%" class="ma-0 pa-4 rounded-card">
      <v-container>
        <v-row justify="space-between">
          <div>
            <v-icon v-if="!validAnswer" class="mr-2" color="accent">warning</v-icon>
            <div class="question-text" v-html="questionText"></div>
          </div>
          <div>
            <v-tooltip
              color="primary"
              v-if="questionText_extraInfo != null && questionText_extraInfo.length>0"
              top
            >
              <template v-slot:activator="{ on }">
                <v-icon class="align-self-flexend" color="primary" v-on="on">help</v-icon>
              </template>
              <span>{{questionText_extraInfo}}</span>
            </v-tooltip>
          </div>
        </v-row>
      </v-container>
      <slot name="question"></slot>
    </v-card>
  </v-col>
</template>

<script>
export default {
  props: {
    id: Number,
    title: String,
    tab: String,
    groupAnswers: Boolean,
    answer_text: String,
    answerAlternatives: Array,
    questionText: String,
    questionText_extraInfo: String,
    dimmed: Boolean,
    visible: Boolean,
    introText: String,
    instructionText: String,
    disabled: Boolean,
    validAnswer: Boolean,
    missingAnswer: Boolean,
    showOnlyMissing: Boolean,
  },
};
</script>
