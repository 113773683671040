module.exports = {
    items: [
        { id: 8, key:"dashboard", name: "Hem", icon: "speed", path: "/", notification:false},
        { id: 1, key:"profile", name: "Profil", icon: "person", path: "/p", notification:false},
        // { id: 2, name: "questions", icon: "question_answer", path: "/q", notification:false },
        { id: 3, key:"tests",name: "Tester", icon: "assignment", path: "/t", notification:false },
        { id: 4, key:"jobs",name: "Jobb", icon: "my_location", path: "/j", notification:false },
        // { id: 5, key:"alljobs",name: "Alla Jobb", icon: "my_location", path: "/alljobs", notification:false },
        { id: 6, key:"applications",name: "Ansökningar", icon: "mdi-sitemap", path: "/a" , notification:false},
        { id: 7, key:"settings",name: "Inställningar", icon: "mdi-tune", path: "/s", notification:false }
    ],
    mobile_items: [
        { id: 8, key:"dashboard", name: "Hem", icon: "speed", path: "/", notification:false},
        { id: 1, key:"profile",name: "Profil", icon: "person", path: "/p", notification:false },
        // { id: 2, key:"profile",name: "Frågor", icon: "question_answer", path: "/q" , notification:false},
        { id: 3, key:"tests",name: "Tester", icon: "assignment", path: "/t", notification:false },
        { id: 4, key:"jobs",name: "Jobb", icon: "my_location", path: "/j", notification:false },
        { id: 6, key:"applications",name: "Ansökningar", icon: "mdi-sitemap", path: "/a", notification:false },
    //  { id: 8, key:"profile",name: "Inställningar", icon: "mdi-tune", path: "/s", notification:false }
        { id: 99, key:"more",name: "Mer", icon: "menu", path: "/o", notification:false }
    ]
}