<template>
  <v-container fluid>
    <h1>Översikt</h1>
    <v-divider></v-divider>
   <!--  <v-row>
      <v-col cols="12" sm="4" md="4">
        <v-select
          class="my-2"
          :items="languages"
          v-model="lang"
          label="Language"
          item-value="code"
          item-text="text"
        ></v-select>
      </v-col>
    </v-row> -->
    <v-row>
      <v-col cols="12" md="8">
        <div v-for="route in routes" :key="route.id" class="support-link">
          <router-link :to="route.path">{{route.name}}</router-link>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import routes from "../routes";
import language from "@/mixins/language";

export default {
 // mixins: [language],
  data() {
    return {
      routes: routes.items,
    };
  }
};
</script>