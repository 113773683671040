 <template>
  <div class="mx-auto page-normal">
    <PageHeader :title="page.title" :divider="true"></PageHeader>
    <PageActions :alertBar="alertBar" :breadcrumbs="[]"></PageActions>
    <PageContent :loading="false">
      <v-snackbar
        v-model="showSnackbar"
        :timeout="snackbartimeout"
        :bottom="snackbarbottom"
        color="secondary"
        :multi-line="snackbarmultiline"
      >
        {{snackbarMessage}}
        <v-btn color="primary" text @click="showSnackbar = false">Stäng</v-btn>
      </v-snackbar>

      <v-row v-if="invitedToInterviews != null && invitedToInterviews.length > 0">
        <v-col v-for="item in invitedToInterviews" :key="item">
          <v-card class="flex-column d-flex" height="100%" :elevation="2" color="success" dark>
            <v-card-title>
              <v-toolbar-title>Inbjudan till intervju!</v-toolbar-title>

              <v-spacer></v-spacer>
            </v-card-title>

            <v-card-subtitle>Du har fått en inbjudan till tjänsten som {{item.title}}</v-card-subtitle>
            <v-card-actions class="justify-center">
              <v-btn @click="openJob(item)" class="text-none px-8" rounded color="primary">Svara</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <!--  <SimpleList
            :type="'prominent'"
            v-if="invitedToInterviews != null && invitedToInterviews.length > 0"
            :items="invitedToInterviews"
            :keyName="'job_guid'"
            :itemTitle="'title'"
            :itemAvatar="'company_logo'"
            :itemSubtitle="'company'"
            :itemSubtitle2="'description'"
            :itemActionText="'jobStatusText'"
            :itemActionAvatarColor="'jobStatusColor'"
            :title="'Inbjudan till intervju'"
            v-on:listItemClick="openJob"
      />-->
      <v-row v-if="upcomingInterviews != null && upcomingInterviews.length > 0">
        <v-col cols="12" md="12">
          <SimpleList
            
            :items="upcomingInterviews"
            :keyName="'job_guid'"
            :itemTitle="'title'"
            :itemAvatar="'company_logo'"
            :itemSubtitle="'company'"
            :itemSubtitle2="'description'"
            :itemActionText="'meetingStart'"
            :itemActionAvatarColor="'jobStatusColor'"
            :title="'Kommande intervjuer'"
            v-on:listItemClick="openJob"
          />
        </v-col>
      </v-row>
      <AlertBox v-if="showAlertBox" />
      <Spinner v-if="loading==true" v-bind:message="'loadingJobs'" />
            <ActionCard v-on:clicked="$router.push('/j')" :title="'Inga ansökningar'" :subtitle="'Gå till lediga jobb för att söka ditt första jobb'" :actionText="'Till matchade jobb'" v-else-if="jobs == null || jobs.length < 1" />
      <v-row v-else-if="this.$vuetify.breakpoint.smAndDown">
        <v-col>
          <v-card max-width="800">
            <v-toolbar flat>
              <v-toolbar-title>{{page.tableTitle}}</v-toolbar-title>

              <v-spacer></v-spacer>
              <!-- 
              <v-btn icon>
                <v-icon>mdi-magnify</v-icon>
              </v-btn>

              <v-btn icon>
                <v-icon>mdi-tune</v-icon>
              </v-btn>-->
            </v-toolbar>

            <v-list two-line>
              <v-list-item-group>
                <template v-for="(item, index) in jobs">
                  <v-list-item :key="item.job_guid" @click="openJob(item)">
                    <v-list-item-avatar tile>
                      <v-img max-width="40px" contain :src="item.company_logo"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.title"></v-list-item-title>
                      <v-list-item-subtitle
                        class="text--primary"
                        v-text="item.company + ' - ' + item.cityText"
                      ></v-list-item-subtitle>
                      <v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-list-item-action-text v-text="item.jobStatusText"></v-list-item-action-text>
                      <v-avatar :color="item.jobStatusColor" size="24"></v-avatar>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
      <div v-else>
        <v-row>
          <v-col cols="12">
            <v-card>
              <DataTableTitle
                :title="page.tableTitle"
                :itemLength="jobs.length"
                :searchLabel="'Sök'"
                v-model="jobs_search"
              />
              <v-data-table
                :mobile-breakpoint="500"
                class="clickable-rows-table"
                v-model="jobs_selected"
                :headers="headers"
                @click:row="openJob"
                :items="jobs"
                :search="jobs_search"
                :sort-by="['application_period_end']"
                :sort-desc="false"
              >
                <template v-slot:item.jobStatusText="{ item }">
                  <v-row>
                    <v-tooltip color="primary" v-model="item.tooltip" left nudge-bottom>
                      <template v-slot:activator="{ on }">
                        <div v-on="on">
                          <v-avatar :color="item.jobStatusColor" size="24"></v-avatar>
                        </div>
                      </template>
                      <span>{{ item.jobStatusText }}</span>
                    </v-tooltip>
                    <v-tooltip color="primary" v-model="item.applicationTooltip" right nudge-bottom>
                      <template v-slot:activator="{ on }">
                        <div v-on="on">
                          <v-avatar :color="item.applicationStatusColor" size="24"></v-avatar>
                        </div>
                      </template>
                      <span>{{ item.applicationStatusText }}</span>
                    </v-tooltip>
                  </v-row>
                </template>

                <template v-slot:item.image="{ item }">
                  <!-- <v-btn
                  @click.stop="openApplication(item)"
                  class="text-none"
                  rounded
                  dark
                  color="primary"
                  >Ansök</v-btn>-->
                  <!-- <v-icon large color="grey">star_outline</v-icon> -->
                  <v-list-item-avatar tile>
                    <v-img max-width="40px" contain :src="item.company_logo"></v-img>
                  </v-list-item-avatar>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </PageContent>
  </div>
</template>

<script>
import ouical from "../utils/addToCalendar.js";

import PageHeader from "../components/layout/PageHeader";
import PageActions from "../components/layout/PageActions";
import PageContent from "../components/layout/PageContent";

import store from "../store/store";
import ds from "../dataservice";
import { mapState, mapActions } from "vuex";
import Spinner from "../components/misc/Spinner";
import SimpleList from "../components/basic/SimpleList";
import AlertBox from "../components/misc/AlertBox";
import ActionDialog from "../components/actions/ActionDialog";
import DataTableTitle from "../components/tables/DataTableTitle";
import jobrequests from "../requests/jobs/requests";
import PageTitle from "../components/page/Title";
import AlertBar from "../components/basic/AlertBar";
import AlertBarMixin from "@/mixins/alertBar";
import basic from "@/mixins/basic";

export default {
  mixins: [AlertBarMixin, basic],
  components: {
    Spinner,
    AlertBox,
    ActionDialog,
    DataTableTitle,
    PageTitle,
    PageTitle,
    PageHeader,
    PageContent,
    PageActions,
    AlertBar,
    SimpleList,
  },
  data() {
    return {
      page: {
        key: "jobs",
        title: this.$t("views.applications.title"),
        tableTitle: "Alla ansökningar",
      },
      snackbarMessage: null,
      showSnackbar: false,
      snackbartop: true,
      snackbartimeout: 5000,
      snackbarbottom: true,
      snackbarmultiline: true,
      showAlertBox: false,
      loading: true,
      jobs: [],
      jobs_selected: [],
      jobs_search: "",
      googleLink: null,
    };
  },
  props: {},
  computed: mapState("applications", [
    "status",
    "headers",
    "upcomingInterviews",
    "invitedToInterviews",
  ]),
  created: function () {},
  beforeMount: function () {
    this.handleLoad(this.status);
  },
  watch: {
    status(newValue, oldValue) {
      this.handleLoad(newValue);
    },
  },
  methods: {
    ...mapActions("applications", ["get"]),
    handleLoad(newValue) {
      if (newValue === "success") {
        this.jobs = JSON.parse(
          JSON.stringify(store.state.applications.applications)
        );
        this.loading = false;
      } else if (newValue === "initialize") {
        this.getJobs();
      } else if (newValue === "failed") {
        this.loading = false;
        this.showAlertBox = true;
      }
    },
    getJobs: async function () {
      try {
        this.loading = true;
        await this.get();
        this.loading = false;
        this.showAlertBox = false;
      } catch (err) {
        console.log("failed to get jobs");
        console.log(err);
        this.showAlertBox = false;
      }
    },
    openJob(job) {
      console.log(job);
      this.$router.push({
        name: "Job",
        params: {
          job_guid: job.job_guid,
          application_guid: job.application_guid,
        },
      });
    },
  },
};
</script>
<style scope>
.dialog-action-btn.v-btn:not(.v-btn--round).v-size--default {
  min-width: 150px;
}
.theme--light.v-card > .v-card__text {
  color: black;
}
</style>