<template>
  <v-btn @click="$emit('clicked')" rounded :color="dColor" class="text-none" :text="dText">
    <slot></slot>
  </v-btn>
</template>
<script>
export default {
  components: {},
  data: () => {
    return {
        dColor: null,
        dText: null,
    };
  },
  props: {
    color: {
      type: String,
      default: "primary",
    },
    text: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: null,
    },
  },
  beforeMount: function () {
    switch (this.type) {
      case "continue":
        this.dColor = "secondary";
        this.dText = false;
        break;
      case "confirm":
        this.dColor = "primary";
        this.dText = false;
        break;
      case "cancel":
        this.dColor = "error";
        this.dText = true;
        break;
      case "error":
        this.dColor = "error";
        this.dText = false;
        break;

      default:
          this.dColor = this.color;
          this.dText = this.text;
    }
  },
};
</script>