
import requests from '../requests/requests'
import { getQuestions, saveQuestions } from '../requests/global'

export default {
    namespaced: true,
    state: () => ({
        questions: null,
        questionsMissingAnswers: false,
        status: 'initialize',
        language: null,
        sortOrder: {
            "questions": 2,
            "create": 3,
            "profile": 1
        }
    }),
    mutations: {
        questions(state, status) { state.questions = status },
        status(state, status) { state.status = status },
        language(state, status) { state.language = status },
        questionsMissingAnswers(state, status) { state.questionsMissingAnswers = status },
    },
    getters: {
        questions: state => JSON.parse(JSON.stringify(state.questions)),
    },
    actions: {

        async get(context, data) {
            try {
                if (data != null && data.loader) {
                    //context.dispatch("loader/show", null, { root: true })
                }
                console.log("Get questions");
                context.commit("status", "pending");
                let result = await getQuestions({ signup: false, params: { language: context.rootState.settings.language } });
                let qs = result.questions;
                var prepped = prepQuestions(context, qs);

                context.dispatch("categorize", qs);
                context.commit("status", "success");
                if (data != null && data.loader) {
                    //context.dispatch("loader/hide", null, { root: true })
                }
            }
            catch (e) {
                console.log(e)
                //context.dispatch("loader/hide", null, { root: true })
                context.dispatch("snackbar/error", "errorCouldNotGetProfileQuestions", { root: true })
            }
        },
        async save(context, questions) {
            try {
                //context.dispatch("loader/show", { text: "savingQuestions" }, { root: true })
                console.log("trying save")
                function combineArr(arr) {
                    var q = [];
                    for (var i = 0; i < arr.length; i++) {
                        q = q.concat(arr[i].questions)
                    }
                    return q
                }
                var qs_draft = combineArr(questions);
                var qs_changed = JSON.parse(JSON.stringify(qs_draft));
                var qs_current = combineArr(context.state.questions);

                for (var i = qs_changed.length - 1; i >= 0; i--) {
                    for (var k = 0; k < qs_current.length; k++) {
                        if (qs_changed[i].id == qs_current[k].id) {
                            if ((qs_changed[i].answer == null &&
                                qs_changed[i].answer_text == null &&
                                qs_changed[i].optional == false)
                                ||
                                (JSON.stringify(qs_changed[i].answer) == JSON.stringify(qs_current[k].answer) &&
                                    JSON.stringify(qs_changed[i].answer_text) == JSON.stringify(qs_current[k].answer_text))) {
                                qs_changed.splice(i, 1);
                                break;
                            }
                        }
                    }
                }
                if (qs_changed.length > 0) {
                    var response = await saveQuestions({ body: { questions: qs_changed } });
                    var prepped = prepQuestions(context, qs_draft);
                    context.dispatch("categorize", JSON.parse(JSON.stringify(qs_draft)));
                    context.dispatch("information/get", null, { root: true })
                }
                context.dispatch("snackbar/success", "savedQuestions", { root: true })
                //context.dispatch("loader/hide", null, { root: true })
                return response
            }
            catch (e) {
                context.dispatch("loader/hide", null, { root: true })
                context.dispatch("snackbar/error", "errorSavingQuestions", { root: true })
                console.log(e);
            }
        },
        categorize(context, data) {

            var qs = [];

            function inQs(tab) {
                for (var k = 0; k < qs.length; k++) {
                    //console.log(qs[k].tab)
                    if (qs[k].tab.toLowerCase() == tab.toLowerCase()) {
                        return k;
                    }
                }
                return -1
            }

            for (var i = 0; i < data.length; i++) {

                if (inQs(data[i].tab) < 0) {
                    //qs.push({ tab: data[i].tab.charAt(0).toUpperCase() + data[i].tab.slice(1), questions: [] });
                    qs.push({ tab: data[i].tab, questions: [] });
                }
                qs[inQs(data[i].tab)].questions.push(data[i]);
            }
            qs.forEach((element) => {
                element.sortKey = context.state.sortOrder[element.tab];
            });
            qs = qs.sort((a, b) =>
                a.sortKey > b.sortKey ? 1 : b.sortKey > a.sortKey ? -1 : 0
            );

            for (var k = 0; k < qs.length; k++) {
                console.log(qs[k]);
                console.log(qs[k].questions);
                console.log(qs[k].questions.length);
                qs[k].questionsMissingAnswers = false;
                for (var p = 0; p < qs[k].questions.length; p++) {
                    if (qs[k].questions[p].missingAnswer) {
                        qs[k].questionsMissingAnswers = true;
                        break;
                    }
                }
            }
            console.log(qs);
            context.commit('questions', qs);
            //console.log(context.state.questions)

        }
    }
}



function prepQuestions(context, qs) {
    context.commit("questionsMissingAnswers", false);
    var questionsMissingAnswers = false;
    for (var i = 0; i < qs.length; i++) {
        if (qs[i].answer != null || qs[i].answer_text != null) {
            qs[i].validAnswer = true;
            qs[i].missingAnswer = false;
        }
        else {
            qs[i].validAnswer = false;
            qs[i].missingAnswer = true;
            context.commit("questionsMissingAnswers", true);
        }
    }
    return { qs: qs, questionsMissingAnswers: questionsMissingAnswers };
}
