<template>
  <v-content v-bind:class="{ 'scalePage': scalePage}">
    <transition name="fade" @after-enter="scalePage=false;$router.push({ path: 'c' })">
      <!--     -->
      <svg v-if="scalePage" id="fader" />
    </transition>
    <!--  <h1 class="title-text main-title">Brite</h1> -->
    <v-container v-if="!scalePage" class="fill-height login-container" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="6" lg="4">
          <v-card class="elevation-12 page-not-found-card">
            <img class="white--text align-end page-not-found-img" src="../assets/404.gif" />

            <v-card-text>
              <p>
                Always look on the Brite side of life!
                Även om denna sida inte finns så kanske nedan länkar kan hjälpa dig på traven?
              </p>
            </v-card-text>
            <v-card-actions>
              <a href="https://brite.jobs/faq">
                <v-btn color="primary" class="confirm-action text-none">Vanliga frågor</v-btn>
              </a>
              <v-btn color="primary" class="confirm-action text-none" to="/">Till Brite</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-content>
</template>

<script>
import store from "../store/store";
import router from "../router";

export default {
  props: {
    source: String
  },
  data: () => ({
    drawer: null,
    scalePage: false,
    loading: false,
    imgFix: false
  }),
  created: function() {
    console.log("Created");
  },
  methods: {}
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
#fader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  pointer-events: none;
  background: #fafafa; /* 
    animation-duration: 5000ms;
    animation-timing-function: ease-in-out; */
}

.page-not-found-img {
  max-width: 100%;
  max-height: 80%;
  display: block;
  margin: auto;
}

.confirm-action {
  width: 130px;
}

.page-not-found-card .v-card__text {
  height: 90%;
}
.page-not-found-card {
  height: 100%;
}

.limit-height {
  height: 60%;
}

.theme--light.v-card > .v-card__text p {
  font-family: "Proxima Nova Bold";
  color:black;
}

.v-card__text p {
  font-family: "Proxima Nova Bold";
  color:black;
}

.scalePage {
  transform: scale(2);
  transition: 0.6s;
}
.v-application--wrap .v-content {
  margin-top: 0px;
  background: linear-gradient(
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8)
    ),
    url("~@/assets/login_img.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #fafafa;
  max-width: unset;
  height: 100%;
}

.v-card__actions {
justify-content: space-evenly;
  padding: 16px;
}

.title-text {
  position: absolute;
  left: 0px;
  right: 0px;
  display: block;
  text-align: center;
}

.main-title {
  top: 10vh;
}

.sub-title {
  bottom: 10vh;
}

.container.login-container {
  height: 100%;
  min-height: 100%;
  max-width: 100%;
  margin: 0px 0px 0px 0px;
}

.v-form .v-input__slot {
  box-shadow: none;
}

/* .v-card {
  opacity:0.8;
} */

.v-card h1 {
  margin-top: 24px;
  margin-bottom: 24px;
  display: block;
  width: 100%;
  text-align: center;
  color: black;
}
</style>