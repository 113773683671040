<template>
  <v-content class="fullscreen-bg" v-bind:class="{ scalePage: scalePage }">
    <transition
      name="fade"
      @after-enter="
        $router.push({ path: nextPath, query: { new: newUser } })
      "
    >
      <!--     -->
      <svg v-if="scalePage" id="fader" />
    </transition>
    <v-container v-if="!scalePage" class="fill-height login-container" fluid>
      <v-row>
        <v-col>
          <h1 class="title-text main-title my-1">
            <img
              class="brite-logo-white"
              src="https://storage.googleapis.com/brite-resources/logo/Brite_logo_white%404x.png"
            />
          </h1>
          <v-row justify="center">
            <v-col cols="12">
              <v-card class="elevation-12 mx-auto" width="100%" max-width="500">
                <v-card-text>
                  <h1 class="my-1">Logga in</h1>
                  <router-link :to="'signup'">
                    <p class="text-center my-2">Skapa konto</p>
                  </router-link>
                  <!--      <v-layout v-if="loading" class="mt-4" wrap align-content="center" justify="center">
                <v-flex xs3></v-flex>
                <v-flex class="text-center" xs6>
                  <span class="text-center">Hämtar login information</span>
                  <v-progress-linear color="#038c8c" indeterminate rounded height="6"></v-progress-linear>
                  <br />
                </v-flex>
                <v-flex xs3></v-flex>
            </v-layout>-->
                  <div class="buttonContainer">
                    <LoginButtons v-on:loggedIn="loggedIn" />
                  </div>
                  <v-form id="nativeLogin">
                    <v-text-field
                      @animationstart="checkAnimation"
                      v-model="username"
                      outlined
                      ref="username"
                      name="username"
                      type="text"
                      label="Användarnamn"
                      hide-details="auto"
                    ></v-text-field>
                    <v-text-field
                      class="mt-2"
                      @animationstart="checkAnimation"
                      v-model="password"
                      outlined
                      ref="password"
                      id="password"
                      label="Lösenord"
                      name="password"
                      type="password"
                      hide-details="auto"
                    ></v-text-field>
                  </v-form>
                  <v-alert
                    class="mt-2"
                    v-if="errorMessage != null"
                    type="error"
                    >{{ errorMessage }}</v-alert
                  >
                </v-card-text>
                <v-card-actions class="login-action-buttons">
                  <v-row class="ma-0 pa-0" justify="center">
                    <v-col cols="12" class="text-center ma-0 pa-0">
                      <v-btn
                        v-if="!loading"
                        form="nativeLogin"
                        color="primary"
                        type="submit"
                        class="confirm-action text-none"
                        rounded
                        @click="scale"
                        >Logga in</v-btn
                      >
                      <LoadingButton v-else />
                    </v-col>
                    <v-col class="ma-0 pa-0" cols="12">
                      <p class="text-center mb-0">
                        <router-link to="password-reset">
                          <small>Glömt lösen?</small>
                        </router-link>
                      </p>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
          <h3
            v-if="this.$vuetify.breakpoint.mdAndUp"
            class="white--text title-text sub-title"
          >
            The future is Brite!
          </h3>
        </v-col>
      </v-row>
    </v-container>
  </v-content>
</template>

<script>
import store from "../store/store";
import router from "../router";

import LoginButtons from "../components/misc/LoginButtons";
import LoadingButton from "../components/misc/LoadingButton";

export default {
  components: {
    LoginButtons,
    LoadingButton,
  },
  props: {
    source: String,
  },
  data: () => ({
    drawer: null,
    scalePage: false,
    loading: false,
    imgFix: false,
    nextPath: "p",
    username: "",
    password: "",
    errorMessage: null,
    newUser: null,
  }),
  created: function () {
    console.log("Created");
    if (this.$route.query.nextUrl != null) {
      this.nextPath = this.$route.query.nextUrl;
    }
  },
  methods: {
    checkAnimation(e) {
      if (e.animationName == "onAutoFillStart") {
        //this.autofilled = true;
      } else if (e.animationName == "onAutoFillCancel") {
        //this.autofilled = false;
      }
    },
    loggedIn: function (user) {
      console.log(user);
      if (user.loggedIn == true) {
        if (user.newUser == true) {
          this.newUser = true;
          this.nextPath = "signup";
        } else if (this.$route.query.nextUrl != null) {
          this.nextPath = this.$route.query.nextUrl;
        }

        this.scalePage = true;
      } else {
        this.errorMessage = "Kunde inte logga in. Försök igen.";
      }
    },
    scale: async function (e) {
      try {
        this.loading = true;
        e.preventDefault();
        await store.dispatch("user/login", {
          username: this.username,
          password: this.password,
        });
        this.loading = false;
        this.scalePage = true;
      } catch (err) {
        console.log(err);
        console.log(err.response);
        if (err.response.status == 401) {
          this.errorMessage = "Fel användarnamn eller lösenord";
        } else {
          this.errorMessage = "Det uppstod ett problem";
        }
        this.loading = false;
      }
      //router.push({ path: 'c' })Tusen tac
      /* var self = this;
      store
        .dispatch("nativeLogin", {
          username: this.username,
          password: this.password
        })
        .then(result => {
          console.log(result);
          this.loading = false;
          self.scalePage = true;
        })
        .catch(err => {
          console.log("Error: " + err);
          this.loading = false;
          this.errorMessage = err;
        }); */
    },
  },
};
</script>
<style >
:-webkit-autofill {
  animation-name: onAutoFillStart;
}
:not(:-webkit-autofill) {
  animation-name: onAutoFillCancel;
}
@keyframes onAutoFillStart {
  from {
  }
  to {
  }
}
@keyframes onAutoFillCancel {
  from {
  }
  to {
  }
}
</style>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
#fader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  pointer-events: none;
  background: #fafafa; /* 
    animation-duration: 5000ms;
    animation-timing-function: ease-in-out; */
}

.scalePage {
  transform: scale(2);
  transition: 0.6s;
}

.v-card__actions {
  justify-content: center;
  padding: 16px;
}

.title-text {
  left: 0px;
  right: 0px;
  display: block;
  text-align: center;
}

.main-title {
  top: 10vh;
}

.sub-title {
  bottom: 10vh;
}

.container.login-container {
  height: 100%;
  min-height: 100%;
  max-width: 100%;
  margin: 0px 0px 0px 0px;
}

.v-form .v-input__slot {
  box-shadow: none;
}

/* .v-card {
  opacity:0.8;
} */

.v-card h1 {
  margin-top: 24px;
  margin-bottom: 24px;
  display: block;
  width: 100%;
  text-align: center;
  color: black;
}

.login-container {
  display: flex;
  justify-content: space-evenly;
}
.login-button {
  height: 50px;
}
.login-button-facebook {
  height: 44px;
  margin-bottom: 3px;
}
</style>