

var { getTests } = require('../../requests/global')

const get = async (context, data) => {
    context.commit("status", "pending");

    var tests = await getTests({});
    console.log(tests)
    context.commit("linkDefaultTest", tests.testUrl);
    context.commit("userTestStatus", tests.testStatus);
    context.commit("testReportUrl", tests.testReportUrl);
    context.commit("status", "success");
    return;

}


export default {
    get: get

}