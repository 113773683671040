<template>
  <div>
    <v-row v-if="loginFailed">
      <v-col>
        <h3>Kunde inte logga in, försök igen</h3>
      </v-col>
    </v-row>
    <v-row
      v-if="!loading"
      justify="space-around"
      class="login-provider-buttons"
    >
      <v-btn
        @click="authenticate('google')"
        dark
        class="text-none login-provider-button google-signin-button"
      >
        <v-img
          max-width="32"
          src="@/assets/img/logo-login-providers/g-logo.png"
        />
        <span class="login-provider-button-text">Sign in with Google</span>
      </v-btn>
      <v-btn
        @click="authenticate('facebook')"
        dark
        class="text-none login-provider-button facebook-signin-button"
      >
        <v-img
          max-width="34"
          src="@/assets/img/logo-login-providers/f-logo.png"
        />
        <span class="login-provider-button-text">Sign in with Facebook</span>
      </v-btn>
    </v-row>
    <v-row v-else>
      <Spinner
        class="mb-6"
        :absoluteSpinner="false"
        :dark="true"
        v-bind:message="'oauthSigninLoading'"
      />
    </v-row>
  </div>
</template>
<script>
import Vue from "vue";
import VueAuthenticate from "vue-authenticate";
import Spinner from "./Spinner.vue";

import store from "@/store/store";

//console.log(process.env.NODE_ENV);
//var fbRedirectUri = window.location.origin;
var fbRedirectUri = window.location.origin + "/signup";
var googleRedirectUri = window.location.href;
googleRedirectUri = window.location.origin + "/signup";
var fbUrl = process.env.VUE_APP_JOBS_BASE_URL + "/auth/facebook/redirect";
var googleUrl = process.env.VUE_APP_JOBS_BASE_URL + "/auth/google/redirect";
//googleUrl = "http://localhost:3000/auth/google/redirect";
//fbUrl = "http://localhost:3000/auth/facebook/redirect";
if (process.env.NODE_ENV == "production") {
  //fbRedirectUri = 'https://at-dev-brite.web.app/l';
  fbRedirectUri = window.location.origin + "/signup";
  //googleRedirectUri = window.location.origin + "/signup";
  googleRedirectUri = window.location.origin + "/signup";
  //fbUrl =
  //  "https://us-central1-higher.cloudfunctions.net/rec/auth/facebook/redirect";
  // googleUrl =
  //   "https://us-central1-higher.cloudfunctions.net/brite-dev/auth/google/redirect";
}

Vue.use(VueAuthenticate, {
  bindRequestInterceptor: function () {
    // Vue authenticate - messes up lots of stuff, overiding defaults
  },
  bindResponseInterceptor: function () {
    // Vue authenticate - messes up lots of stuff, overiding defaults
  },
  baseUrl: "https://us-central1-higher.cloudfunctions.net", // Your API domain
  storageType: "memoryStorage",
  cookieStorage: {
    path: "/",
    secure: false,
  },

  providers: {
    facebook: {
      url: fbUrl,
      clientId: "438297253358759",
      redirectUri: fbRedirectUri, // Your client app URL
    },
    google: {
      clientId:
        "73309280856-qanjp9n5oq90iuk6khs45hrpm7bk6rrv.apps.googleusercontent.com",
      url: googleUrl,
      redirectUri: googleRedirectUri, // Your client app URL
      requiredUrlParams: ["scope"],
      optionalUrlParams: ["access_type", "display"],
      accessType: "offline",
      scope: ["email"],
      scopePrefix: "openid",
      scopeDelimiter: " ",
      display: "popup",
      oauthType: "2.0",
      popupOptions: { width: 452, height: 633 },
    },
    github: {
      clientId: "",
      redirectUri: "http://localhost:8080/auth/callback", // Your client app URL
    },
  },
});

export default {
  data: () => ({
    loading: false,
    loginFailed: false,
  }),
  components: {
    Spinner,
  },
  methods: {
    authenticate(provider) {
      var self = this;
      //self.loading = true;
      //self.imgFix = true;
      this.loading = true;
      this.$auth
        .authenticate(provider)
        .then(async function (result) {
          //self.loading = false;
          //self.imgFix = false;
          console.log("Login provider response");
          console.log(result.data.newUser);
          //console.log(result.data.isLoggedIn);
          if (result.data.isLoggedIn == true) {
            await store.dispatch("user/token", result.data.token);
            /* store.commit("user/isLoggedIn", true);
            store.commit("isLoggedIn", true);
            store.commit(
              "encryptedAccessToken",
              result.data.encryptedAccessToken
            );
            store.commit(
              "encryptedSessionToken",
              result.data.encryptedSessionToken
            );
            store.commit("newUser", result.data.newUser); */
            self.$emit("loggedIn", {
              loggedIn: true,
              newUser: result.data.newUser,
            });
            //self.scalePage = true;
            //router.push({ path: "c" });
            //self.scalePage = true;
          } else {
            //Sätt ett felmeddelande
            this.loading = false;
            this.loginFailed = true;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.loginFailed = true;
          //self.loading = false;
          console.log("Login failed");
          console.log(err);
        });
    },
  },
};
</script>
