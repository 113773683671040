import Vue from 'vue'


export default {
    newUser: function (userInfo) {
        console.log("running new user");
        return new Promise(resolve => {
            Vue.axios.post("http://localhost:8010/higher/us-central1/newUser", userInfo)
            //Vue.axios.post("https://us-central1-higher.cloudfunctions.net/newUser", userInfo)
                .then((response) => {
                    //console.log(response);
                    resolve(response.data);
                }, (error) => {
                    console.log(error.response);
                    resolve(error.response);
                });
        })
    },
    getQuestions: function (jobGuid='') {
        return new Promise((resolve, reject) => {
            //Vue.axios.get("https://us-central1-higher.cloudfunctions.net/getQuestions")
            //Vue.axios.get("http://localhost:8010/higher/us-central1/getQuestions")
            //Vue.axios.get("http://localhost:3000/signup/questions")
            Vue.axios.get("https://us-central1-higher.cloudfunctions.net/service/signup/questions/" + jobGuid)
                //Vue.axios.get("http://localhost:3000/signup/questions")
                .then((response) => {
                    //response.data.forEach((el) => { el.visible = false, el.dimmed = false, el.answer = null, el.revealed = false, el.validAnswer = false; }) // ES6
                    //console.log(response.data.value[0].joke);
                    resolve(response.data);
                }, (error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    getQuestionsId: function (jobGuid) {
        return new Promise((resolve, reject) => {
            //Vue.axios.get("https://us-central1-higher.cloudfunctions.net/getQuestions")
            //Vue.axios.get("http://localhost:8010/higher/us-central1/getQuestions")
            //Vue.axios.get("http://localhost:3000/signup/questions")
            Vue.axios.get("https://us-central1-higher.cloudfunctions.net/service/signup/questions/" + jobGuid, {
                //Vue.axios.get("http://localhost:3000/signup/questions/" + jobGuid, {
                //Vue.axios.get("http://localhost:3000/signup/questions_withid", {
                //Vue.axios.get("http://localhost:3000/ag/jobs", {
                params: {
                    jobGuid: jobGuid,
                }
            })
                .then((response) => {
                    response.data.forEach((el) => { el.visible = false, el.dimmed = false, el.answer = null, el.revealed = false, el.validAnswer = false; }) // ES6
                    //console.log(response.data.value[0].joke);
                    resolve(response.data);
                }, (error) => {
                    console.log(JSON.stringify(error.response.data));
                    reject(error.response.data);
                });
        });
    },
    saveQuestions: function (questions) {
        var encryptedAccessToken = $cookies.get("encrypted_access_token");
        var encryptedSessionToken = ($cookies.get("encrypted_session_token") != null) ? $cookies.get("encrypted_session_token") : '';
        if (encryptedAccessToken == null) { return; }
        return new Promise((resolve, reject) => {
            Vue.axios.post("https://us-central1-higher.cloudfunctions.net/service/signup/questions",
                //Vue.axios.post("http://localhost:3000/signup/questions",
                {
                    encryptedAccessToken: encryptedAccessToken,
                    encryptedSessionToken: encryptedSessionToken,
                    questions: questions
                })
                .then((response) => {
                    // console.log("What is the token?");
                    // console.log(response.data);
                    resolve(response.data)
                },
                    (error) => {
                        console.log(error);
                        reject(error);
                    });
        });
    },
}
