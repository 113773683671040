<template>
  <v-container fluid>
    <h1>Frågor</h1>
    <v-divider></v-divider>
    <Spinner v-if="profile_questions_status=='initialise'" v-bind:message="'Hämtar information'" />
    <div v-else>
      <v-row class="questions">
        <component
          v-for="question in questions"
          v-bind:key="question.id"
          v-on:changeAnswer="answersChanged"
          v-bind="question"
          v-bind:is="question.kind"
        ></component>
      </v-row>
      <v-btn
        @click="save"
        rounded
        dark
        color="primary"
        class="confirm-action float-right mb-10 text-none"
      >Uppdatera profil</v-btn>
      <v-btn
        rounded
        dark
        color="primary"
        class="confirm-action float-right mb-10 mr-10 text-none"
        @click="logout"
      >Logga ut</v-btn>
    </div>
  </v-container>
</template>
<script>
import store from "../store/store";
import requests from "../plugins/requests";
import QSingle from "../components/q/QSingle";
import QMulti from "../components/q/QMulti";
import QSlide from "../components/q/QSlide";
import QCities from "../components/q/QCities";
import QTextShort from "../components/q/QTextShort";
import QText from "../components/q/QText";
import QJobs from "../components/q/QJobs";
import Spinner from "../components/misc/Spinner";
import { mapState } from "vuex";

export default {
  components: {
    QSingle,
    QMulti,
    QSlide,
    QCities,
    QTextShort,
    QText,
    QJobs,
    Spinner
  },
  data() {
    return {
      questions: []
    };
  },
  computed: mapState(["profile_questions_status"]),
  beforeMount: function() {
    this.handleLoad(this.profile_questions_status);
  },
  watch: {
    profile_questions_status(newValue, oldValue) {
      this.handleLoad(newValue);
    }
  },
  methods: {
    handleLoad(value) {
      if (value == "success") {
        this.questions = store.state.questions;
      }
    },
    logout() {
      store.commit("logout");
    },
    answersChanged(data) {
      console.log("Anaswer changed right now");
      //console.log(data);
      var q = this.questions;
      for (var i = 0; i < q.length; i++) {
        if (q[i].id == data.id) {
          q[i].answer =
            data.answer != null && data.answer != undefined
              ? data.answer
              : null;
          q[i].answer_text = data.answer_text ? data.answer_text : null;
          q[i].validAnswer = data.validAnswer;
        }
      }
    },
    save() {
      requests.methods
        .saveQuestions(this.questions)
        .then(result => {
          console.log("saved answers to database");
          console.log(result);
        })
        .catch(err => {
          console.log("failed to save answers to database");
          console.log(err);
        });
    }
  }
};
</script>
<style>
.theme--light.v-subheader {
  color: rgba(0, 0, 0, 1);
}
</style>