<template>
  <v-container>
    <Spinner v-if="loading" v-bind:message="loadingMessage" />

    <slot v-else>
     <!--  <div>
        <Button @clicked="nextStep">Finish</Button>Fisr page content
        <br />Some page content
        <br />Some page content
        <br />Some page content
        <br />Some page content
        <br />Some page content
        <br />Some page content
        <br />Some page content
        <br />Some page content
        <br />Some page content
        <br />Some page content
        <br />Some page content
        <br />Some page content
        <br />Some page content
        <br />Some page content
        <br />Some page content
        <br />Some page content
        <br />Some page content
        <br />Some page content
        <br />Some page content
        <br />Some page content
        <br />Some page content
        <br />Some page content
        <br />Some page content
        <br />Some page content
        <br />Some page content
        <br />Some page content
        <br />Some page content
        <br />Some page content
        <br />Some page content
        <br />Some page content
        <br />Some page content
        <br />Some page content
        <br />Some page content
        <br />Some page content
        <br />Some page content
        <br />Some page content
        <br />Some page content
        <br />Some page content
        <br />Some page content
        <br />Some page content
        <br />Some page content
        <br />Blä Some page content
        <br />
      </div> -->
    </slot>
  </v-container>
</template>
<script>
import Button from "../basic/Button";
import Spinner from "../misc/Spinner";

export default {
  components: {
    Button,
    Spinner,
  },
  data: () => {
    return {};
  },
  methods: {
    nextStep: function () {
      console.log("vlicked");
      this.$emit("confirm", {});
    },
  },
  props: {
    loading: Boolean,
    loadingMessage: {
      type: String,
      default: 'loading'
    },
    fluid: {
      type: Boolean,
      default: true,
    },
  },
};
</script>