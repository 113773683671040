<template>
  <transition name="fade" mode="out-in" @after-enter="startAnimations">
     
    <div v-if="showChatbox" class="text-xs-left mb-4">
      <span class="brite-bot">Brite Bot</span>
      <transition name="fade" mode="out-in" @after-enter="handleChatboxes">
        <div v-if="showIntroText">
          <span class="mess" :class="tab">
            <span v-html="introText"></span>
          </span>
        </div>
      </transition>
      <transition name="fade" mode="out-in" @after-enter="handleChatboxes">
        <div v-if="showQuestionText">
          <span class="mess" :class="tab">
            <span v-html="dQuestionText"></span>
          </span>
        </div>
      </transition>
      <transition name="fade" mode="out-in" @after-enter="handleChatboxes">
        <div v-if="showInstructionText">
          <span class="mess" :class="tab">
            <span v-html="instructionText"></span>
          </span>
        </div>
      </transition>
      <transition
        name="fade"
        mode="out-in"
        @after-enter="handleChatboxes(true)"
        @after-leave="handleChatboxes(false)"
      >
        <span v-if="typing" class="mess" :class="tab">
          <div class="wave">
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
          </div>
        </span>
      </transition>
      <!--      <transition name="fade">
        <span v-if="showSecondMessage" class="mess" :class="tab">
          <span v-html="dQuestionText"></span>
        </span>
      </transition>-->
    </div>
  </transition>
</template>
<script>
import { setTimeout } from "timers";
export default {
  data() {
    return {
      tempo: "bu",
      typing: true,
      display: true,
      showChatbox: false,
      dQuestionText: this.questionText,
      showIntroText: false,
      showQuestionText: false,
      showInstructionText: false,
      showSecondMessage: false
    };
  },
  props: {
    questionText: String,
    introText: String,
    instructionText: String,
    tab: String,
    secondMessage: String
  },
  created: function() {},
  mounted: function() {
    this.showChatbox = true;
  },
  methods: {
    startAnimations() {
      this.typing = false;
    },
    handleChatboxes(enter) {
      if (enter == true) {
        this.typing = false;
        return;
      }
      if (enter == false) {
        if (
          this.introText != "" &&
          this.introText != null &&
          this.showIntroText == false
        ) {
          this.showIntroText = true;
          this.typing = false;
        } else if (
          this.questionText != "" &&
          this.questionText != null &&
          this.showQuestionText == false
        ) {
          this.showQuestionText = true;
          this.typing = false;
        } else if (
          this.instructionText != "" &&
          this.instructionText != null &&
          this.showInstructionText == false
        ) {
          this.showInstructionText = true;
          this.typing = false;
        } else {
          this.typing = false;
          this.typingDone();
        }
        return;
      }

      if (
        this.typing == false &&
        ((this.introText != "" &&
          this.introText != null &&
          this.showIntroText == false) ||
          (this.questionText != "" &&
            this.questionText != null &&
            this.showQuestionText == false) ||
          (this.instructionText != "" &&
            this.instructionText != null &&
            this.showInstructionText == false))
      ) {
        this.typing = true;
        console.log("type true");
      } else {
        this.typing = false;
        this.typingDone();
      }
    },
    typingDone() {
      this.$emit("typingDone", { typing: this.typing });
    }
  },
  watch: {
    typing: function() {
      if (this.typing == false) {
        this.dQuestionText = this.questionText;
        //this.$emit("typingDone", { typing: this.typing });
      }
    }
  }
};
</script>
<style scoped>
.fade-enter-active {
  transition: opacity 1s;
}
.fade-enter/* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.fade-leave-active {
  transition: none;
}
.fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  display: none;
}

.fadespec-enter-active {
  transition: opacity 0s;
}
.fadespec-enter/* .fade-leave-active below version 2.1.8 */ {
  opacity: 1;
}

.fadespec-leave-active {
  transition: 0s;
}
.fadespec-leave-to /* .fade-leave-active below version 2.1.8 */ {
  display: none;
}

.messfix {
  visibility: hidden;
}

div.wave {
  position: relative;

  text-align: center;

  margin-left: auto;
  margin-right: auto;
}
div.wave .dot {
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin-right: 3px;
  background: white;
  animation: wave 1.3s linear infinite;
}
div.wave .dot:nth-child(2) {
  animation-delay: -1.1s;
}
div.wave .dot:nth-child(3) {
  animation-delay: -0.9s;
}

@keyframes wave {
  0%,
  60%,
  100% {
    transform: initial;
  }
  30% {
    transform: translateY(-4px);
  }
}
</style>