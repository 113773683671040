

var { getApplications, postApplication, deleteApplication, putInterview } = require('../../requests/global')
const ouical = require("../../utils/addToCalendar.js")

// Applications fetches all applications + job that are a match but we have not yet applied for
const get = async (context, data) => {
    context.commit('status', "pending");
    if(data != null && data.job_guid != null) {
        var jobs = await getApplications({ params: { match: true, job_guid: data.job_guid }, ep: false })
    }
    else {
        var jobs = await getApplications({ params: { match: true }, ep: false })
    }
    context.dispatch("loadJobs", { jobs: jobs });
}

const reset = (context, data) => {
    context.commit('jobs', null);
    context.commit('status', 'initialize');
}

const loadJobs = async (context, data) => {

    var jobs = data.jobs;
    context.commit('status', "pending");
    console.log("got jobs")
    jobs = setStatusLabelsAndColor(jobs, context);
    //console.log("labelled jobs")
    var applications = jobs.filter(function (item) { return item.hasOwnProperty('application_status') });
    var matchedJobs = jobs.filter(function (item) { return item.matched == true; });
    var now = new Date().setHours(0,0,0,0);
    var upcomingInterviews = applications.filter(function (item) { 
        if(item.hasOwnProperty('interviews') == true) {
            var interviewDate = new Date(item.interviews[0].start).setHours(0,0,0,0);
            return item.application_status == "BOOKED" && interviewDate >= now
        }
    });
    var invitedToInterviews = applications.filter(function (item) { return item.application_status == "INVITED" });

    try {
        if (upcomingInterviews.length > 0) {
            upcomingInterviews.forEach((interview) => {
                interview.meetingStart = interview.interviews[0].start;
                interview.addToCalendar = createAddToCalendarItems(interview);
                interview.meetingEmployerComment = interview.interviews[0].start;
            })
        }
    } catch { }

    applications = applications.reverse();
    context.commit("applications", applications);
    context.commit("upcomingInterviews", upcomingInterviews);
    context.commit("invitedToInterviews", invitedToInterviews);
    context.commit("matchedJobs", matchedJobs);

    context.commit('jobs', jobs);
    context.commit('status', "success");
    //console.log("commited appl status success")
}

const updateApplication = async (context, data) => {
    context.commit('status', "pending");
    var jobs = await postApplication({ body: { job_guid: data.job_guid } });
    context.dispatch("reset", null);
    context.dispatch("get", null);
}


const removeApplication = async (context, data) => {
    context.commit('status', "pending");
    await deleteApplication({ id: data.application_guid });
    var jobs = context.state.jobs;
    jobs.forEach(job => {
        if (job.application_guid == data.application_guid) {
            delete job.application_status;
            //console.log(job)
        }
    })
    context.dispatch("reset", null);
    context.dispatch("get", null);
}

const sendInterview = async (context, data) => {
    await putInterview({
        body: {
            status: "ACCEPT",
            application_guid: data.application_guid,
            interview_guid: data.interview_guid,
            interview_info: data.interview_info
        }
    })
    context.dispatch("reset", null);
    context.dispatch("get", null);

}


function setStatusLabelsAndColor(jobs, context) {
    jobs.forEach(element => {
        element.jobStatusText = (context.state.jobStatusDefinition[element.status]) ? context.state.jobStatusDefinition[element.status].statusText : null;
        element.jobStatusColor = (context.state.jobStatusDefinition[element.status]) ? context.state.jobStatusDefinition[element.status].statusColor : null;
        if (element.hasOwnProperty("application_status")) {
            element.applicationStatusText = (context.state.applicantsStatusDefinition[element.application_status]) ? context.state.applicantsStatusDefinition[element.application_status].statusText : null;
            element.applicationStatusColor = (context.state.applicantsStatusDefinition[element.application_status]) ? context.state.applicantsStatusDefinition[element.application_status].statusColor : null;
        }
        if (element.hasOwnProperty("interviews")) {
            element.interviews.forEach(interview => {
                if (interview.hasOwnProperty("start") && interview.start != null) {
                    //console.log("interview loop")
                    interview.expanded = false;
                    interview.meetingTime = interview.start + " - " + interview.end.substring(interview.end.length - 5, interview.end.length)
                    //console.log(JSON.parse(JSON.stringify(interview)));
                    interview.addToCalendar = createAddToCalendarItems(element, interview);

                }
                interview.statusText = (context.state.interviewStatusDefinition[interview.interview_status]) ? context.state.interviewStatusDefinition[interview.interview_status].statusText : null;
                interview.statusColor = (context.state.interviewStatusDefinition[interview.interview_status]) ? context.state.interviewStatusDefinition[interview.interview_status].statusColor : null;
            })

        }
        var cityText = '';
        try {
            element.city.forEach(city => {
                cityText += city.name + ", ";
            })
            element.cityText = cityText.slice(0, -2);
        }
        catch (e) {
            
        }
    })
    //console.log(jobs)
    return jobs
}

function createAddToCalendarItems(item, event) {
    //console.log("item calendar");
    //console.log(item);
    //console.log("ouical");
    //console.log(ouical);
    var ouicalData = ouical.addToCalendarData({
        data: {
            // Event title
            title: "Intervju: " + item.title,

            // Event start date
            start: new Date(event.start.replace(/-/g, '/')),
            end: new Date(event.end.replace(/-/g, '/')),
            //start: new Date("2020/03/04 13:00"),
            //end: new Date("2020/03/04 14:00"),
        },
    });
    //console.log(ouicalData);
    return ouicalData;
}


export default {
    get: get,
    reset: reset,
    updateApplication: updateApplication,
    loadJobs: loadJobs,
    removeApplication: removeApplication,
    sendInterview: sendInterview
}