<template>
  <v-btn :color="color" rounded>
    <v-progress-circular indeterminate="indeterminate"  size="26" width="3" color="white"></v-progress-circular>
  </v-btn>
</template>
<script>
export default {
  props: {
    color: {
      type:String,
      default: 'primary',
    }
  }
};
</script>