module.exports = {
    specific_job: state => function (id) {
        if (state.jobs == null) {
            return null;
        }
        return state.jobs.filter(function (itm) {
            return itm.link_id == id;
        });
    },
    filteredList: state => function (list, attr, answer) {
        return list.filter(function (itm) {
            // console.log(itm[attr]);
            // console.log(answer.includes(itm[attr]));
            // console.log(list);
            return answer.includes(itm[attr]) == true;
        });
    },
}