 <template>
  <v-container fluid>
    <PageTitle :title="pageTitle" />
    <v-snackbar
      v-model="showSnackbar"
      :timeout="snackbartimeout"
      :bottom="snackbarbottom"
      color="secondary"
      :multi-line="snackbarmultiline"
    >
      {{snackbarMessage}}
      <v-btn color="primary" flat @click="showSnackbar = false">Stäng</v-btn>
    </v-snackbar>
    <AlertBox v-if="showAlertBox" />
    <Spinner v-if="loading==true" v-bind:message="'Hämtar ansökningar'" />
    <div v-else>
      <v-row>
        <v-col cols="12">
          <v-card>
            <DataTableTitle
              :title="JobsTableTitle"
              :itemLength="jobs.length"
              :searchLabel="'Sök'"
              v-model="jobs_search"
            />
            <v-data-table
              :mobile-breakpoint="500"
              class="clickable-rows-table"
              v-model="jobs_selected"
              :headers="headers"
              @click:row="openJob"
              :items="jobs"
              :search="jobs_search"
              :sort-by="['application_period_end']"
              :sort-desc="false"
            >
              <template v-slot:item.actions="{ item }">
                <v-btn
                  @click.stop="openApplication(item)"
                  class="text-none"
                  rounded
                  dark
                  color="primary"
                >Ansök</v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <ActionDialog
        v-model="loading"
        title="Följ ansökan"
        v-bind:accept="'Godkänn'"
        v-bind:cancel="'Avbryt'"
      >
        {{loading}}
        <template v-slot:actions>
          <v-row justify="space-around" class="mx-0">
            <v-btn class="dialog-action-btn text-none" rounded text color="error">Avbryt</v-btn>
            <v-btn class="dialog-action-btn text-none" rounded dark color="primary">Skicka</v-btn>
          </v-row>
        </template>
      </ActionDialog>
      <v-dialog v-model="loading" width="600">
        <v-card>
          <v-toolbar>
            <v-toolbar-title>Discover</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon>
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-title>
            <v-layout>
              <v-flex xs10>
                <h4>Följ ansökan</h4>
              </v-flex>
              <v-flex xs2 class="text-right">
                <v-icon large color="black" @click="dialog=false">close</v-icon>
              </v-flex>
            </v-layout>
          </v-card-title>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog" width="600" class="mx-auto">
        <v-card>
          <v-card-title>
            <v-layout>
              <v-flex xs10>
                <h4>Följ ansökan</h4>
              </v-flex>
              <v-flex xs2 class="text-right">
                <v-icon large color="black" @click="dialog=false">close</v-icon>
              </v-flex>
            </v-layout>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col xs6>
                {{jobInfo.title}}
                <br />
                {{jobInfo.company}}, {{jobInfo.city_name}}
              </v-col>
              <v-col xs6 class="text-right">
                Sista ansökningsdag: {{jobInfo.application_period_end}}
                <br />Din ansökningsdag: Sparas ej just nu
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text>
            <div class="dialog icon-wrap">
              <span class="stageIcon" v-bind:class="{ 'active':stage==1, 'inactive':stage!=1}">
                <img class="stageIconImg" src="../assets/img/Ikonbibliotek/Forskningsbaserat.svg" />
              </span>
              <span class="stageIcon" v-bind:class="{ 'active':stage==2, 'inactive':stage!=2}">
                <img
                  class="stageIconImg"
                  src="../assets/img/Ikonbibliotek/Artificiell intelligens.svg"
                />
              </span>
              <span class="stageIcon" v-bind:class="{ 'active':stage==3, 'inactive':stage!=3}">
                <img class="stageIconImg" src="../assets/img/Ikonbibliotek/Intervju.svg" />
              </span>
              <span class="stageIcon" v-bind:class="{ 'active':stage==4, 'inactive':stage!=4}">
                <img class="stageIconImg" src="../assets/img/Ikonbibliotek/Handskakning.svg" />
              </span>
            </div>
          </v-card-text>
          <v-card-text>
            <v-row>
              <v-col v-if="stage==1">
                <h4>1.Annons publicerad</h4>
                <p>Massa lorem ipsum place holdertext</p>
              </v-col>
              <v-col v-if="stage==2">
                <h4>2.Brite Bot utvärderar</h4>
                <p>Massa lorem ipsum place holdertext</p>
              </v-col>
              <v-col v-if="stage==3">
                <h4>3.Slutintervjuer pågår</h4>
                <p>Massa lorem ipsum place holdertext</p>
              </v-col>
              <v-col v-if="stage==4">
                <h4>4.Rekrytering avslutad</h4>
                <p>Massa lorem ipsum place holdertext</p>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-layout v-if="jobInfo.user_application_status == 'Applied'">
              <v-dialog v-model="abortDialog" width="600" class="mx-auto">
                <v-card>
                  <v-card-title>
                    <v-layout>
                      <v-flex xs10>
                        <h4>Avbryt ansökan</h4>
                      </v-flex>
                      <v-flex xs2 class="text-right">
                        <v-icon large color="black" @click="abortDialog=false">close</v-icon>
                      </v-flex>
                    </v-layout>
                  </v-card-title>
                  <v-card-text>
                    Vill du verkligen avbryta ansökan? Du kan alltid söka igen innan ansökningsperioden är slut.
                    <v-checkbox
                      xs12
                      v-model="confirmCancel"
                      color="primary"
                      label="Jag vill avbryta ansökan"
                    />
                  </v-card-text>
                  <v-card-actions>
                    <v-layout justify-center wrap>
                      <v-btn
                        @click="cancelApplication(jobInfo.link_id)"
                        :disabled="!confirmCancel"
                        class="dialog-action-btn text-none"
                        rounded
                        color="error"
                      >Avbryt ansökan</v-btn>
                    </v-layout>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-layout justify-center>
                <v-btn
                  @click="dialog=false"
                  class="dialog-action-btn text-none"
                  rounded
                  dark
                  color="primary"
                >Stäng</v-btn>
                <v-btn
                  @click="abortDialog=true"
                  class="dialog-action-btn text-none"
                  rounded
                  dark
                  color="error"
                >Avbryt ansökan</v-btn>
              </v-layout>
            </v-layout>
            <v-layout
              v-else-if="jobInfo.user_application_status != 'Applied' && jobInfo.status == 'Open'"
            >
              <v-dialog v-model="applyDialog" width="600" class="mx-auto">
                <v-card>
                  <v-card-title>
                    <v-layout>
                      <v-flex xs10>
                        <h4>Sök jobbet</h4>
                      </v-flex>
                      <v-flex xs2 class="text-right">
                        <v-icon large color="black" @click="applyDialog=false">close</v-icon>
                      </v-flex>
                    </v-layout>
                  </v-card-title>
                  <v-card-text>
                    Vill du söka detta jobb?
                    <v-checkbox
                      xs12
                      v-model="confirmApply"
                      color="primary"
                      label="Jag vill söka detta jobb"
                    />
                  </v-card-text>
                  <v-card-actions>
                    <v-row justify-space-around wrap>
                      <v-btn
                        @click="applyApplication(jobInfo.link_id)"
                        :disabled="!confirmApply"
                        class="dialog-action-btn text-none"
                        rounded
                        color="error"
                      >Skicka in ansökan</v-btn>
                      <v-btn
                        @click="dialog=false"
                        class="dialog-action-btn text-none"
                        rounded
                        dark
                        color="primary"
                      >Stäng</v-btn>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-row justify="end">
                <v-btn
                  @click="dialog=false"
                  class="dialog-action-btn text-none"
                  rounded
                  text
                  dark
                  color="primary"
                >Stäng</v-btn>
                <v-btn
                  @click="applyDialog=true"
                  class="dialog-action-btn text-none"
                  rounded
                  dark
                  color="error"
                >Sök jobbet</v-btn>
              </v-row>
            </v-layout>
            <v-layout v-else>
              <v-layout justify-center>
                <v-btn
                  @click="dialog=false"
                  class="dialog-action-btn text-none"
                  rounded
                  dark
                  color="primary"
                >Stäng</v-btn>
              </v-layout>
            </v-layout>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import store from "../store/store";
import ds from "../dataservice";
import { mapState } from "vuex";
import Spinner from "../components/misc/Spinner";
import AlertBox from "../components/misc/AlertBox";
import ActionDialog from "../components/actions/ActionDialog";
import DataTableTitle from "../components/tables/DataTableTitle";
import jobrequests from "../requests/jobs/requests";
import requests from "../plugins/requests";
import PageTitle from "../components/page/Title";

export default {
  components: {
    Spinner,
    AlertBox,
    ActionDialog,
    DataTableTitle,
    PageTitle
  },
  data() {
    return {
      pageTitle: "[DEV] Alla lediga tjänster - även tjänster du inte matchar",
      JobsTableTitle: "Alla lediga tjänster",
      testString: "not empy",
      snackbarMessage: null,
      showSnackbar: false,
      snackbartop: true,
      snackbartimeout: 5000,
      snackbarbottom: true,
      snackbarmultiline: true,
      confirmCancel: false,
      confirmApply: false,
      showAlertBox: false,
      dialog: false,
      abortDialog: false,
      applyDialog: false,
      stage: 2,
      loading: true,
      jobInfo: {},
      jobs: [],
      jobs_selected: [],
      jobs_search: "",
      headers: [
        {
          text: "Tjänst",
          align: "left",
          sortable: true,
          value: "title"
        },
        { text: "Företag", value: "company" },
        { text: "Ort", value: "city_name" },
        { text: "Avslutas", value: "application_period_end" },
        { text: "", value: "actions" }
      ]
    };
  },
  props: {},
  computed: mapState(["all_jobs_status"]),
  created: function() {
    //console.log(dataservice.methods.get_process_items());
    //console.log(dataservice.methods.get_process_items());
  },
  beforeMount: function() {
    this.handleLoad(this.all_jobs_status);
  },
  watch: {
    all_jobs_status(newValue, oldValue) {
      this.handleLoad(newValue);
    }
  },
  methods: {
    handleLoad(newValue) {
      console.log("What to do?" + newValue)
      if (newValue === "success") {
        this.loading = false;
        this.jobs = JSON.parse(JSON.stringify(store.state.all_jobs));
      console.log("Setting job")
      } else if (newValue === "initialise") {
        this.getJobs();
      console.log("Request job")
      } else if (newValue === "failed") {
        this.loading = false;
      console.log("fail")
        this.showAlertBox = true;
      }
    },
    getJobs() {
      jobrequests
        .getAllJobs()
        .then(result => {
          this.loading = false;
          this.showAlertBox = false;
        })
        .catch(err => {
          console.log("failed to apply job application");
          console.log(err);
          this.showAlertBox = false;
        });
    },
    applyApplication() {
      requests.methods
        .applyApplication(this.jobInfo.link_id)
        .then(result => {
          console.log("Applied job application");
          console.log(result);
          this.abortDialog = false;
          this.dialog = false;
          this.snackbarMessage = "Din ansökan är mottagen!";
          this.showSnackbar = true;
          store.commit("applications_status", "initialise");
          ds.checkStore();
        })
        .catch(err => {
          console.log("failed to apply job application");
          console.log(err);
        });
    },
    openJob(job) {
      this.$router.push({ name: "Job", params: { id: job.link_id } });
    },
    openApplication(job) {
      this.jobInfo = job;
      this.dialog = true;
      // this.$router.push({ name: "Job", params: { id: job.link_id } });
    }
  }
};
</script>
<style scope>
.dialog-action-btn.v-btn:not(.v-btn--round).v-size--default {
  min-width: 150px;
}
.theme--light.v-card > .v-card__text {
  color: black;
}
</style>