<template>
     <v-menu
        transition="slide-y-transition"
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="white" v-bind="attrs" v-on="on" class="text-none black--text mr-2">
            <v-icon>add</v-icon>
            Din kalender
          </v-btn>
        </template>
        <v-list>
            <v-list-item
            v-for="(value, propertyName) in items"
            :key="value"
            v-html="value"
          > 

          </v-list-item>
        </v-list>
      </v-menu>
</template>
<script>
export default {
    props: {
        items: Object,
    }
}
</script>