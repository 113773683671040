<template>
    <v-textarea :disabled="disabled" :rows="2" v-model="dAnswer" placeholder="Skriv..."></v-textarea>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      dAnswer: this.answer_text,
      dValidAnswer: false,
      typing: false,
      typingDone: false
    };
  },
  props: {
    disabled: {
      type:Boolean,
      default: false,
    },
    id: Number,
    title: String,
    tab: String,
    groupAnswers: Boolean,
    answer_text: String,
    answerAlternatives: Array,
    questionText: String,
    dimmed: Boolean,
    visible: Boolean,
    introText: String,
    instructionText: String
  },
  mounted: function() {
    //console.log("Answer is: " + this.answer)
    //this.dAnswer = JSON.parse(this.answer);
  },
  watch: {
    dAnswer: {
      handler: function() {
        this.dValidAnswer = false;
        if (
          this.dAnswer != null &&
          this.dAnswer != false &&
          this.dAnswer != ""
        ) {
          this.dValidAnswer = true;
        }
        this.$emit("changeAnswer", {
          id: this.id,
          //tab: this.tab,
          answer_text: this.dAnswer,
          validAnswer: this.dValidAnswer
        });
      },
      deep: true
    }
  },
  methods: {}
};
</script>