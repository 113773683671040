<template>
  <div
    :id="tab + id"
    v-if="visible"
    class="clear"
    v-bind:class="{ dim: dimmed == true }"
  >
    <chatbox
      :questionText="questionText"
      :introText="introText"
      :instructionText="instructionText"
      :tab="tab"
      :typing="typing"
      v-on:typingDone="showAlternatives"
    />
    <transition name="fade">
      <div v-if="typingDone" class="text-right">
        <v-layout wrap class="justify-end">
          <v-flex xs12 v-bind:class="{ groupAnswers: groupAnswers }">
            <ul>
              <li v-for="alt in answerAlternatives" :key="alt.val">
                <input
                  v-model="dAnswer"
                  type="radio"
                  :value="alt.val"
                  :id="id + alt.text"
                />
                <label :for="id + alt.text" @click.stop>
                  <v-chip :outlined="dAnswer != alt.val" color="secondary">{{
                    alt.text
                  }}</v-chip>
                </label>
              </li>
            </ul>
          </v-flex>
          <v-flex class="text-right" xs12 md6 v-if="dAnswer == 1">
            <v-subheader> Ange referensnummer (4d på körkortet)</v-subheader>
            <v-text-field
              v-model="dAnswerText"
              placeholder="Referensnummer"
              solo
            ></v-text-field>
          </v-flex>
        </v-layout>
        <br />
        <v-chip
          v-if="showOKbutton && !confirmedOnce"
          @click="confirmQuestion"
          color="primary"
          >{{ OKText }}</v-chip
        >
      </div>
    </transition>
  </div>
</template>
<script>
import chatbox from "./misc/chatbox";

export default {
  components: {
    chatbox,
  },
  data() {
    return {
      dAnswer: this.answer,
      dAnswerText: this.answer_text,
      dValidAnswer: false,
      showOKbutton: this.optional ? true : false,
      confirmedOnce: false,
      OKText: this.atbotOKText || "OK",
      typing: false,
      typingDone: false,
    };
  },
  props: {
    id: Number,
    title: String,
    tab: String,
    groupAnswers: Boolean,
    answer: Number,
    answer_text: String,
    answerAlternatives: Array,
    questionText: String,
    dimmed: Boolean,
    visible: Boolean,
    introText: String,
    instructionText: String,
  },
  watch: {
    dAnswer: {
      handler: function () {
        this.checkAnswer();
      },
      deep: true,
    },
    dAnswerText: {
      handler: function () {
        this.checkAnswer();
      },
      deep: true,
    },
  },
  methods: {
    checkAnswer: function () {
      this.dValidAnswer = false;
      if (
        ((this.dAnswer == 1 &&
          this.dAnswerText != null &&
          this.dAnswerText.length > 0) ||
          this.dAnswer == 2) &&
        this.dAnswer != false &&
        this.dAnswer != ""
      ) {
        this.dValidAnswer = true;
        this.showOKbutton = true;
      }

      if (this.confirmedOnce == true || this.dAnswer == 2) {
        this.confirmQuestion();
      }
    },
    showAlternatives() {
      this.typingDone = true;
    },
    confirmQuestion() {
      this.confirmedOnce = true;
      this.$emit("changeAnswer", {
        id: this.id,
        //tab: this.tab,
        answer_text: this.dAnswerText,
        answer: this.dAnswer,
        validAnswer: this.dValidAnswer,
      });
      this.$emit("clicked", { event: event, id: this.id, tab: this.tab });
    },
  },
};
</script>