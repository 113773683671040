import applications from './applications/requests'
import misc from './misc/requests'
import user from './user/requests'
import jobs from './jobs/requests'
import questions from './questions/requests'
import settings from './settings/requests'

export default {
    jobs: {
        get: {
            all: jobs.getJobs,
            specific: jobs.getSpecificJob,
        }

    },
    applications: {
        post: {
            cancel: applications.cancel,
            apply: applications.apply,

        },
        get: applications.getApplications,
        getInterviews: applications.getInterviews,
    },
    misc: {
        places: misc.getPlaces,
        suggestJob: misc.suggestJob

    },
    user: {
        login: user.login,
        validate: user.validate,
    },
    settings: {
        get: settings.get,
        set: settings.set

    },
    questions: {
            get: questions.getQuestions,
            save: questions.saveQuestions

    }
}
