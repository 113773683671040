 <template>
  <div class="mx-auto page-normal">
    <PageHeader :title="page.title" :divider="true"></PageHeader>
    <PageActions :alertBar="alertBar" :breadcrumbs="[]"></PageActions>
    <PageContent :loading="false">
      <v-snackbar
        v-model="showSnackbar"
        :timeout="snackbartimeout"
        :bottom="snackbarbottom"
        color="secondary"
        :multi-line="snackbarmultiline"
      >
        {{snackbarMessage}}
        <v-btn color="primary" text @click="showSnackbar = false">Stäng</v-btn>
      </v-snackbar>
      <AlertBox v-if="showAlertBox" />
      <Spinner v-if="loading==true" v-bind:message="'loadingJobs'" />
      <ActionCard v-on:clicked="$router.push('/p')" :title="'Inga matchande jobb'" :subtitle="'Prova att uppdatera din profil för att matcha fler jobb. Du behöver ladda om sidan för att ladda nya jobbmatchningar'" :actionText="'Till min profil'" v-else-if="jobs == null || jobs.length < 1" />
      <v-row v-else-if="this.$vuetify.breakpoint.smAndDown">
        <v-col>
          <v-card max-width="800">
            <v-toolbar flat>
              <v-toolbar-title>Lediga jobb</v-toolbar-title>

              <v-spacer></v-spacer>
<!-- 
              <v-btn icon>
                <v-icon>mdi-magnify</v-icon>
              </v-btn>

              <v-btn icon>
                <v-icon>mdi-tune</v-icon>
              </v-btn> -->
            </v-toolbar>

            <v-list two-line>
              <v-list-item-group>
                <template v-for="(item, index) in jobs">
                  <v-list-item :key="item.job_guid" @click="openJob(item)">
                    <v-list-item-avatar tile>
                      <v-img max-width="40px" contain :src="item.company_logo"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.title"></v-list-item-title>
                      <v-list-item-subtitle
                        class="text--primary"
                        v-text="item.company + ' - ' + item.cityText"
                      ></v-list-item-subtitle>
                      <v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-list-item-action-text v-text="item.jobStatusText"></v-list-item-action-text>
                      <v-avatar :color="item.jobStatusColor" size="24"></v-avatar>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
      <div v-else>
        <v-row>
          <v-col cols="12">
            <v-card>
              <DataTableTitle
                :title="page.tableTitle"
                :itemLength="jobs.length"
                :searchLabel="'Sök'"
                v-model="jobs_search"
              />
              <v-data-table
                :mobile-breakpoint="500"
                class="clickable-rows-table"
                v-model="jobs_selected"
                :headers="headers"
                @click:row="openJob"
                :items="jobs"
                :search="jobs_search"
                :sort-by="['application_period_end']"
                :sort-desc="false"
              >
                <template v-slot:item.image="{ item }">
                  <!-- <v-btn
                  @click.stop="openApplication(item)"
                  class="text-none"
                  rounded
                  dark
                  color="primary"
                  >Ansök</v-btn>-->
                  <!-- <v-icon large color="grey">star_outline</v-icon> -->
                  <v-list-item-avatar tile>
                    <v-img max-width="40px" contain :src="item.company_logo"></v-img>
                  </v-list-item-avatar>
                </template>
                <template v-slot:item.statusText="{ item }">
                  <v-tooltip color="primary" v-model="item.tooltip" left nudge-bottom>
                      <template v-slot:activator="{ on }">
                        <div v-on="on">
                          <v-avatar :color="item.jobStatusColor" size="24"></v-avatar>
                        </div>
                      </template>
                      <span>{{ item.jobStatusText }}</span>
                    </v-tooltip>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
        <ActionDialog
          v-model="dialog"
          title="Följ ansökan"
          v-bind:accept="'Godkänn'"
          v-bind:cancel="'Avbryt'"
        >
          {{loading}}
          <template v-slot:actions>
            <v-row justify="space-around" class="mx-0">
              <v-btn class="dialog-action-btn text-none" rounded text color="error">Avbryt</v-btn>
              <v-btn class="dialog-action-btn text-none" rounded dark color="primary">Skicka</v-btn>
            </v-row>
          </template>
        </ActionDialog>
      </div>
    </PageContent>
  </div>
</template>

<script>
import PageHeader from "../components/layout/PageHeader";
import PageActions from "../components/layout/PageActions";
import PageContent from "../components/layout/PageContent";

import store from "../store/store";
import ds from "../dataservice";
import { mapState, mapActions } from "vuex";
import Spinner from "../components/misc/Spinner";
import AlertBox from "../components/misc/AlertBox";
import ActionDialog from "../components/actions/ActionDialog";
import DataTableTitle from "../components/tables/DataTableTitle";
import jobrequests from "../requests/jobs/requests";
import PageTitle from "../components/page/Title";
import AlertBar from "../components/basic/AlertBar";
import AlertBarMixin from "@/mixins/alertBar";
import basic from "@/mixins/basic";

export default {
  mixins: [AlertBarMixin, basic],
  components: {
    Spinner,
    AlertBox,
    ActionDialog,
    DataTableTitle,
    PageTitle,
    PageTitle,
    PageHeader,
    PageContent,
    PageActions,
    AlertBar,
  },
  data() {
    return {
      page: {
        key: "jobs",
        title: this.$t("views.jobs.availableJobs"),
        tableTitle: "Lediga tjänster",
      },
      snackbarMessage: null,
      showSnackbar: false,
      snackbartop: true,
      snackbartimeout: 5000,
      snackbarbottom: true,
      snackbarmultiline: true,
      showAlertBox: false,
      loading: true,
      jobs: [],
      jobs_selected: [],
      jobs_search: "",
      headers: [
        { text: "Logga", value: "image", width: 100, sortable: false },
        { text: "Status", value: "statusText", width: 85, sortable: false },
        {
          text: "Tjänst",
          align: "left",
          width: 200,
          sortable: true,
          value: "title",
        },
        { text: "Företag", width: 190, value: "company" },
        { text: "Ort", width: 140, value: "cityText" },
        { text: "Avslutas", width: 140, value: "application_period_end" },
      ],
    };
  },
  props: {},
  computed: mapState("applications", ["status"]),
  created: function () {},
  beforeMount: function () {
    this.handleLoad(this.status);
  },
  watch: {
    status(newValue, oldValue) {
      this.handleLoad(newValue);
    },
  },
  methods: {
    ...mapActions("applications", ["get"]),
    handleLoad(newValue) {
      if (newValue === "success") {
        this.loading = false;
        this.jobs = JSON.parse(JSON.stringify(store.state.applications.matchedJobs));
      } else if (newValue === "initialize") {
        this.getJobs();
      } else if (newValue === "failed") {
        this.loading = false;
        this.showAlertBox = true;
      }
    },
    getJobs: async function () {
      try {
        this.loading = true;
        await this.get();
        this.loading = false;
        this.showAlertBox = false;
      } catch (err) {
        console.log("failed to get jobs");
        console.log(err);
        this.showAlertBox = false;
      }
    },
    openJob(job) {
      this.$router.push({ name: "Job", params: { job_guid: job.job_guid } });
    },
  },
};
</script>
<style scope>
.dialog-action-btn.v-btn:not(.v-btn--round).v-size--default {
  min-width: 150px;
}
.theme--light.v-card > .v-card__text {
  color: black;
}
</style>