import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
//import './registerServieWorker'
import vuetify from './plugins/vuetify'
import VueCookies from 'vue-cookies'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueAuthenticate from 'vue-authenticate'

// import './assets/main.css'
import './styles/main.sass'

import Default from './layouts/Default'
import NoSidebar from './layouts/NoSidebar'
import i18n from './i18n'

import axiosinterceptor from './requests/interceptors';

import VueMeta from 'vue-meta'
 
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

//import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';  
//import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

// Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);
Vue.component('default-layout', Default)
Vue.component('no-sidebar-layout', NoSidebar)

Vue.config.productionTip = false

Vue.use(VueAxios, axios)
Vue.use(VueCookies)
VueCookies.config('30d')

axiosinterceptor.interceptor();

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')


// store.subscribe((mutation, state) => {
//   console.log("Subscribing");
// });

