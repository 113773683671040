

var { getInfo } = require('../../requests/global')

const get = async (context, data) => {
    context.commit("status", "pending");

    var info = await getInfo({});
    console.log("info.questionStatus")
    console.log(info)
    context.commit("userQuestionStatus", info.questionsStatus[0]);
    context.commit("status", "success");
    return;

}


export default {
    get: get

}