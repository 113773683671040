//import requests from '../../requests/signup/signup'
import requests from '../../requests/global'
import store from '../store'

function getSignupQuestions(context, data) {
    return new Promise((resolve, reject) => {
        requests.getQuestions({ signup: true, params: { job_guid: data } }).then(result => {
            console.log("result");
            console.log(result);
            result.forEach((el) => { el.ref = "signup-questions-no-" + el.id, el.visible = false, el.dimmed = false, el.answer = null, el.revealed = false, el.validAnswer = false; }) // ES6
            //console.log(result);
            store.commit('signup_questions', result);
            //console.log(store.state.signup_questions);
            store.commit('signup_questions_status', 'success');

            resolve("Success actions");
        })
            .catch(err => {
                console.log("Error " + err);
                reject(err);
            })
    })
}

export default {
    getSignupQuestions: getSignupQuestions
}