

var { getJobs } = require('../../requests/global')

const get = async (context, data) => {
    context.commit('status', "pending");
    var jobs = await getJobs({ params: { match: true }, ep: false })
    console.log("wa have jobs")
    context.commit('jobs', jobs);
    context.commit('status', "success");
}

const reset = (context, data) => {
    context.commit('jobs', null);
    context.commit('status', 'initialize');
}


export default {
    get: get,
    reset: reset,
}