<template>
  <v-app-bar app color="white" hide-on-scroll extension-height="70">
    <router-link class="no-ul d-flex" :to="'/'">
      <v-img
        max-width="85"
        max-height="55"
        src="https://storage.googleapis.com/brite-resources/logo/Brite_logo_blue%404x.png"
      />
    </router-link>
    <v-spacer></v-spacer>
       <router-link class="no-ul d-flex" :to="'/'">Logga in</router-link>
    <v-menu transition="slide-y-transition" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on"  icon>
          <v-icon color="primary">help</v-icon>
        </v-btn>
      </template>

      <v-list >
       <a class="no-ul d-flex" href="https://brite.jobs/om-oss#kontakta-oss" target="_blank"> <v-list-item > Kontakta </v-list-item></a>
       <a class="no-ul d-flex" href="https://brite.jobs/faq" target="_blank"> <v-list-item > FAQ </v-list-item></a>
      </v-list>
    </v-menu>

    <template v-slot:extension>
      <v-progress-linear
        class="progress"
        height="5"
        v-model="progress"
        color="secondary"
      ></v-progress-linear>
      <transition name="fade">
        <v-tabs
          height="65"
          v-model="active"
          color="secondary"
          hide-slider
          centered
          icons-and-text
        >
          <v-tabs-slider id="question-tabs" color="white"></v-tabs-slider>
          <v-tab
            :key="'profile'"
            href="#profile"
            class="caption"
            @click.stop="$emit('update', 1)"
          >
            Profil
            <v-icon>person</v-icon>
          </v-tab>
          <v-tab
            v-if="display > 1"
            :key="'questions'"
            href="#questions"
            class="caption"
            @click.stop="$emit('update', 2)"
          >
            Frågor
            <v-icon>question_answer</v-icon>
          </v-tab>
          <v-tab
            v-if="display > 2"
            :key="'test'"
            href="#test"
            class="caption"
            @click.stop="$emit('update', 3)"
          >
            Tester
            <v-icon>assignment</v-icon>
          </v-tab>
        </v-tabs>
      </transition>
    </template>
  </v-app-bar>
</template>
<script>
export default {
  data: () => ({
    active: 0,
  }),
  props: {
    progress: Number,
    value: String,
    display: Number,
  },
  watch: {
    value: function () {
      console.log("Value updated");
      this.active = this.value;
    },
    display: function () {
      if (this.display == 2) {
        this.active = "questions";
        this.$emit("update", 2);
      } else if (this.display == 3) {
        this.active = "test";
        this.$emit("update", 3);
      }
    },
  },
};
</script>