<template>
  <div>
    <v-dialog v-model="value" width="600">
      <v-card>
          <v-card-title>
                <h4>{{title}}</h4>
                <v-spacer></v-spacer>
            <v-icon color="black" @click="value=false" large>close</v-icon>
          </v-card-title>
 <!--        <v-toolbar flat>
          <v-toolbar-title>{{title}}</v-toolbar-title>
          <v-spacer></v-spacer>
            <v-icon @click="$emit('input', false)" large>close</v-icon>
        </v-toolbar> -->
        <v-card-text>
          <slot></slot>
        </v-card-text>
        <v-card-actions justify="center" >
          <slot name="actions">
            <v-row class="mx-0" justify="space-around">
              <v-btn @click="$emit('reject', true)" class="dialog-action-btn text-none" rounded text color="error">{{cancel}}</v-btn>
              <v-btn @click="$emit('resolve', true)" class="dialog-action-btn text-none" rounded dark color="primary">{{accept}}</v-btn>
            </v-row>
          </slot>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data: () => ({}),
  props: {
    title: String,
    value: Boolean,
    accept: {
      type: String,
      default: 'Godkänn'},
    cancel: {
      type:String,
      default: 'Avbryt'
    }
  },
  watch: {
      value(newValue, oldValue) {
          this.$emit('input', newValue);
      }
  }
};
</script>

