
import PageHeader from "../components/layout/PageHeader";
import PageActions from "../components/layout/PageActions";
import PageContent from "../components/layout/PageContent";

import InfoBar from "../components/basic/InfoBar";
import ContinueBar from "../components/basic/ContinueBar";
import AlertBar from "../components/basic/AlertBar";
import SimpleList from "../components/basic/SimpleList";

import Button from "../components/basic/Button";
import Card from "../components/basic/Card";
import ActionCard from "../components/basic/ActionCard";
import Dialog from "../components/basic/Dialog";

import AddToCalendar from "../components/misc/AddToCalendar";
import LoadingButton from "../components/misc/LoadingButton";

import Spinner from "../components/misc/Spinner"

export default {
    components: {
        PageHeader,
        PageActions,
        PageContent,
        Button,
        InfoBar,
        ContinueBar,
        AlertBar,
        Card,
        Dialog,
        SimpleList,
        AddToCalendar,
        ActionCard,
        Spinner,
        LoadingButton
    },
    data: () => {
        return {
            mobileBreakpoint: 500,
            debounceDelay: 320,
        }
    },
    computed: {
        typeOfEnvironment: function() {
            return process.env.NODE_ENV;
        }
    }
}