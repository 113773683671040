<template>
  <div>
    <!--         <v-range-slider
          v-model="dAnswer"
          :step="sliderStepSize"
          :max="range[1]"
          :min="range[0]"
          thumb-label="always"
          class="align-center"
        >
  <template v-slot:prepend>
            <v-text-field
              v-model="salary.values[0]"
              class="mt-0 pt-0"
              hide-details
              single-line
              type="number"
              style="width: 60px"
            ></v-text-field>
          </template>
          <template v-slot:append>
            <v-text-field
              v-model="salary.values[1]"
              class="mt-0 pt-0"
              hide-details
              single-line
              type="number"
              style="width: 60px"
            ></v-text-field>
          </template>
    </v-range-slider>-->
    <v-slider
      v-if="showThumbLabel"
      :disabled="disabled"
      v-model="dAnswer"
      :max="range[1]"
      :min="range[0]"
      :step="sliderStepSize"
      :ticks="false"
      @start="touching=true"
      @end="endOfSlide"
      @mouseup="endOfSlide"
      @mousedown="dragging=true"
      color="secondary"
      thumb-label="always"
      :thumb-size="45"
      track-color="grey lighten-3"
      always-dirty
      :tick-labels="fixedLabels"
    ></v-slider>
    <v-slider
      v-if="!showThumbLabel"
      v-model="dAnswer"
      :disabled="disabled"
      :max="range[1]"
      :min="range[0]"
      :step="sliderStepSize"
      :ticks="false"
      @start="touching=true"
      @end="endOfSlide"
      @mouseup="endOfSlide"
      @mousedown="dragging=true"
      :thumb-size="80"
      color="secondary"
      track-color="secondary"
      always-dirty
      :tick-labels="fixedLabels"
    ></v-slider>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      dAnswer:
        this.answer != null
          ? this.answer
          : (this.range[1] + this.range[0] - 1) / 2,
      dValidAnswer: false,
      dLabels: [],
      touching: false,
      dragging: false,
      slideEnded: false
      // myColor: "#038C8C",
    };
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    id: Number,
    tab: String,
    title: String,
    questionText: String,
    dimmed: Boolean,
    visible: Boolean,
    ticks: Number,
    max: Number,
    min: Number,
    default: Number,
    sliderStepSize: Number,
    answer: {
      type: Number,
      default: () => {
        return 50;
      }
    },
    showThumbLabel: Boolean,
    range: Array,
    labels: Array,
    introText: String,
    instructionText: String
  },
  computed: {
    fixedLabels: function() {
      //this.labels = this.labels[0];
      this.dLabels[0] = this.labels[0];
      var labellength = Math.floor(
        (this.range[1] - this.range[0]) / this.sliderStepSize
      );
      //console.log(labellength);
      //console.log(this.range);
      for (var i = 1; i < labellength; i++) {
        this.dLabels[i] = "";
      }
      this.dLabels[labellength] = this.labels[1];
      //console.log(this.dLabels);

      return this.dLabels;
    }
  },
  mounted: function() {
    //console.log("Answer is: " + this.answer)
    //this.dAnswer = JSON.parse(this.answer);
  },
  watch: {
    dAnswer: {
      handler: function() {
        this.slideEnded = false;
        //console.log(this.slideEnded)
        //console.log(this.touching)
        //console.log(this.dragging)
        if (
          this.dAnswer != null &&
          this.dAnswer != false &&
          this.dAnswer != ""
        ) {
          this.dValidAnswer = true;
          if (
            this.slideEnded == true ||
            (this.touching == false && this.dragging == false)
          ) {
            this.confirmedOnce = true;
            this.$emit("changeAnswer", {
              id: this.id,
              tab: this.tab,
              answer: this.dAnswer,
              validAnswer: this.dValidAnswer
            });
          }
        }
        /*         this.$emit("changeAnswer", {
          id: this.id,
          //tab: this.tab,
          answer: this.dAnswer,
          validAnswer: this.dValidAnswer
        }); */
      },
      deep: true
    }
  },
  methods: {
    endOfSlide: function() {
      //console.log("end of slide")
      this.touching = false;
      this.dragging = false;
      this.slideEnded = true;
      this.dValidAnswer = true;
      //console.log("slide ended");
      //console.log(this.dAnswer);
      this.$emit("changeAnswer", {
        id: this.id,
        //tab: this.tab,
        answer: this.dAnswer,
        validAnswer: this.dValidAnswer
      });
    }
  }
};
</script>
<style >
.v-input__slider {
  margin-top: 24px;
}
.v-slider__tick {
  background-color: rgba(255, 255, 255, 0) !important;
}


.v-slider--horizontal .v-slider__thumb-label > * {
  -webkit-transform: rotate(0deg) !important;
  transform: rotate(0deg) !important;
  background-color: white !important;
  caret-color: white !important;
  color: rgb(121, 121, 121);
  border: 1px solid #cecece;
  border-radius: 3px;
  padding: 0px 10px 0px 10px;
  font-size: 10px;
}

.v-slider--horizontal .v-slider__thumb-label {
  -webkit-transform: translateY(0%) translateY(-25px) translateX(0%)
    rotate(0deg) !important;
  transform: translateY(0%) translateY(-25px) translateX(0%) rotate(0deg) !important;
  background-color: white !important;
  caret-color: white !important;
  height: 0px !important;
  width: 0px !important;
} 
</style>