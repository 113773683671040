const token = (state, token) => {
    state.token = token;
};

const isLoggedIn = (state, status) => {
    state.isLoggedIn = status;
};

export default {
    token,
    isLoggedIn
}