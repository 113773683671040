
const jobByJobGuid = (state) => (job_guid) => {
    var temp = null;
    for (var i = 0; i< state.jobs.length; i++) {
        if(state.jobs[i].job_guid == job_guid) {
          temp = JSON.parse(JSON.stringify(state.jobs[i]));
          break;
        }
      }
    return temp;
}

export default {
    jobByJobGuid: jobByJobGuid
}
