<template>
  <div>
    <v-card flat color="#FAFAFA">
      <v-container class="pb-0 mb-0">
        <div class="py-0 d-flex">
          <v-icon v-if="back" @click="$router.go(-1)" large color="black">arrow_back</v-icon>
            <h1 class="mb-0">{{title}}</h1>
        </div>
        <v-divider v-if="divider" class="mt-0 pt-0"></v-divider>
      </v-container>
    </v-card>

  </div>
</template>
<script>
export default {
  components: {},
  data: () => {
    return {};
  },
  props: {
    title: String,
    back: Boolean,
    fluid: {
      type: Boolean,
      default: true,
    },
    divider: {
      type: Boolean,
      default: false,
    }
  },
};
</script>
<style>
</style>