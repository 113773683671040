<template>
  <v-card-title>
    <h6>{{title}} ({{itemLength}})</h6>
    <div class="flex-grow-1"></div>
    <v-text-field
      v-if="searchLabel!=null"
      :value="value"
      v-on:input="$emit('input', $event)"
      append-icon="search"
      :label="searchLabel"
      single-line
      hide-details
    ></v-text-field>
  </v-card-title>
</template>
<script>
export default {
  data: () => ({}),
  props: {
    title: String,
    itemLength: Number,
    searchLabel: String,
    value: String
  }, 
};
</script>