
import { mapState, mapActions } from "vuex";

export default {
    data() {
        return {
            lang: null,
        };
    },
    computed: {
        ...mapState("settings", ["language", "languages"])
    },
    mounted: function () {
        //this.lang = this.language
    },
    methods: {
        ...mapActions("settings", {
            _updateLanguage: "language"
        }),
    },
    watch: {
       /*  language(newValue, oldValue) {
            this.lang = this.language;
        },
        lang(newValue, oldValue) {
            if (this.lang != this.language) {
                console.log("go update")
                this._updateLanguage({ language: this.lang, notify: true, manual: true })
            }
        } */
    },
}