
import { mapState, mapActions } from "vuex";

export default {
    computed: {
        ...mapState("loader", ["showLoader"])
    },
    methods: {
        ...mapActions("loader", {
            _loaderShow: "show",
            _loaderHide: "hide"
        }),
    }
}