const axios = require('axios')
import store from '../store/store'

export default {
    interceptor: function () {
        axios.interceptors.request.use(function (config) {
            if (config.headers.briteUserToken == 'BRITE USER TOKEN') {
                config.headers.authorization = "Bearer " + store.state.user.token;
                delete config.headers.briteUserToken;
            }
            return config;
        }, function (error) {
            // Do something with request error
            return Promise.reject(error);
        });
    }
}

